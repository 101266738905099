<template>
    <!-- <div v-if="loading" class="spinner-container" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' :  'background-color: whitesmoke; color: #003;'"> 
      <img src="@/assets/loading-white.gif" alt="Loading..." class="spinner" style=" border-radius: 5px;"/>
    </div>
    <div v-else > -->
      <section class="secimage" v-show="showpictureimage === true">
        <div class="txtdataimage">
          <h1  v-html="imagetext" :class="formatvhtml"></h1>
        </div>
        <div>
            <img :src="poster" class="imgcss" />
      </div>
      <div class="overlayimage"></div>
    </section>
  
  <section v-if="showpictureimage === false" style="margin-top: 140px;">
  
  </section>

  
  <!------Top banner section-->
  <section  
    :style="colortheme === 'darkTheme' ? `${darkstyle} height: 100px;` :  `${allpagesbannercolor};height: 100px;`">
    <div class="container" style="padding-top: 40px; font-size: 24px;">
        <b >SITEMAP</b>
    </div>
  </section>
  
  <section style="height: auto; padding-bottom: 200px; padding-top: 50px;" :style="colortheme === 'darkTheme' ? `${darkstyle}; ` :  ''">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3"  v-for="(menu, index) in mainmenu" :key="menu.mid">
                <div :style="colortheme === 'darkTheme' ? `background-color: #252525; padding: 10px;` :  `${allpagesbannercolor} text-align: center; padding: 10px;`"> 
                    {{ menu.mdesc }}
                </div>
                <div v-for="(md, index2) in menualldesc" :key="md.mfid_id">
                    <div  v-if="index2 === index">
                        <div v-for="sec1 in divseconeinfo">
                            <ul>
                                <li v-if="sec1.mfid === md.mids">
                                    <router-link :to="{path: sec1.ds1link}" class="rtlink sec1fmt" :style="colortheme === 'darkTheme' ? `${darkstyle}; ` :  ''">
                                        {{ sec1.ds1desc}}
                                    </router-link>
          
                                    <div v-for="dsv2 in divsectwoinfo">
                                        <ul>
                                            <li v-if="dsv2.ds1fid === sec1.did">
                                                <router-link :to="{path: dsv2.ds2link}" class="rtlink" :style="colortheme === 'darkTheme' ? `${darkstyle}; ` :  ''">
                                                    {{ dsv2.dsshortdesc}}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li> 
                            </ul>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  </section>

  
  
  <!-- </div> -->
  </template>
  
  
  
  <script>
  import axios from 'axios'; 
  require('@/defaultPath');
  // require('@/assets/css/pannellium-2.5.css');
  // require('@/assets/css/video-js.css');
  
  
  export default {
  name: 'HomeView',
  components: {
             
              },
  
  
  //=========================DATA SECTION==========================
  data() {
  return {
    //-----------ALL PAGES VARIABLES====================
      poster: '',
  
      pvpictureimage: false,
  
      allpages: [],
      allslidesinfo: [],
      allslidestext: '',

  
      //pagesettings
      myImgUrl: myUrlPath,
      imagetext: '',
      btmimagetext: '',
      colortheme: localStorage.getItem('colorTheme'),
      showslideshow: false,
      showvideo: false,
      showpictureimage: false,
      showbanner: false,
      darkstyle: '',
      alternatedarkstyle: '',
      whitesmokestyle: '',
      submenustyle: '',
     
  
      linkdisabled: 'manufacturing',
  
      loading: true, // Initially true to show the spinner

      mainmenu: [],
      menualldesc: [],
      divsecone: [],
      divsectwo: [],
  }
  },
  
  //=========================METHODS SECTION==========================
  methods: {
 

  },
  
  
  //=========================COMPUTED SECTION==========================
  
  //=========================MOUNTED HOOK SECTION==========================
  async mounted() {
     // Simulate a load time with a timeout (replace this with real data fetching)
    //  setTimeout(() => {
    //     this.loading = false; // Hide spinner after data is loaded
    //   }, 2000);
  
    //-------------get page theme------------------
    this.nbTheme = localStorage.getItem('nbtheme')
  
    // this.fetchItems();
    this.myPicUrl = myUrlPath
  
      //-------------page carousel section------------------

  
  
      //-------------videos scetion------------------
    await axios.get('allpagespv/manufacturing').then(
      response => {
        this.poster = this.myImgUrl + response.data[0].pvposter
      })
      .catch((error) => {
          console.log(error)
        })
  
      //-------------all pages settings------------------
    await axios.get('getwebsitesettings/manufacturing').then(
        response => {         
          this.menuname = 'manufacturing'
          localStorage.setItem('menuname', this.menuname)
          this.webdesc = response.data[0].webdesc
          this.showslideshow = response.data[0].showslideshow
          this.showvideo = response.data[0].showvideo
          this.showpictureimage = response.data[0].showpictureimage
          this.topmenubarbgcolor = response.data[0].topmenubarbgcolor
          this.mainmenubarcolor = response.data[0].mainmenubarcolor
          this.darkstyle = response.data[0].darkstyle
          this.alternatedarkstyle = response.data[0].alternatedarkstyle
          this.whitesmokestyle = response.data[0].whitesmokestyle
  
          this.submenustyle = response.data[0].submenustyle
          this.showallpagesbanner = response.data[0].showallpagesbanner
          this.allpagesbannercolor = response.data[0].allpagesbannercolor
         
        })
      .catch((error) => {
          console.log(error)
      })
    
    await axios.get('menualldesc/').then(
    response => {
      this.menualldesc = response.data
    })
    .catch((error) => {
      console.log(error)
    })
    
    await axios.get('mainmenu/').then(
    response => {
      this.mainmenu = response.data
    })
    .catch((error) => {
      console.log(error)
    })

    await axios.get('divsecone/').then(
    response => {
      this.divseconeinfo = response.data
    })
    .catch((error) => {
      console.log(error)
    })

    await axios.get('divsectwo/').then(
    response => {
      this.divsectwoinfo = response.data
    })
    .catch((error) => {
      console.log(error)
    })


  
  }
  
  
  
  }
  </script>
  <style lang="scss" scoped>
  .bannersection1 {
  margin-top: -20px;
  height: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 150px;
  padding-right: 150px;
  }
  
  .transition {
  transition: all 3s ease-out;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  }
  
  @keyframes fadeInAnimation {
  0% {
  opacity: 0;
  }
  100% {
  opacity: 1;
  }
  }
  .videosection {
  background-color: #000000;
  }
  
  .overlayvideo{
  opacity: .4;
  height: 600px;
  background-color: #000000;
  }
  .overlayvideo {
  opacity: 0.55;
  width: 100%;
  height: 670px;
  background: linear-gradient(to bottom, #000000);
  margin-top: -670px;
  }
  
  
  .overlayimage{
  opacity: .4;
  height: 600px;
  background-color: #000000;
  }
  .overlayimage {
  opacity: 0.55;
  width: 100%;
  height: 670px;
  background: linear-gradient(to bottom, #000000);
  margin-top: -670px;
  }
  
  .overlaycarousel{
  opacity: .4;
  height: 600px;
  background-color: #000000;
  }
  .overlaycarousel {
  opacity: 0.55;
  width: 100%;
  height: 670px;
  background: linear-gradient(to bottom, #000000);
  margin-top: -670px;
  }
  
  .btnformat {
    border-radius: 50%;
    padding: 14px;
    margin-left: 10px;
  }
  
  .playercss {
  max-height: 300%; 
  height: 70vh;
  
  }
  
  
  .thing {
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  background-color: #000000;
  }
  
  
  [data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
  }
  
  .clientextinfo {
  width: 100%;
  margin: 0 auto;
  margin: 0 auto; 
  text-align: center;
  margin-top: 130px;
  }
  .carousel {
  position: relative;
  max-height: 100vh;
  height: 80vh;
  margin-top: -100px;
  z-index: -9999;
  
  .slide-info {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  
  img {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.99;
  }
  }
  }
  .pushbyeighty {
  margin-top: 166px;
  }
  
  .clientextinfo {
  width: 100%;
  margin: 0 auto;
  margin: 0 auto; 
  text-align: center;
  margin-top: 130px;
  }
  .carousel {
  position: relative;
  max-height: 100vh;
  height: 80vh;
  margin-top: -100px;
  z-index: -9999;
  
  .slide-info {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  
  img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.99;
  }
  }
  
  }
  .txtselected {
  text-align: justify;
  }
  .container {
  
  padding-top: 20px;
  }
  .wrapcontent {
  padding-top: 50px;
  padding-bottom: 50px;
  }
  .videosec {
  margin-top: 160px;
  }
  
  .secimage {
  margin-top: 160px;
  }
  .imgcss {
  width: 100%;
  }
  .rndbtnformat {
    margin-top: -120px;
    margin-left: 100px;
  }
  /*-----------------Spinner------------*/
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    width: 150px; /* Adjust size as needed */
    height: 150px;
  }
  ul li {
    padding-top: 10px;
    /*list-style: none;*/
    text-decoration: none;
  }

  .sec1fmt {
    font-size: 16px;

  }

  .rtlink {
    text-decoration: none;
  }
  @media only screen and (max-width: 600px) {
  .bannersection1 {
  width: 100%;
  height: auto;
  padding-left: 0px;
  padding-right: 0px;
  } 
  .imgcss {
  width: 100%; 
  height: 40vh;
  margin-top: -80px;
  }
  .overlayimage, .overlayvideo{
  opacity: 0.55;
  width: 100%;
  height: 90vh;
  background: linear-gradient(to bottom, #000000);
  
  }
  .container {
  padding-left: 20px;
  padding-right: 20px;
  }
  
  .txtdatavideo  {
  position: absolute;
  margin-top: 250px;
  z-index: 9999999999999;
  color: whitesmoke;
  padding-left: 30px;
  }
  
  .txtdataimage  {
  position: absolute;
  margin-top: 150px;
  z-index: 9999999999999;
  color: whitesmoke;
  padding-left: 30px;
  }
  .rndbtnformat {
    margin-top: -60px;
  }
  
  }
  @media only screen and (min-width: 600px) {
  .bannersection1 {
  width: 100%;
  height: auto;
  padding-left: 0px;
  padding-right: 0px;
  } 
  
  .txtdatavideo  {
  position: absolute;
  margin-top: 250px;
  z-index: 9999;
  color: whitesmoke;
  padding-left: 60px;
  }
  
  .txtdataimage  {
  position: absolute;
  margin-top: 100px;
  z-index: 9;
  color: whitesmoke;
  padding-left: 30px;
  }
  .imgcss {
  width: 100%; 
  height: 40vh;
  margin-top: -80px;
  }
  .rndbtnformat {
    margin-top: 60px;
  }
  }
  
  @media screen and (min-width:769px) {
  .txtdataimage  {
  position: absolute;
  margin-top: 170px;
  z-index: 9;
  color: whitesmoke;
  padding-left: 30px;
  }
  
  .txtdatavideo  {
  position: absolute;
  margin-top: 250px;
  z-index: 9999;
  color: whitesmoke;
  padding-left: 100px;
  }
  
  .txtdataimage  {
  position: absolute;
  margin-top: 100px;
  z-index: 9;
  color: whitesmoke;
  padding-left: 30px;
  }
  
  .imgcss {
  width: 100%;
  height: 50vh;
  }
  
  .rndbtnformat {
    margin-top: 60px;
  }
  
  }
  
  @media only screen and (min-width: 992px) {
  
  .txtdataimage  {
  position: absolute;
  margin-top: 140px;
  z-index: 9;
  color: whitesmoke;
  padding-left: 30px;
  }
  
  .imgcss {
  width: 100%;
  height: 50vh;
  }
  }
  @media only screen and (min-width: 1200px) {
  .txtdataimage  {
  position: absolute;
  margin-top: 350px;
  z-index: 9;
  color: whitesmoke;
  padding-left: 80px;
  }
  
  .imgcss {
  width: 100%;
  height: 70vh;
  
  }
  .playercss {
  height: 650px;
  }
  
  .txtselected {
  padding-right: 60px;
  text-align: justify;
  }
  .txtleftalign {
  text-align: justify;
  padding-left: 60px;
  }
  .rndbtnformat {
    margin-top: -140px;
  }
  }
  
  @media only screen and (min-width: 1600px) {
  .txtdataimage  {
  position: absolute;
  margin-top: 350px;
  z-index: 9;
  color: whitesmoke;
  padding-left: 300px;
  }
  
  .txtdatavideo  {
  position: absolute;
  margin-top: 250px;
  z-index: 9999;
  color: whitesmoke;
  padding-left: 300px;
  }
  
  .imgcss {
  width: 100%;
  height: 70vh;
  }
  .overlaycarousel {
  opacity: 0.55;
  width: 100%;
  height: 750px;
  background: linear-gradient(to bottom, #000000);
  margin-top: -750px;
  }
  .rndbtnformat {
    margin-top: -140px;
    margin-left: 300px;
  }
  }
  
  </style>
<template>
    <div class="container ">
       <div class="row" style="padding: 10px;">
         <div class="col-xs-12 col-sm-12 col-md-12 formatbanner">
            <div v-html="bannertext" class="innertext">
            
            </div>
         </div>
       </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {

props: ['bannertext', 'colortheme'],
data(){

 return {
 
    allcards: [],

 }
},
mounted(){
    axios.get('getcardsinfo/nddtesting').then(
      response => {
          this.allcards = response.data
    }) .catch((error) => {
        console.log(error)
      })
}
}
</script>
<style scoped>
.bannersection {
    margin-top: -20px;
}

.innertext {
    font-size: 16px;
  
    text-align: left;
}
.headingtext {
    font-weight: bold;

    text-align: left;
}


@media only screen and (max-width: 600px) {
    .container {
        padding-top: 40px;
        padding-bottom: 40px;
    } 
}
@media only screen and (min-width: 600px) {

    .container {
        padding-left: 0px;
        padding-right: 2px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

@media screen and (min-width:769px) {

    .container {
        padding-left: 0px;
        padding-right: 2px;
        padding-top: 10px;
        padding-bottom: 10px;

    }
}

@media only screen and (min-width: 992px) {
    .formatbanner{
        height: 200px;
        padding-right: 70px;
        padding-top: 10px;
      
        width: 80%;
    }
}
@media only screen and (min-width: 1200px) {
    .formatbanner{
        height: 200px;
        padding-top: 30px;
       
        width: 80%;
    }
}
@media only screen and (min-width: 1400px) {
    .formatbanner{
        height: 200px;
    
        padding-right: 70px;
        padding-top: 30px;
     
        width: 80%;
    }
}
@media only screen and (min-width: 1600px) {
    .formatbanner{
        height: 200px;
    
        padding-right: 70px;
        padding-top: 30px;

        width: 80%;
    }
}
</style>
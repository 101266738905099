<template>
    <div class="player">
      <video id="myVideo" controls class="videocss" :poster="poster">
          <source :src="`${baseurl1}${videourl}`" type="video/mp4">
        </video>
    </div>

</template>

<script>

export default {
props: ['videourl', 'baseurl1', 'poster'],
data() {
  return {
    // videojs options
    vid: '',
  }
},

mounted() {
  this.vid = document.getElementById("myVideo");
  let vid1 = document.getElementById("myVideo");
      vid1.loop = true;
  // this.vid.onplay = function() {
  // alert("The video has started to play");
  // };
},

methods: {
 
}
}
</script>

<style scoped>
.videocss {
width: 100%;
height: 100%;
}
img {
  object-fit: cover;
}
video{
  object-fit: inherit;
}
</style>



<template>
  <!-- <div v-if="loading" class="spinner-container">
    <img src="@/assets/loading-white.gif" alt="Loading..." class="spinner" />
  </div>
  <div v-else> -->
  <section  class="navbarsection"  style="margin-top: 150px;" v-show="showslideshow === true && showvideo === false && showpictureimage === false">
      <RealSlideshow :carouselImages="carouselSlides" :imagepath="myImgUrl"/>
      <div class="overlaycarousel">

      </div>
  </section>
  <section v-show="showvideo === true && showslideshow === false  && showpictureimage === false" class="videosec">
    <div class="txtdatavideo">
      <h1  v-html="imagetext" :class="formatvhtml"></h1>
    </div>
      <div class="videosection" >
          <Transition class="transition thing">
            <video-background 
              :playbackRate="playrate"
              :src="src"
              :poster="poster"
              :class="`thing playercss`"
                >
            </video-background>
          </Transition>
        
      </div>
     
      <div class="overlayvideo">

      </div>
  </section>
  <section class="secimage" v-show="showpictureimage === true && showslideshow === false  && showvideo === false">
      <div class="txtdataimage">
        <h1  v-html="imagetext" :class="formatvhtml"></h1>
      </div>
      <div>
          <img :src="poster" class="imgcss" />
    </div>
    <div class="overlayimage"></div>
  </section>
  <section class="pushbyeighty" v-show="showpictureimage === false && showslideshow === false  && showvideo === false">

  </section>
  <section :class="showslideshow === true ? 'pushbyfifty' : ''">

  </section>

<!---   Breadcrumb section  ---->
<!-- <div class="col-xs-12 col-sm-12 col-md-12">
  <BreadCrumbs :breadcrumbdata="breadcrumbdata" :darkstyle="darkstyle" />
</div> -->


<!------Top banner section-->
<section class="bannersection1" :style="colortheme === 'darkTheme' ? darkstyle :  `${allpagesbannercolor}; padding-bottom: 100px;`">
  <MiddleBanner :allcardsinfo="allcardsinfo" :breadcrumbdata="breadcrumbdata" />
</section>


<DesignYourway :designuwdata="designuwdata" :posterleft="posterleft" :posterright="posterright"/>


<section  v-show="showtwosecrpttop === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${twosecrpttopcolor}`" >
  <TwoReportsection :tworeportdata="tworeportsectop" :rpttopcaption="rpttoptext" />
</section>

  <!---------------Hover image or video left-------------------------->

  <!---------------Three equal section with boxes-------------------------->
<section style="padding-top: 50px; padding-bottom: 50px;"
  v-show="showthreecardsec === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${threecardseccolor}`"
  >
  <ThreecardsSection :allcardsinfo="allcardsinfohomepage2" :allcardmdcol="allcardmdcol" :toptext="threesectoptext"  />
</section>

  <!--------------Three equal sections picture only-------------------------->
<section
   v-show="showthreecardsec === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${threecardseccolor}`"
  >
 <ActualThreesections :allcardsdata="allcardsdata" />
</section>



<!---------------All images section-------------------------->
<section style="padding-top: 50px; padding-bottom: 50px;"
  v-show="showequalthreecards === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${equalthreecardscolor}`"
  >
  <div  class="container">
    <AllsectionImages :allsecimages="allsectimages" :cmimage1="cmimage1" :cmimage2="cmimage2" :cmimage3="cmimage3" :abspath="myImgUrl"
    :mageonelink="mageonelink" :magetwolink="magetwolink" :magethreelink="magethreelink"
    :timageonedesc="timageonedesc" :timagetwodesc="timagetwodesc" :timagethreedesc="timagethreeedesc" :colortheme="colortheme" :toptext="toptext"   />
  </div>
</section>

  <!---------------Two equal section-------------------------->
<section style="padding-top: 50px; padding-bottom: 100px;"
   v-show="showtwoequalsections === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${twoequalsectionscolor}`"
  >
  <div class="container">
  <TwoequalSections :secquestion="twosectoptext" :seconetext="twoseclefttext" :sectwotext="twosecrightext" />
  </div>
</section>

<!---------------List of Upcoming Event and summary event briefing-------------------------->
<section style="padding-top: 50px; padding-bottom: 100px;"
  v-show="showeventspanel === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${eventcollapsecolor}`"
  >
  <div class="container">
    <EventspanelCollapsible :eventdata="eventdata" :accordionid="accordionid" />
  </div>
</section>

<!---------------All Slides Section-------------------------->
<section style="padding-top: 100px; padding-bottom: 250px;"
   v-show="showallslides === true" :style="colortheme === 'darkTheme' ? darkstyle :  `${allsidescolor}`"
  >
  <div>
    <AllSlides :slidetext="slidetext" :slidelmorelink="slidelmorelink" :slidelmoretext="slidelmoretext"
    :slideimage="slideimage" :slideimagetext="slideimagetext" :colortheme="colortheme" />
    <div class="rndbtnformat">
          <!-- Pagination Controls -->
          <div v-if="totalPages > 1" class="pagination" >
            <button v-for="page in totalPagesArray" :key="page" @click.prevent="fetchItems(page)" :class="{ 'active': currentPage === page }" class="btnformat">
              {{ page }}
            </button>
          </div>
    </div>
       
  </div>
</section>


<!-- </div> -->
</template>


<script>
import axios from 'axios';
import NbCarousel from "@/components/NbCarousel.vue";
import SwiperSlide from "@/components/SwiperSlide.vue";
import RealSlideshow from '@/components/RealSlideshow.vue';
import MiddleBanner from '@/components/MiddleBanner.vue';
import EventspanelCollapsible from '@/components/EventspanelCollapsible.vue';
import AllsectionImages from '@/components/AllsectionImages.vue';
import TwoequalSections from '@/components/TwoequalSections.vue';
import ActualThreesections from '@/components/ActualThreesections.vue';
import CardsandTextcards from '@/components/CardsandTextcards.vue';
import AllSlides from '@/components/AllSlides.vue';
import TwoReportsection from '@/components/TwoReportsection.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import DesignYourway from '@/components/DesignYourway.vue';

require('@/defaultPath');
// require('@/assets/css/pannellium-2.5.css');
// require('@/assets/css/video-js.css');


export default {
name: 'HomeView',
components: {NbCarousel, MiddleBanner, SwiperSlide, RealSlideshow, 
            EventspanelCollapsible, AllsectionImages, TwoequalSections, ActualThreesections, CardsandTextcards, AllSlides, 
            TwoReportsection, BreadCrumbs, DesignYourway
            },


//=========================DATA SECTION==========================
data() {
return {
  //-----------ALL PAGES VARIABLES====================
  carouselSlides: [],
    videoimage: [],
    imagetext: '',
    src: '',
    playrate: '',
    poster: '',
    pvstyle: '',
    pvsrc: '',

    pvpictureimage: false,

    allpages: [],
    allslidesinfo: [],
    allslidestext: '',

    //three section cards variables
    allcardsinfo: [],
    allcardsinfohomepage2: [],
    allcardmdcol: 3,

    //pagesettings
    myImgUrl: myUrlPath,
    imagetext: '',
    btmimagetext: '',
    colortheme: localStorage.getItem('colorTheme'),
    showslideshow: false,
    showvideo: false,
    showpictureimage: false,
    showbanner: false,
    darkstyle: '',
    alternatedarkstyle: '',
    whitesmokestyle: '',
    submenustyle: '',
    allcardtoptext: '',
    showallpagesbanner: false,
    allpagesbannercolor: '',



    showthreecardsec: false,
    threecardseccolor: '',

    showequalboxcards: false,
    equalboxcardscolors: '',
    showrightimgboxtext: false,
    rightimgboxtextcolor: '',
    showequalthreecards: false,
    equalthreecardscolor: '',
    showtwoequalsections: false,
    twoequalsectionscolor: '',
    showeventspanel: false,
    eventcollapsecolor: '',
    showallslides: false,
    allsidescolor: '',
    showtwosecrpttop: false,
    twosecrpttopcolor: '',

    designuwdata: [],

    loading: true, // Initially true to show the spinner


    mbtnsectext: '',
    bgcolor: '',
    setbannercolor: false,

    breadcrumbdata: [],
    
    defaultURL: defaultPath,
    lang: localStorage.getItem('lang'),
    // showleftsec: false,
    // showrightsec: false,

    contfluiddata: [],
    contdata: [],
    allcardsdata: [],
    eventdata: [],
    presentdata: [],
    accordionid: 1,
 

    sliderdata: [],
    slidertextinfo: '',

    //pagination props
    items: [],
    slidetext: '', 
    slidelmorelink: '',
    slidelmoretext: '',
    slideimage: '',
    slideimagetext: '',
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    nextLink: null,
    prevLink: null,
    readnews: '',

    twosecttoptext: '',
    twoseclefttext: '',
    twosecrightext: '',

    cmimage1: '',
    cmimage2: '',
    cmimage3: '',
    imageonelink: '', 
    imagetwolink: '', 
    imagethreelink: '',
    timageonedesc: '', 
    timagetwodesc: '',
    timagethreeedesc: '',
    toptext: '',
    myPicUrl: '',

    tworeportsectop: [],
    tworeportsecbottom: [],
    rpttoptext: '',

    horizontalnocardsectop: [],

 

    dataltext: '', 
    datartext: '',
    datarlinktext: '',
    datarlinkpath: '',
    datalrbgcolor: '',
    datalwidth: '', 
    datarwidth: '',
    drpleftvideopath: '',
    isvideo: false,

    //Inspection text retrieval
    dataltext1: '', 
    datartext1: '',
    datarlinktext1: '',
    datarlinkpath1: '',
    datalrbgcolor1: '',
    datalwidth1: '', 
    datarwidth1: '',

    //client slider
    allclientslidesinfo: [],
    
    //clientinfo data
    getclientslidertext: '',

    //topslideshow
    carouselSlidesinfo: [],

    //everything news
    newstitle: '',
    newslinktext: '',
    newslink: '',
    allnews: [],
    cardpress: [],
    newscat: [],

    drplefttext: '',
    drpleftimagepath: '',
    drpleftcaption: '',
    imagelinkpath: '',

    dlprighttext: '', 
    dlprimagepath: '',
    dlprcatpion: '',
    imagelinkpath: '',

    images: [],
    imagelinkpath: '',
    linkdisabled: 'homepage',
}
},

//=========================METHODS SECTION==========================
methods: {
 // Fetch items for a specific page
 fetchItems(page = 1) {
        
      axios.get(`getallsliderspaginationfilter/?filtertext=homepagefilter1&page=${page}`)
        .then(response => {
          this.items = response.data.results;
        
          this.slidetext = this.items[0].slidetext
          this.slidelmorelink = this.items[0].slidelmorelink
          this.slidelmoretext = this.items[0].slidelmoretext
          this.slideimage = this.items[0].slideimage
          this.slideimagetext = this.items[0].slideimagetext
          
          const count = response.data.count; // Total number of records
          const pageSize = 1; // Adjust this to match the page size used in your backend
          this.totalPages = Math.ceil(count / pageSize);
  
          this.currentPage = response.data.current_page;
          this.nextLink = response.data.links.next;
          this.prevLink = response.data.links.previous;
       
        })
        .catch(error => {
          console.error("Error fetching items:", error);
        });
    
    },
    moveNext(){
      let thisx = this
      let page = thisx.currentPage + 1
      this.fetchItems(page)
    },

logChange(event) {
    this.showImage = true
    this.histsearchid = event
    
    //------------Homepage Stock Prices---------------
    axios.get(`gethistorydataid/${this.histsearchid}`).then(
      response => {
          this.yearimages = response.data
    })
    .catch((error) => {
        console.log(error)
      })
  }
},


//=========================COMPUTED SECTION==========================
  computed: {
    totalPagesArray() {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  },

},


//=========================MOUNTED HOOK SECTION==========================
async mounted() {
     // Simulate a load time with a timeout (replace this with real data fetching)
    //  setTimeout(() => {
    //   this.loading = false; // Hide spinner after data is loaded
    // }, 2000);
  //-------------get page theme------------------
  this.nbTheme = localStorage.getItem('nbtheme')

  this.fetchItems();
  this.myPicUrl = myUrlPath

    //-------------page carousel section------------------
  await axios.get('getslideshow/homepage').then(
    response => {
      this.carouselSlides = response.data
    }).catch((error) => {
      console.log(error)
    })  

    //------------------slider section------------------
  await axios.get('getclientsliders/homepage').then(
    response => {
      this.images = response.data
    }).catch((error) => {
      console.log(error)
    })  

  //------------------Design it your way section------------------
  await axios.get('getdesignyourwaysection/homepage').then(
    response => {
      this.designuwdata = response.data
    }).catch((error) => {
      console.log(error)
    }) 
    
    //-------------videos scetion------------------
  await axios.get('allpagespv/homepage').then(
    response => {
      this.videoimage = response.data      
      this.imagetext = response.data[0].pvdisplaytext 
      this.src = this.myImgUrl + response.data[0].pvsrc
      this.playrate = response.data[0].playbackrate
      this.poster = this.myImgUrl + response.data[0].pvposter
      this.pvstyle = response.data[0].pvstyle
      this.pvpictureimage = this.myImgUrl + response.data[0].pvpictureimage
    })
    .catch((error) => {
        console.log(error)
      })

  //Breadcrum info
  await axios.get('getbreadcrumb/homepage').then(
      response => {
        // this.allpages = response.data
        this.breadcrumbdata = response.data
      })
      .catch((error) => {
          console.log(error)
        })

    //-------------all pages settings------------------
  await axios.get('getwebsitesettings/homepage').then(
      response => {         
        this.menuname = 'homepage'
        localStorage.setItem('menuname', this.menuname)
        this.webdesc = response.data[0].webdesc
        this.showslideshow = response.data[0].showslideshow
        this.showvideo = response.data[0].showvideo
        this.showpictureimage = response.data[0].showpictureimage
        this.topmenubarbgcolor = response.data[0].topmenubarbgcolor
        this.mainmenubarcolor = response.data[0].mainmenubarcolor
        this.darkstyle = response.data[0].darkstyle
        this.alternatedarkstyle = response.data[0].alternatedarkstyle
        this.whitesmokestyle = response.data[0].whitesmokestyle

        this.submenustyle = response.data[0].submenustyle
        this.allcardtoptext = response.data[0].allcardtoptext
        this.showallpagesbanner = response.data[0].showallpagesbanner
        this.allpagesbannercolor = response.data[0].allpagesbannercolor
        this.showthreecardsec = response.data[0].showthreecardsec
        this.threecardseccolor = response.data[0].threecardseccolor

        this.showequalboxcards = response.data[0].showequalboxcards
        this.equalboxcardscolors = response.data[0].equalboxcardscolors

        this.showequalthreecards = response.data[0].showequalthreecards
        this.equalthreecardscolor = response.data[0].equalthreecardscolor
        this.showtwoequalsections = response.data[0].showtwoequalsections
        this.twoequalsectionscolor = response.data[0].twoequalsectionscolor
        this.clientslider = response.data[0].clientslider
        this.clientslidercolor = response.data[0].clientslidercolor
        this.showeventspanel = response.data[0].showeventspanel
        this.eventcollapsecolor = response.data[0].eventcollapsecolor
        this.showallslides = response.data[0].showallslides
        this.allsidescolor = response.data[0].allsidescolor
        this.showtwosecrpttop = response.data[0].showtwosecrpttop
        this.twosecrpttopcolor = response.data[0].twosecrpttopcolor
        this.showtwosecbtmrpt = response.data[0].showtwosecbtmrpt
        this.twosecrptbtmcolor = response.data[0].twosecrptbtmcolor
      })
    .catch((error) => {
        console.log(error)
    })

 


    //-------------PBANNER PAGE------------------
    axios.get('getbannerinfo/homepage').then(
        response => {
          console.log(response.data)
            this.mbtnsectext = response.data.mbtnsectext
            this.bgcolor = response.data.bgcolor
            this.setbannercolor = response.data.setbannercolor
      }) .catch((error) => {
          console.log(error)
        })
  //-------------all pages banner------------------



 

      //get all cards information for homepage
  await axios.get('getcardsinfo/homepage').then(
    response => {
      this.allcardsinfo = response.data
    })
    .catch((error) => {
        console.log(error)
    })

  //get all cards information for homepage
  await axios.get('getcardsinfo/homepage2').then(
    response => {
      this.allcardsinfohomepage2 = response.data
    })
    .catch((error) => {
        console.log(error)
    })

    //-------------client slider text------------------
  await axios.get('getallpagesinfo/').then(
    response => {
      this.getclientslidertext = response.data[0].homepageclienttext
    })
    .catch((error) => {
        console.log(error)
    })

  




    //------------all cards Section---------------
  await axios.get('getallcardsectionsdata/homepage').then(
    response => {
        this.allcardsdata = response.data
    })
    .catch((error) => {
        console.log(error)
    })

    //----------Two equal section data--------------
  await axios.get('gettwosectiondata/homepage').then(
    response => {
        this.twosectoptext = response.data[0].toptext
        this.twoseclefttext = response.data[0].leftsection
        this.twosecrightext = response.data[0].rightsection
    })
    .catch((error) => {
        console.log(error)
    })

    //------------upcoming events---------------
  await axios.get('geteventlist/homepage').then(
    response => {
        this.eventdata = response.data
    })
    .catch((error) => {
        console.log(error)
    })

    //------------upcoming Presentation Pictures---------------
  await axios.get('geteventcaption/homepage').then(
    response => {
        this.presentdata = response.data
    })
  .catch((error) => {
      console.log(error)
    })
  
  //------------upcoming Presentation Pictures---------------
  await axios.get('gettworeportinfo/tworpttopsec').then(
    response => {
        this.tworeportsectop = response.data
        this.rpttoptext = response.data[0].rpttopcaption
    })
  .catch((error) => {
      console.log(error)
    })
    
    
    //------------Three section images ---------------
  await axios.get('getthreesectioncards/homepage').then(
      response => {
        this.cmimage1 = response.data[0].imageonepath
        this.cmimage2 = response.data[0].imagetwopath
        this.cmimage3 = response.data[0].imagethreepath
        this.imageonelink = response.data[0].imageonelink 
        this.imagetwolink = response.data[0].imagetwolink 
        this.imagethreelink = response.data[0].imagethreelink
        this.timageonedesc = response.data[0].timageonedesc 
        this.timagetwodesc = response.data[0].timagetwodesc
        this.timagethreeedesc = response.data[0].timagethreeedesc
        this.toptext = response.data[0].toptext
    })
    .catch((error) => {
        console.log(error)
      })



}



}
</script>
<style lang="scss" scoped>
.bannersection1 {
margin-top: -20px;
height: 200px;
padding-top: 20px;
padding-bottom: 20px;
padding-left: 150px;
padding-right: 150px;
}

.transition {
transition: all 3s ease-out;
animation: fadeInAnimation ease 3s;
animation-iteration-count: 1;
animation-fill-mode: backwards;
}

@keyframes fadeInAnimation {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
.videosection {
background-color: #000000;
}

.overlayvideo{
opacity: .4;
height: 600px;
background-color: #000000;
}
.overlayvideo {
opacity: 0.55;
width: 100%;
height: 670px;
background: linear-gradient(to bottom, #000000);
margin-top: -670px;
}


.overlayimage{
opacity: .4;
height: 600px;
background-color: #000000;
}
.overlayimage {
opacity: 0.55;
width: 100%;
height: 670px;
background: linear-gradient(to bottom, #000000);
margin-top: -670px;
}

.overlaycarousel{
opacity: .4;
height: 600px;
background-color: #000000;
}
.overlaycarousel {
opacity: 0.55;
width: 100%;
height: 670px;
background: linear-gradient(to bottom, #000000);
margin-top: -670px;
}

.btnformat {
  border-radius: 50%;
  padding: 14px;
  margin-left: 10px;
}

.playercss {
max-height: 300%; 
height: 70vh;

}


.thing {
transition-timing-function: ease;
transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

img {
width: 90%;
height: 90%;
object-fit: cover;
background-color: #000000;
}


[data-aos="new-animation"] {
opacity: 0;
transition-property: transform, opacity;
}

.clientextinfo {
width: 100%;
margin: 0 auto;
margin: 0 auto; 
text-align: center;
margin-top: 130px;
}
.carousel {
position: relative;
max-height: 100vh;
height: 80vh;
margin-top: -100px;
z-index: -9999;

.slide-info {
position: absolute;
top: 0;
left: 0;
min-width: 100%;
max-height: 100%;
height: 100%;

img {
min-width: 100%;
height: 100%;
object-fit: cover;
opacity: 0.99;
}
}
}
.pushbyeighty {
margin-top: 166px;
}

.clientextinfo {
width: 100%;
margin: 0 auto;
margin: 0 auto; 
text-align: center;
margin-top: 130px;
}
.carousel {
position: relative;
max-height: 100vh;
height: 80vh;
margin-top: -100px;
z-index: -9999;

.slide-info {
position: absolute;
top: 0;
left: 0;
min-width: 100%;
max-height: 100%;
height: 100%;

img {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.99;
}
}

}
.txtselected {
text-align: justify;
}
.container {

padding-top: 20px;
}
.wrapcontent {
padding-top: 50px;
padding-bottom: 50px;
}
.videosec {
margin-top: 160px;
}

.secimage {
margin-top: 160px;
}
.imgcss {
width: 100%;
}
.rndbtnformat {
  margin-top: -120px;
  margin-left: 100px;
}

/*-----------------Spinner------------*/
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 150px; /* Adjust size as needed */
  height: 150px;
}
@media only screen and (max-width: 600px) {
.bannersection1 {
width: 100%;
height: auto;
padding-left: 0px;
padding-right: 0px;
} 
.imgcss {
width: 100%; 
height: 40vh;
margin-top: -80px;
}
.overlayimage, .overlayvideo{
opacity: 0.55;
width: 100%;
height: 90vh;
background: linear-gradient(to bottom, #000000);

}
.container {
padding-left: 20px;
padding-right: 20px;
}

.txtdatavideo  {
position: absolute;
margin-top: 250px;
z-index: 9999999999999;
color: whitesmoke;
padding-left: 30px;
}

.txtdataimage  {
position: absolute;
margin-top: 150px;
z-index: 9999999999999;
color: whitesmoke;
padding-left: 30px;
}
.rndbtnformat {
  margin-top: -60px;
}

}
@media only screen and (min-width: 600px) {
.bannersection1 {
width: 100%;
height: auto;
padding-left: 0px;
padding-right: 0px;
} 

.txtdatavideo  {
position: absolute;
margin-top: 250px;
z-index: 9999;
color: whitesmoke;
padding-left: 60px;
}

.txtdataimage  {
position: absolute;
margin-top: 100px;
z-index: 9;
color: whitesmoke;
padding-left: 30px;
}
.imgcss {
width: 100%; 
height: 40vh;
margin-top: -80px;
}
.rndbtnformat {
  margin-top: 60px;
}
}

@media screen and (min-width:769px) {
.txtdataimage  {
position: absolute;
margin-top: 170px;
z-index: 9;
color: whitesmoke;
padding-left: 30px;
}

.txtdatavideo  {
position: absolute;
margin-top: 250px;
z-index: 9999;
color: whitesmoke;
padding-left: 100px;
}

.txtdataimage  {
position: absolute;
margin-top: 100px;
z-index: 9;
color: whitesmoke;
padding-left: 30px;
}

.imgcss {
width: 100%;
height: 50vh;
}

.rndbtnformat {
  margin-top: 60px;
}

}

@media only screen and (min-width: 992px) {

.txtdataimage  {
position: absolute;
margin-top: 140px;
z-index: 9;
color: whitesmoke;
padding-left: 30px;
}

.imgcss {
width: 100%;
height: 50vh;
}
}
@media only screen and (min-width: 1200px) {
.txtdataimage  {
position: absolute;
margin-top: 350px;
z-index: 9;
color: whitesmoke;
padding-left: 80px;
}

.imgcss {
width: 100%;
height: 70vh;

}
.playercss {
height: 650px;
}

.txtselected {
padding-right: 60px;
text-align: justify;
}
.txtleftalign {
text-align: justify;
padding-left: 60px;
}
.rndbtnformat {
  margin-top: -140px;
}
}

@media only screen and (min-width: 1600px) {
.txtdataimage  {
position: absolute;
margin-top: 350px;
z-index: 9;
color: whitesmoke;
padding-left: 300px;
}

.txtdatavideo  {
position: absolute;
margin-top: 250px;
z-index: 9999;
color: whitesmoke;
padding-left: 300px;
}

.imgcss {
width: 100%;
height: 70vh;
}
.overlaycarousel {
opacity: 0.55;
width: 100%;
height: 770px;
background: linear-gradient(to bottom, #000000);
margin-top: -770px;
}
.rndbtnformat {
  margin-top: -140px;
  margin-left: 300px;
}
}

</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TexttoVoice from '@/views/TexttoVoice.vue'
import NbAbout from '@/views/NbAbout.vue'
import AboutUs from '@/views/AboutUs.vue'
import WhatwedoOverview from '@/views/WhatwedoOverview.vue'
import NbWhatwedo from '@/views/NbWhatwedo.vue'
import NbIndustries from '@/views/NbIndustries.vue'
import NbServices from '@/views/NbServices.vue'
import NbCloud from '@/views/NbCloud.vue'
import NbSecurity from '@/views/NbSecurity.vue'
import EnterpriseSolution from '@/views/EnterpriseSolution.vue'
import ProjectManagement from '@/views/ProjectManagement.vue'
import NetworkingServices from '@/views/NetworkingServices.vue'
import ConsultingServices from '@/views/ConsultingServices.vue'
import DataManagement from '@/views/DataManagement.vue'
import EnergyServices from '@/views/EnergyServices.vue'
import RetailServices from '@/views/RetailServices.vue'
import PublicSector from '@/views/PublicSector.vue'
import TravelLogistics from '@/views/TravelLogistics.vue'
import ManufacturingServices from '@/views/ManufacturingServices.vue'
import EducationServices from '@/views/EducationServices.vue'
import HealthCare from '@/views/HealthCare.vue'
import WhoweareOverview from '@/views/WhoweareOverview.vue'
import NbEvents from '@/views/NbEvents.vue'
import CsrDiversity from '@/views/CsrDiversity.vue'
import NbLeadership from '@/views/NbLeadership.vue'
import NbCareers from '@/views/NbCareers.vue'
import NbPolicies from '@/views/NbPolicies.vue'
import NbInsights from '@/views/NbInsights.vue'
import NbCustomerstories from '@/views/NbCustomerstories.vue'
import NbInfrastructure from '@/views/NbInfrastructure.vue'
import NbBlogs from '@/views/NbBlogs.vue'
import NbArticles from '@/views/NbArticles.vue'
import CyberSecurity from '@/views/CyberSecurity.vue'
import NbContact from '@/views/NbContact.vue'
import NewsDetail from '@/views/NewsDetail.vue'
import AllNews from '@/views/AllNews.vue'
import NbNews from '@/views/NbNews.vue'
import SingleNewsread from '@/views/SingleNewsread.vue'
import ArticleReading from '@/views/ArticleReading.vue'
import NbCategories from '@/views/NbCategories.vue'
import NbSitemapview from '@/views/NbSitemapview.vue'
import NbCharts from '@/views/NbCharts.vue'

const routes = [
  {
    path: '/', name: 'home', component: HomeView,
    path: '/', name: 'homefr', component: HomeView,
  },


  { path: '/en/texttovoice/', name: 'texttovoice', component: TexttoVoice,},

  { path: '/en/getchart/', name: 'nbchart', component: NbCharts,},

//      //===================WHAT WE DO==================================
  { path: '/en/what-we-do/', name: 'whatwedoen', component: NbWhatwedo,},
  { path: '/fr/que-nous-faisons/', name: 'whatwedofr', component: NbWhatwedo,},

  { path: '/en/what-we-do/overview/', name: 'wwdooverviewen', component: WhatwedoOverview,},
  { path: '/fr/que-nous-faisons/apercu/', name: 'wwdooverviewfr', component: WhatwedoOverview,},

  { path: '/en/what-we-do/industries/', name: 'wwdoindustriesen', component: NbIndustries,},
  { path: '/fr/que-nous-faisons/industries/', name: 'wwdoindustriesfr', component: NbIndustries,},

  { path: '/en/what-we-do/services/', name: 'wwdoservicesen', component: NbServices,},
  { path: '/fr/que-nous-faisons/services/', name: 'wwdoservicesfr', component: NbServices,},

  { path: '/en/what-we-do/services/cloud/', name: 'clouden', component: NbCloud,},
  { path: '/fr/que-nous-faisons/services/nuage/', name: 'cloudfr', component: NbCloud,},

  { path: '/en/what-we-do/services/security/', name: 'securityen', component: NbSecurity,},
  { path: '/fr/que-nous-faisons/services/securite/', name: 'securityfr', component: NbSecurity,},

  { path: '/en/what-we-do/services/enterprise-solutions/', name: 'enterprisesolen', component: EnterpriseSolution,},
  { path: '/fr/que-nous-faisons/services/solutions d-entreprise/', name: 'enterprisesolfr', component: EnterpriseSolution,},

  { path: '/en/what-we-do/services/project-management/', name: 'pmgmten', component: ProjectManagement,},
  { path: '/fr/que-nous-faisons/services/gestion-de-projet/', name: 'pmgmtfr', component: ProjectManagement,},

  { path: '/en/what-we-do/services/networking-solutions-&-services/', name: 'networksvcen', component: NetworkingServices,},
  { path: '/fr/que-nous-faisons/services/solutions-et-services-de-mise-en-reseau/', name: 'networksvcfr', component: NetworkingServices,},

 { path: '/en/what-we-do/services/consulting-services/', name: 'consultingwwden', component: ConsultingServices,},
  { path: '/fr/que-nous-faisons/services/services-de-conseil/', name: 'consultingwwdfr', component: ConsultingServices,},

  { path: '/en/what-we-do/services/data-management/', name: 'datamgmtwwden', component: DataManagement,},
  { path: '/fr/que-nous-faisons/services/gestion-des-donnees/', name: 'datamgmtwwdfr', component: DataManagement,},

  { path: '/en/what-we-do/services/energy/', name: 'energyen', component: EnergyServices,},
  { path: '/fr/que-nous-faisons/services/energie/', name: 'energyfr', component: EnergyServices,},

  { path: '/en/what-we-do/services/retail/', name: 'retailen', component: RetailServices,},
  { path: '/fr/que-nous-faisons/services/vente-au-detail/', name: 'retailfr', component: RetailServices,},

  { path: '/en/what-we-do/services/public-sector/', name: 'publicsectoren', component: PublicSector,},
  { path: '/fr/que-nous-faisons/services/secteur-public/', name: 'publicsectorfr', component: PublicSector,},

  { path: '/en/what-we-do/services/travel-&-logistics/', name: 'travelen', component: TravelLogistics,},
  { path: '/fr/que-nous-faisons/services/voyage-et-logistique/', name: 'travelfr', component: TravelLogistics,},

  { path: '/en/what-we-do/services/manufacturing/', name: 'manufacturingen', component: ManufacturingServices,},
  { path: '/fr/que-nous-faisons/services/fabrication/', name: 'manufacturingfr', component: ManufacturingServices,},

  { path: '/en/what-we-do/services/education/', name: 'educationen', component: EducationServices,},
  { path: '/fr/que-nous-faisons/services/education/', name: 'educationfr', component: EducationServices,},

  { path: '/en/what-we-do/services/healthcare/', name: 'healthcareen', component: HealthCare,},
  { path: '/fr/que-nous-faisons/services/soins-de-sante/', name: 'healthcarefr', component: HealthCare,},

//  //===================WHO WE ARE======================================
{ path: '/en/who-we-are/', name: 'whoweareen', component: WhoweareOverview,},
{ path: '/fr/qui-nous-sommes/', name: 'whowearefr', component: WhoweareOverview,},

{ path: '/en/who-we-are/overview/', name: 'wwaoverviewen', component: WhoweareOverview,},
{ path: '/fr/qui-nous-sommes/apercu/', name: 'wwaoverviewfr', component: WhoweareOverview,},

{ path: '/en/who-we-are/about-us/', name: 'wwaaboutusen', component: AboutUs,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/', name: 'wwaaboutusfr', component: AboutUs,},

{ path: '/en/who-we-are/about-us/directory/', name: 'contactsen', component: NbContact,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/annuaire/', name: 'contactsfr', component: NbContact,},

{ path: '/en/who-we-are/events/', name: 'wwaeventsen', component: NbEvents,},
{ path: '/fr/qui-nous-sommes/evenements/', name: 'wwaeventsfr', component: NbEvents,},

{ path: '/en/who-we-are/about-us/diversity/', name: 'diversityen', component: CsrDiversity,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/diversite/', name: 'diversityfr', component: CsrDiversity,},

{ path: '/en/who-we-are/about-us/corporate-social-responsibility/', name: 'csren', component: CsrDiversity,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/responsabilite-sociale-d-entreprise/', name: 'csrfr', component: CsrDiversity,},

{ path: '/en/who-we-are/about-us/leadership/', name: 'leadershipen', component: NbLeadership,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/direction/', name: 'leadershipfr', component: NbLeadership,},

{ path: '/en/who-we-are/about-us/careers/', name: 'careersen', component: NbCareers,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/carrieres/', name: 'careersfr', component: NbCareers,},

{ path: '/en/who-we-are/about-us/our-policies/', name: 'ourpoliciesen', component: NbPolicies,},
{ path: '/fr/qui-nous-sommes/a-propos-de-nous/nos-politiques/', name: 'ourpoliciesfr', component: NbPolicies,},



//  //===================INSIGHTS======================================
{ path: '/en/insights/', name: 'insightsen', component: NbInsights,},
{ path: '/fr/connaissances/', name: 'insightsfr', component: NbInsights,},

{ path: '/en/insights/overview/', name: 'insightsoverviewen', component: NbInsights,},
{ path: '/fr/connaissances/apercu/', name: 'insightsoverviewfr', component: NbInsights,},

{ path: '/en/insights/customer-stories/', name: 'ishistorycustcen', component: NbCustomerstories,},
{ path: '/fr/connaissances/histoires-clients/', name: 'ishistorycustcfr', component: NbCustomerstories,},

{ path: '/en/insights/categories/', name: 'iscaten', component: NbCategories,},
{ path: '/fr/connaissances/categories/', name: 'iscatfr', component: NbCategories,},

{ path: '/en/insights/categories/cybersecurity/', name: 'cybersecuritycaten', component: CyberSecurity,},
{ path: '/fr/connaissances/categories/cybersecurite/', name: 'cybersecuritycatfr', component: CyberSecurity,},

{ path: '/en/insights/categories/enterprise-solutions/', name: 'enterprisesolcaten', component: EnterpriseSolution,},
{ path: '/fr/connaissances/categories/solutions d-entreprise/', name: 'enterprisesolcatfr', component: EnterpriseSolution,},

{ path: '/en/insights/categories/project-management/', name: 'pmen', component: ProjectManagement,},
{ path: '/fr/connaissances/categories/gestion-de-projet/', name: 'pmfr', component: ProjectManagement,},

{ path: '/en/insights/categories/networking-solutions-&-services/', name: 'networken', component: NetworkingServices,},
{ path: '/fr/connaissances/categories/solutions-et-services-de-mise-en-reseau/', name: 'networkfr', component: NetworkingServices,},

{ path: '/en/insights/categories/consulting-services/', name: 'consultingen', component: ConsultingServices,},
{ path: '/fr/connaissances/categories/services-de-conseil/', name: 'consultingfr', component: ConsultingServices,},

{ path: '/en/insights/categories/data-management/', name: 'datamgmten', component: DataManagement,},
{ path: '/fr/connaissances/categories/gestion-des-donnees/', name: 'datamgmtfr', component: DataManagement,},


//  //===================OUR INTEREST======================================
{ path: '/en/our-interest/', name: 'ourinteresten', component: HomeView,},
{ path: '/fr/notre-interet/', name: 'ourinterestfr', component: HomeView,},

{ path: '/en//our-interest/infrastructure/', name: 'infrstructureen', component: NbInfrastructure,},
{ path: '/fr/notre-interet/infrastructure/', name: 'infrstructurefr', component: NbInfrastructure,},

{ path: '/en/our-interest/data-management/', name: 'infradatamgmtoien', component: DataManagement,},
{ path: '/fr/notre-interet/gestion-de-donnees/', name: 'infradatamgmtoifr', component: DataManagement,},

{ path: '/en/our-interest/security/', name: 'infrasecurityen', component: NbSecurity,},
{ path: '/fr/notre-interet/securite/', name: 'infrasecurityfr', component: NbSecurity,},

{ path: '/en/our-interest/cybersecurity/', name: 'cybersecurityen', component: NbSecurity,},
{ path: '/fr/notre-interet/cybersecurite/', name: 'cybersecurityfr', component: NbSecurity,},

{ path: '/en/our-interest/project-management/', name: 'infrapmen', component: ProjectManagement,},
{ path: '/fr/notre-interet/gestion-de-projet/', name: 'infrapmfr', component: ProjectManagement,},



  // All news and article links
  { path: '/en/blogs-articles-&-news/', name: 'newsen', component: NbNews,},
  { path: '/fr/blogs-articles-et-nouvelles/', name: 'newsfr', component: NbNews,},
  
  { path: '/en/blogs-articles-&-news/:newscatid', name: 'catnewsen', component: NbNews,},
  { path: '/fr/blogs-articles-et-l-nouvelles/:newscatid', name: 'catnewsfr', component: NbNews,},
  
  { path: '/en/blogs-articles-&-news/', name: 'allnewsen', component: AllNews,},
  { path: '/fr/blogs-articles-et-toute l-actualite/', name: 'allnewsfr', component: AllNews,},
  
  { path: '/en/blogs-articles-&-news/read/', name: 'newsdetailsen', component: NewsDetail,},
  { path: '/fr/blogs-articles-et-l-nouvelles/lire/', name: 'newsdetailsfr', component: NewsDetail,},

  { path: '/en/blogs-articles-&-news/single-read/:newssingleid', name: 'newssingleen', component: SingleNewsread,},
  { path: '/fr/blogs-articles-et-l-nouvelles/lecture-d-une-seule-nouvelle/:newssingleid', name: 'newsinglefr', component: SingleNewsread,},

  { path: '/en/articles/:newssingleid', name: 'articleen', component: NbArticles,},
  { path: '/fr/blogs-articles-et-l-nouvelles/articles/:newssingleid', name: 'articlefr', component: NbArticles,},

  { path: '/en/articles/', name: 'actarticleen', component: NbArticles,},
  { path: '/fr/articles/', name: 'actarticlefr', component: NbArticles,},

  { path: '/en/articles/read-article/:articleid', name: 'readarticleen', component: ArticleReading,},
  { path: '/fr/articles/lire-l-article/:articleid', name: 'readarticlefr', component: ArticleReading,},

  { path: '/en/contact-us/', name: 'subscribeen', component: NbContact,},
  { path: '/fr/Contactez-nous/', name: 'subscribefr', component: NbContact,},

  { path: '/en/sitemap/', name: 'sitemapen', component: NbSitemapview,},
  { path: '/fr/plan-du-site/', name: 'sitemapfr', component: NbSitemapview,},

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

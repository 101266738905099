<template>


 <!------Top banner section-->
<section class="bannersection1" v-show="showallpagesbanner === true"
:style="colortheme === 'darkTheme' ? `${darkstyle}` :  `${allpagesbannercolor}; color: white;`">
<AllPagesBanner :bannertext="mbtnsectext" :colortheme="colortheme" />
</section>
  

<section style="padding: 100px; ">
  <SpeechText />
</section>
   
</template>

<script>
// import TextTovoicelist from '@/components/TextTovoicelist.vue';
import axios from 'axios';
import NbCarousel from "@/components/NbCarousel.vue";
import SwiperSlide from "@/components/SwiperSlide.vue";
import RealSlideshow from '@/components/RealSlideshow.vue';
import AllPagesBanner from '@/components/AllPagesBanner.vue';

import BreadCrumbs from '@/components/BreadCrumbs.vue';
import SpeechText from '@/components/SpeechText.vue';


export default {
components: {NbCarousel, AllPagesBanner, SwiperSlide, RealSlideshow,  BreadCrumbs, SpeechText
},
data(){
    return {
    carouselSlides: [],
    videoimage: [],
    imagetext: '',
    src: '',
    playrate: '',
    poster: '',
    pvstyle: '',
    pvsrc: '',

    pvpictureimage: false,

    allpages: [],
    allslidesinfo: [],
    allslidestext: '',

    //pagesettings
    myImgUrl: myUrlPath,
    imagetext: '',
    btmimagetext: '',
    colortheme: localStorage.getItem('colorTheme'),
    showslideshow: false,
    showvideo: false,
    showpictureimage: false,
    showbanner: false,
    darkstyle: '',
    alternatedarkstyle: '',
    whitesmokestyle: '',
    submenustyle: '',
    showallpagesbanner: false,
    allpagesbannercolor: '',
    }
},
async mounted() {
    //-------------get page theme------------------
    this.nbTheme = localStorage.getItem('nbtheme')
  

    this.myPicUrl = myUrlPath
  
      //-------------page carousel section------------------
    await axios.get('getslideshow/whatwedooverview').then(
      response => {
        this.carouselSlides = response.data
      }).catch((error) => {
        console.log(error)
      })  
  
      //------------------slider section------------------
    await axios.get('getclientsliders/whatwedooverview').then(
      response => {
        this.images = response.data
      }).catch((error) => {
        console.log(error)
      })  
  
      //-------------videos scetion------------------
    await axios.get('allpagespv/whatwedooverview').then(
      response => {
        this.videoimage = response.data      
        this.imagetext = response.data[0].pvdisplaytext 
        this.src = this.myImgUrl + response.data[0].pvsrc
        this.playrate = response.data[0].playbackrate
        this.poster = this.myImgUrl + response.data[0].pvposter
        this.pvstyle = response.data[0].pvstyle
        this.pvpictureimage = this.myImgUrl + response.data[0].pvpictureimage
      })
      .catch((error) => {
          console.log(error)
        })
  
    //Breadcrum info
    await axios.get('getbreadcrumb/whatwedooverview').then(
        response => {
          // this.allpages = response.data
          this.breadcrumbdata = response.data
        })
        .catch((error) => {
            console.log(error)
          })
  
  // ------------------Design it your way section------------------
  await axios.get('getdesignyourwaysection/whatwedooverview').then(
      response => {
        this.designuwdata = response.data
      }).catch((error) => {
        console.log(error)
      }) 
  
      //-------------all pages settings------------------
    await axios.get('getwebsitesettings/whatwedooverview').then(
        response => {         
          this.menuname = 'whatwedooverview'
          localStorage.setItem('menuname', this.menuname)
          this.webdesc = response.data[0].webdesc
          this.showslideshow = response.data[0].showslideshow
          this.showvideo = response.data[0].showvideo
          this.showpictureimage = response.data[0].showpictureimage
          this.topmenubarbgcolor = response.data[0].topmenubarbgcolor
          this.mainmenubarcolor = response.data[0].mainmenubarcolor
          this.darkstyle = response.data[0].darkstyle
          this.alternatedarkstyle = response.data[0].alternatedarkstyle
          this.whitesmokestyle = response.data[0].whitesmokestyle
  
          this.submenustyle = response.data[0].submenustyle
          this.allcardtoptext = response.data[0].allcardtoptext
          this.showallpagesbanner = response.data[0].showallpagesbanner
          this.allpagesbannercolor = response.data[0].allpagesbannercolor
          
          this.showhzsecbottom = response.data[0].showhzsecbottom

        })
      .catch((error) => {
          console.log(error)
      })
    
    //-------------PBANNER PAGE------------------
    axios.get('getbannerinfo/whatwedooverview').then(
        response => {
        console.log(response.data)
            this.mbtnsectext = response.data.mbtnsectext
            this.bgcolor = response.data.bgcolor
            this.setbannercolor = response.data.setbannercolor
    }) .catch((error) => {
        console.log(error)
        })
}
}
</script>

<style scoped>

</style>
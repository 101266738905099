<template>
    <!--     Loop through displayedPosts to display each post     -->
    <section v-for="dsec in designuwdata" 
        :style="colortheme==='darkTheme' ? 'background-color: black; color: white' 
        : `${dsec.dysecbgcolor}  padding-bottom: 50px; `"
        v-show="dsec.showsection === true"
        >

      <div class="container" style="margin-top: -10px;">
        <div  :key="index" class="forminnercontainer"  data-aos="fade-up" data-aos-duration="2500" >
            <p v-html="dsec.dysectoptexttext" v-show="dsec.showtoptext === true"></p>
          <div class="row">
            <div class="col-sm-12 col-md-6 leftcoltop">
                <div v-html="dsec.dyseclefttext" class="lefttextfmt"></div>
                <div class="lefttextimage" v-show="dsec.showleftimage === true">
                    <router-link :to="`${dsec.dysecleftimglinkpath}`" style="text-decoration: none;">
                        <img :src="`${myPicUrl}${dsec.dysecleftimgpath}`" class="shadow" />
                        <div class="imgdesc">{{dsec.dysecleftimgdesc}}</div>
                    </router-link>
                </div>
                <div class="leftvideo"  v-show="dsec.showleftvideo === true">
                    <HtmlVideoplayer :videourl="`${dsec.dysecleftvideo}`" :baseurl1="myPicUrl" :poster="`${dsec.dysecrighposterleftpath}`" />
                </div>
                
                <btn class="btn btn-outline-info" style="width: 200px; padding: 14px; float: left; margin-top: 40px;">
                    <router-link :to="`${dsec.dysecleftimglinkpath}`" style="text-decoration: none;">
                        Learn more
                    </router-link>
                </btn>
            </div>
            <div class="col-sm-12 col-md-6 colsmall rightcoltop">
                <div v-html="dsec.dysecrighttext" class="righttextfmt" ></div>
                <div class="righttextimage" v-show="dsec.showrightimage === true">
                    <router-link :to="`${dsec.dysecrightimglinkpath}`" style="text-decoration: none;">
                        <img :src="`${myPicUrl}${dsec.dysecrightimgpath}`" class="shadow" />
                        <div class="imgdesc">{{dsec.dysecrightimgdesc}}</div>
                    </router-link>
                </div>
                <div class="rightvideo"  v-show="dsec.showrightvideo === true">
                    <HtmlVideoplayer :videourl="`${dsec.dysecrightvideo}`" :baseurl1="myPicUrl" :poster="`${dsec.dysecrighposterrightpath}`" />
                </div>

                <btn class="btn btn-outline-info" style="width: 200px; padding: 14px; float: right; margin-top: 40px;">
                    <router-link :to="`${dsec.dysecrightimglinkpath}`" style="text-decoration: none;">
                        Learn more
                    </router-link>
                </btn>
          </div>
        </div>
      </div>  
      </div>
    </section>
    
</template>
    
<script>
import axios from 'axios';
import HtmlVideoplayer from './HtmlVideoplayer.vue';
require('@/globalDeclare.js');

export default {
    props: ['designuwdata'],
    components: {
        HtmlVideoplayer
    },
    data() {
        return {
            myPicUrl: '',
            colortheme: localStorage.getItem('colorTheme')
        }
    },
    mounted(){
        this.myPicUrl = myUrlPath
    },
    
    
}
</script>

<style scoped>
img {
    object-fit: cover;
}
.lefttextimage, .leftvideo {
    padding-right: 50px;
}
.righttextimage, .rightvideo {
    padding-left: 50px;
}

.lefttextfmt, .righttextfmt {
    margin-bottom: 40px;
}

.leftvideo, .rightvideo {
    margin-top: 60px;
}
.leftcoltop, .rightcoltop {
    margin-bottom: 50px;
    margin-top: 50px;
}

img {
    width: 100%;
    height: 30vh;
    border-radius: 5px;
}
.imgdesc {
    margin-top: -50px;
    text-align: center;
    background-color: grey;
    color: white;
    font-weight: bold;
    font-size: 20px;
}
.lefttextfmt {
    padding-right: 40px;
}
.righttextfmt {
    padding-left: 60px;
}
.forminnercontainer {
  padding-top: 50px;
  padding-bottom: 100px;
}

    /*===================MEDIA SCREENS TAMPLATES===============*/
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {


    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {


    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {

    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {

    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {


    } 
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1400px) {
    
    
    } 
</style>
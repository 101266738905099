<template>
        <div class="container sectioncontrol" data-aos="fade-up">
          <div class="sectiontwopurpose">{{ cvdesc }}</div>
          <div class="row g-4">
            <div class="col-xs-12 col-sm-12 col-md-12 sectiontwocol8"  v-for="contactin in contactdir">
              <div class="card shadow w-100 h-100 servicescards" style="color: whitesmoke;">
                <div v-html="contactin.apdtext" style="padding: 20px;"></div>
              </div>
            </div>
          </div>
        </div>
</template>


<script>
export default {
    props: ['contactdir', 'contactdirtheme'],
}
</script>

<style scoped>
.sectioncontrol{
  opacity: .9;
  padding-top: 100px;
  height: auto;
}


.sectiontwo {
  margin-bottom: 100px;
  background-color: #f9ebea;
  
 }
.servicescards {
  background-color: #003;
  color: white;
  text-align: center;
}
.iconsize {
  width: 100px;
}
.card-title{
  color: white;
  text-decoration: none;
}
.cardref{
  text-decoration: none;
}

/*===================MEDIA SCREENS TAMPLATES===============*/
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .sectiontwo {
    margin-bottom: 100px;
    background-color: #f9ebea;
    margin-top: -20px;
   }
   .sectiontwopurpose{
    margin-top: -30px;
   }
   .card-title{
    width: 100%;
    font-size: 18px;
   }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  
 }
 
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 800px) {
    .card-title {
      font-size: 10px;
    }
  }

 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    
 } 
</style>
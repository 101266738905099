<template>
  <div class="container" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
      
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-10" id="content">
        <div class="toptitle">
          <ul>
            <li><span class="titlecaptionfmt">{{ $t('Article title') }}:</span></li>
              <li>
                <div v-html="articletitle" class="titlefmt"  
                :style="colortheme === 'darkTheme' ? `${darkstyle} color: whitesmoke` : 'color: #003; font-size: 16px;'">
                </div>
              </li>
          </ul>
            
          <ul>
            <li>{{ $t('Date published') }}:</li>
            <li><i class="datefmt">{{ dateTime(artdate) }}</i></li>
          </ul>
    
          <ul style="margin-top: -20px;">
            <li>{{ $t('Article contributors') }}: <span class="badge bg-secondary badgecontrib" data-bs-toggle="modal" data-bs-target="#contributorsModal">{{ artcontributors.length }} View</span></li>
          </ul>
        </div>

      <div v-html="artfulltext"   
        :style="colortheme === 'darkTheme' ? `${darkstyle} color: whitesmoke;` :  'color:#003; padding-right: 30px; text-align: justify;'" class="containerformat"></div>
      </div>
        <div class="col-xs-12 col-sm-12 col-md-2">
          <div class="rcatitemsformat" 
            :style="colortheme === 'darkTheme' ? `${darkstyle} color: whitesmoke` : 'color: #003; font-size: 16px;'">
            {{ $t('Related items') }}
          </div>
          <div class="col" v-for="expost in excludedpost" style="padding-bottom: 40px;">
            
            <a :href="`/en/articles/read-article/${expost.artid}`" v-show="lang === 'en'">
              <div class="card">
                <img :src="`${myImgUrl}${expost.artpic}`" class="card-img-top" alt="" style="width: 100%; height: 14vh;">
                <div class="card-body">
                  <h5 class="card-title">{{ expost.artdesc }}</h5>
                  
                </div>
            </div>
          </a>
          <a :href="`/fr/articles/lire-l-article/${expost.artid}`" v-show="lang === 'fr'">
            <div class="card">
              <img :src="`${myImgUrl}${expost.artpic}`" class="card-img-top" alt="" style="width: 100%; height: 14vh;">
              <div class="card-body">
                <p class="card-title sidedescformat">{{ expost.artdesc }}</p>
                
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div style="float: left; padding-top: 30px;" >
      <router-link :to="{path: '/en/blogs-articles-&-news/'}" class="btn btn-primary" style="border-radius: 5px;">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left"  class="arrowicons"/> Back
      </router-link>

      <!-- <router-link :to="{path: '/en/blogs-articles-&-news/'}" class="btn btn-primary" style="border-radius: 5px;">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left"  class="arrowicons"/> Back
      </router-link> -->

      <!-- <button @click="generatePDF">Download PDF</button> -->
      <a class="btn btn-primary btnformat" :href="`${myImgUrl}${artpdf}`"> Download PDF</a>
    </div>
  </div>
<!-- Button trigger modal -->



  <!-- <PdfViewer :pdfdoc="injectPdf" />  -->
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import PdfViewer from './PdfViewer.vue';
// import html2pdf from 'html2pdf.js';
require('@/defaultPath');

export default {
    props: ['articletitle', 'ardate', 'artcontributors', 'artfulltext', 'excludedpost', 'colortheme', 'darkstyle', 'artpdf'],
    components: {
      PdfViewer
    },
    // props: {
    //     paramValue: {
    //         type: Number, // Define the prop as a number
    //             required: true
    //             },
    //     },
    // props: ['getartid', 'contributors'],
    data() {
        return {
          myImgUrl: myUrlPath,
          lang: localStorage.getItem('lang'), 
          siteimage: '',   
        }
    },

methods: {
  dateTime(value) {
        return moment(value).format('YYYY-MMMM-DD');
      
    },

},

async mounted () {


  // 
}
}


</script>

<style scoped>

.sidedescformat{
  font-size: 16px;
}
.rcatitemsformat {
  font-size: 18px;
  font-weight: bold;  
  padding-bottom: 20px;
}
ul {
  list-style-type: none;
  display: flex;
  padding-left: 0px;
  padding-top: 10px;
}
.datefmt {
  margin-left: 14px;
}
.badgecontrib {
  margin-left: 16px;
}
.titlefmt {
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
}
.titlecaptionfmt {
  font-size: 20px;
}
.container {
  padding-bottom: 100px;
}
.btnformat {
  width: 140px;
  margin-left: 100px;
}
img {
  object-fit: cover;
}
</style>
<template>

  <section class="secimage" v-show="showpictureimage === true">
    <div class="txtdataimage">
      <h1  v-html="imagetext" :class="formatvhtml"></h1>
    </div>
    <div>
        <img :src="poster" class="imgcss" />
  </div>
  <div class="overlayimage"></div>
</section>

<section v-if="showpictureimage === false" style="margin-top: 140px;">

</section>


<!------Top banner section-->
<section  
  class="allpagesbanner slidebannerformat" 
  v-show="showallpagesbanner === true"  :style="colortheme === 'darkTheme' ? `${darkstyle}` :  `${allpagesbannercolor};`">
  <AllPagesBanner :bannertext="mbtnsectext" :colortheme="colortheme" />
</section>
  
<section  :style="colortheme === 'darkTheme' ? darkstyle :  ''">
  <div class="container-fluid aboutustop">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 formatldiv">
        <AboutusAll 
        :allpages="allpages" 
        :pinfotext="pinfotext" 
        :filtertext="filtertext" 
        :pinfotextfirst="pinfotextfirst" 
        :colortheme="colortheme" 
        :actionselected="activeaction"
        @getEventId="getEventData"/>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 formatrightdiv">
        <div v-if="filterevent === 'aboutus' || filterevent === 'ourpolicies'">
           <div v-html="pinfotext">

           </div>
        </div>
        <div v-else-if="filterevent === 'meetourteam'" class="cardsectionformat">
          <LeadershipCards :leadershipcodes="leadershipcodes" @getId="getProfile" :leadershipprofiles="leadershipprofiles" 
          @getId2="getProfileId" :getlinkProfileId="getlinkProfileId" :getprofileInfo="getprofileInfo" />
        </div>
        <div v-else-if="filterevent === 'careers'" class="careersformat">
          <JoinOurteam :colortheme="colortheme" :darkstyle="darkstyle" :careerpagetopinfo="careerpagetopinfo" :careerpagebottominfo="careerpagebottominfo" />
        </div>
        <div v-else>
          <div v-html="pinfotextfirst">

          </div>
        </div>
      
      </div>
    </div>

  </div>

</section>

<DesignYourway :designuwdata="designuwdata" />

<div style="margin-top: 0px;">
  <BreadCrumbs :breadcrumbdata="breadcrumbdata" :darkstyle="darkstyle" />
</div>

</template>

<script>
import axios from 'axios';
import AllPagesBanner from '@/components/AllPagesBanner.vue';
import LeadershipCards from '@/components/LeadershipCards.vue';
import JoinOurteam from '@/components/JoinOurteam.vue';
import DesignYourway from '@/components/DesignYourway.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';


require('@/defaultPath');


export default {
name: 'NbAbout',
components: {AllPagesBanner, LeadershipCards, JoinOurteam, DesignYourway, BreadCrumbs },

data() {
return {
  //-----------ALL PAGES VARIABLES====================
  carouselSlides: [],
    videoimage: [],
    imagetext: '',
    src: '',
    playrate: '',
    poster: '',
    pvstyle: '',
    pvsrc: '',

    pvpictureimage: false,

    allpages: [],
    allslidesinfo: [],
    allslidestext: '',

    //three section cards variables
    allcardsinfo: [],
    allcardmdcol: 4,

    //pagesettings
    myImgUrl: myUrlPath,
    imagetext: '',
    btmimagetext: '',
    colortheme: localStorage.getItem('colorTheme'),
    showslideshow: false,
    showvideo: false,
    showpictureimage: false,
    showleftside: false,
    showrightside: false,
    showclientslides: false,
    topmenubarbgcolor: '',
    mainmenubarcolor: '',
    menuname: '',
    darkstyle: '',
    alternatedarkstyle: '',
    whitesmokestyle: '',

    mbtnsectext: '',
    bgcolor: '',
    setbannercolor: false,
    showallpagesbanner: false,
    allpagesbannercolor: '',

    breadcrumbdata: [],
    
    defaultURL: defaultPath,
    lang: localStorage.getItem('lang'),

  //-----------ALL PAGES VARIABLES ENDS HERE====================
  dataltext: '', 
  datartext: '',
  datarlinktext: '',
  datarlinkpath: '',
  datalrbgcolor: '',
  datalwidth: '', 
  datarwidth: '',

  //Inspection text retrieval
  dataltext1: '', 
  datartext1: '',
  datarlinktext1: '',
  datarlinkpath1: '',
  datalrbgcolor1: '',
  datalwidth1: '', 
  datarwidth1: '',

  designuwdata: [],

  //client slider
  allclientslidesinfo: [],
  

  //clientinfo data
  getclientslidertext: '',

  //topslideshow
  carouselSlidesinfo: [],

  //everything news
  newstitle: '',
  newslinktext: '',
  newslink: '',
  allnews: [],
  cardpress: [],
  newscat: [],

  drplefttext: '',
  drpleftimagepath: '',
  drpleftcaption: '',
  imagelinkpath: '',

  dlprighttext: '', 
  dlprimagepath: '',
  dlprcatpion: '',
  imagelinkpath: '',

  images: [],
  imagelinkpath: '',

  loading: true, // Initially true to show the spinner
}
},

async mounted() {
 // Simulate a load time with a timeout (replace this with real data fetching)
//  setTimeout(() => {
//       this.loading = false; // Hide spinner after data is loaded
//     }, 2000);
//===========================INCLUDE IN ALL PAGES===================================
//-------------PAGE SPECIFIC CAROUSEL ENTERIES------------------

  // ------------------Design it your way section------------------
  await axios.get('getdesignyourwaysection/aboutus').then(
    response => {
      this.designuwdata = response.data
    }).catch((error) => {
      console.log(error)
    }) 

  //top slider
  // await axios.get('getclientsliders/platforms').then(
  //   response => {
  //     this.images = response.data
  //   }).catch((error) => {
  //     console.log(error)
  //   })  
//-------------PAGE SPECIFIC VIDEO ENTERIES------------------
  await axios.get('allpagespv/aboutus').then(
    response => {
      this.poster = this.myImgUrl + response.data[0].pvposter
    })
    .catch((error) => {
        console.log(error)
      })

//Breadcrum info
  await axios.get('getbreadcrumb/aboutus').then(
      response => {
        // this.allpages = response.data
        this.breadcrumbdata = response.data
      })
      .catch((error) => {
          console.log(error)
        })

//-------------ALL PAGES SETTINGS------------------
await axios.get('getwebsitesettings/aboutus').then(
    response => {         
      this.menuname = 'aboutus'
      localStorage.setItem('menuname', this.menuname)
      this.webdesc = response.data[0].webdesc
      this.showslideshow = response.data[0].showslideshow
      this.showvideo = response.data[0].showvideo
      this.showpictureimage = response.data[0].showpictureimage
      this.showleftside = response.data[0].showleftside
      this.showrightside = response.data[0].showrightside
      this.topmenubarbgcolor = response.data[0].topmenubarbgcolor
      this.mainmenubarcolor = response.data[0].mainmenubarcolor
      this.leftsidecolor = response.data[0].leftsidecolor
      this.showclientslides = response.data[0].showclientslides
      this.rightsidecolor = response.data[0].rightsidecolor
      this.darkstyle = response.data[0].darkstyle
      this.alternatedarkstyle = response.data[0].alternatedarkstyle
      this.whitesmokestyle = response.data[0].whitesmokestyle
      this.showallpagesbanner = response.data[0].showallpagesbanner
      this.allpagesbannercolor = response.data[0].allpagesbannercolor
    })
    .catch((error) => {
        console.log(error)
      })
//===========================INCLUDE IN ALL PAGES ENDS HERE===================================



//-------------PBANNER PAGE------------------
axios.get('getbannerinfo/aboutus').then(
    response => {
      console.log(response.data)
        this.mbtnsectext = response.data.mbtnsectext
        this.bgcolor = response.data.bgcolor
        this.setbannercolor = response.data.setbannercolor
  }) .catch((error) => {
      console.log(error)
    })




//get all cards information for homepage
await axios.get('getcardsinfo/aboutus').then(
response => {
  this.allcardsinfo = response.data
})
.catch((error) => {
    console.log(error)
  })
}



}
</script>
<style lang="scss" scoped>
.bannersection1 {
margin-top: -20px;
height: 200px;
padding-top: 20px;
padding-bottom: 20px;
padding-left: 150px;
padding-right: 150px;
}

.transition {
transition: all 3s ease-out;
animation: fadeInAnimation ease 3s;
animation-iteration-count: 1;
animation-fill-mode: backwards;
}

@keyframes fadeInAnimation {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
.videosection {
margin-top: -60px;
background-color: #000000;
}

.overlay{
opacity: .4;
height: 600px;
background-color: #000000;
}
.overlay {
opacity: 0.55;
width: 100%;
height: 670px;
background: linear-gradient(to bottom, #000000);
margin-top: -670px;
}



.playercss {
max-height: 300%; 
height: 70vh;

}


.thing {
transition-timing-function: ease;
transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

img {
width: 90%;
height: 90%;
object-fit: cover;
background-color: #000000;
}


[data-aos="new-animation"] {
opacity: 0;
transition-property: transform, opacity;
}

.clientextinfo {
width: 100%;
margin: 0 auto;
margin: 0 auto; 
text-align: center;
margin-top: 130px;
}
.carousel {
position: relative;
max-height: 100vh;
height: 80vh;
margin-top: -100px;
z-index: -9999;

.slide-info {
position: absolute;
top: 0;
left: 0;
min-width: 100%;
max-height: 100%;
height: 100%;

img {
min-width: 100%;
height: 100%;
object-fit: cover;
opacity: 0.99;
}
}
}
.pushbyeighty {
margin-top: 50px;
}

.clientextinfo {
width: 100%;
margin: 0 auto;
margin: 0 auto; 
text-align: center;
margin-top: 130px;
}
.carousel {
position: relative;
max-height: 100vh;
height: 80vh;
margin-top: -100px;
z-index: -9999;

.slide-info {
position: absolute;
top: 0;
left: 0;
min-width: 100%;
max-height: 100%;
height: 100%;

img {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.99;
}
}

}
.txtselected {
text-align: justify;
}
.container {

padding-top: 20px;
}
.wrapcontent {
padding-top: 50px;
padding-bottom: 50px;
}
/*-----------------Spinner------------*/
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 150px; /* Adjust size as needed */
  height: 150px;
}
@media only screen and (max-width: 600px) {
.bannersection1 {
width: 100%;
height: auto;
padding-left: 0px;
padding-right: 0px;
} 
.imgcss {
width: 100%; 
height: 400px;
margin-top: 120px;
}
.overlay {
opacity: 0.55;
width: 100%;
height: 400px;
background: linear-gradient(to bottom, #000000);
margin-top: -400px;
}
.container {
padding-left: 20px;
padding-right: 20px;
}

}
@media only screen and (min-width: 600px) {
.bannersection1 {
width: 100%;
height: auto;
padding-left: 0px;
padding-right: 0px;
} 
}

@media screen and (min-width:769px) {

}

@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {
.txtdata {
margin-top: 200px;
position: absolute;
z-index: 999999;
color: white;
margin-left: 140px;
}
.playercss {
height: 650px;
}
.imgcss {
width: 100%; 
height: 600px;
}
.txtselected {
padding-right: 60px;
text-align: justify;
}
.txtleftalign {
text-align: justify;
padding-left: 60px;
}
}

</style>
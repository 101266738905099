<template>
    <div class="eventcss">
     <div class="eventtitle">Upcoming Events</div>
     <div v-for="pevent in eventdata" class="dataindent">
       <div>
         <span class="eventdate">{{dateTime(pevent.eventdate)}}</span>
         <div class="eventdesc">{{ pevent.upevshortdesc}}</div>
       </div>
       
     </div>
    </div>
   </template>
   <script>
   import moment from 'moment';
   
   export default {
   props: ['eventdata'],
   methods: {
     dateTime(value) {
         return moment(value).format('YYYY-MMMM-DD');
       },
   },
   }
   </script>
   <style scoped>
   .eventtitle {
     font-size: 44px;
     font-weight: bold;  
     color: darkgrey;
     padding-bottom: 30px;
     padding-left: 30px;
     
   }
   .eventdesc {
     font-size: 18px;
     font-weight: bold;  
     color: #003;
     padding-bottom: 15px;
     text-wrap: wrap;
   
   }
   .eventdate {
     font-size: 16px;
     font-weight: bold;  
     color: grey;
   }
   .dataindent {
     text-indent: 5%;
   }
   img {
    object-fit: cover;
  }
   /*==============MEDIA QUERIES=====================*/
   @media only screen and (max-width: 600px) {
   
   }
   
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
   
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
     
    }
   
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
   
    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
   }
   
   /* Extra large devices (large laptops and desktops, 1200px and up) */
   @media only screen and (min-width: 1400px) {
   
   }
   
     /* Extra large devices (large laptops and desktops, 1200px and up) */
   @media only screen and (min-width: 1600px) {
   
   }
   </style>
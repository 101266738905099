<template>
    <section style="padding-top: 200px; padding-bottom: 200px;">
        <div id="app">
            <NbChartcomp />
          </div>
    </section>
    
  </template>
  
  <script>
  import NbChartcomp from '@/components/NbChartcomp.vue';
  
  export default {
    name: 'App',
    components: {
        NbChartcomp,
    },
  };
  </script>
  
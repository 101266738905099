<template>
  <div style="position: relative;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
    <div class="topmenubarcss" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: #252525; color: #003;'">
      <ul style="display: flex; float: right; " class="topbarformat">
        <li class="nav-item" style="list-style-type: none; font-weight: bold; text-decoration: none;">
          <div class="dropdown" style="margin-top: 9px;" >
            <a style="margin-right: 40px; color: white; font-size: 18px; text-decoration: none; list-style-type: none;"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon icon="fa-solid fa-globe" style="padding-right: 5px;" /><b v-show="lang==='en'" class="langsh">EN</b> <b v-show="lang==='fr'" class="langsh">FR</b>
            </a>

            <div class="dropdown-menu setwidthdp" :style="colortheme === 'darkTheme' ? 'background-color: white; color: white;' : 'background-color: whitesmoke; color: #003;'">
              <a class="dropdown-item" href="#" :class="[lang==='en' ? 'setEnLang' : 'nonColoren']" @click="changeLangEn($event, $event.target.value='en')"  
              style="max-width: 20px; padding: 10px; text-decoration: none;">En</a>
              <a class="dropdown-item" href="#" :class="[lang==='fr' ? 'setFrLang' : 'noColorfr']" @click="changeLangFr($event, $event.target.value='fr')"  
              style="max-width: 20px; padding: 10px;">Fr</a>
            </div>
          </div>
        </li>
        <li class="nav-item" style="list-style-type: none; font-weight: bold; text-decoration: none; margin-top: 10px; margin-right: 20px;"
        
        >
        
          <!-- <label class="switch" data-toggle="tooltip" title="Please click to toggle themes">
            <input type="checkbox" @click.prevent="toggleCheckbox" id="checkifcheck"> -->
          <input type="checkbox"  :checked="isChecked"  @change="toggleSwitch"  
              data-toggle="switchbutton" 
              id="checkifcheck"
              data-size="xs"
              style="margin-top: 10px; width: 300px; font-size: 8px;"
              data-width="130"
              checked :data-onlabel="switchdark" :data-offlabel="switchlight"
             
              >
              <!-- <input id="switch-trigger" type="checkbox" data-toggle="switchbutton" style="font-size: 10px; padding: 5px;"> -->
              <!-- <button class="btn btn-success" @click="clickApiOn()" style="font-size: 10px; padding: 5px;">{{ $t("Dark theme")}}</button>
              <button class="btn btn-danger" @click="clickApiOff()" style="font-size: 10px; padding: 5px;">{{ $t("Light theme")}}</button> -->
      
          <!-- <div class="slider round"></div>
          </label>  -->
        </li>
        <li style="list-style-type: none; color: white;" >
          <a style="text-decoration: none;">
            <font-awesome-icon  icon="fa-solid fa-magnifying-glass" class="nbsearch" data-bs-toggle="modal" data-bs-target="#searchModal"/>
          </a>
        </li>
        <li style="list-style-type: none;" class="nbmobilemenu " >
          <font-awesome-icon 
     
        icon="bars" data-bs-toggle="offcanvas" 
        data-bs-target="#offcanvasWithBothOptions" 
        aria-controls="offcanvasWithBothOptions"
        @mouseover="showOffcava()"
        />
        </li>
      </ul>
      
    </div>
  <nav  class="setnavbgcolor" style="margin-top: -120px;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
    <div class="container wrapper setnavbgcolor1" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
      <div class="wrappersec1" style="margin-top: 30px;">
        <li style="list-style-type: none;">
          <router-link :to="{path: '/'}">
          <img class="brandlogo" src="@/assets/nbblacklogo.png" v-if="colortheme === 'darkTheme'" />
          <img class="brandlogo" src="@/assets/nblogo.png" v-else />
        </router-link></li>
      </div>   
      <div class="wrappersec2 setnavbgcolor1" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
        <ul class="nav-links formatultopmenu"  style="margin-top: 30px;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
          <li v-for="(menu, index) in mainmenu" :key="menu.mid">
            <a href="#" class="desktop-item" :id="`${menu.mid}`" @mouseover="getId($event, $event.target.value=menu.mid)"  :class="[menu.mid===gml1 ? 'menulevel1' : '']"
            :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'"
            >
              {{ menu.mdesc }}
            </a>
            <input type="checkbox" >
            <label  class="mobile-item"  :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'">{{ menu.mdesc }}</label>
            <div v-if="showmegbox === true" class="mega-box" style="padding-top: 39px;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
              <div class="contentformat" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
                <div class="row" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white; ' : 'background-color: whitesmoke; color: #003; '">
                <div class="col formatrow4" style="min-width: 33%;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
                  <!-- <header>Design Services</header> -->
                  <ul class="mega-links" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
                    <li  v-for="secone in divsecone" :key="secone.did" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
                      <span v-if="secone.fields['secone.hasdropdown'] ===  true">
                        {{ secone.fields['ds1desc']}}
                      </span>
                      <span v-else>
                        <router-link :to="{path: `${secone.fields['ds1link']}`}" :id="`${secone.pk}`"
                        @mouseover="getsectwoId($event, $event.target.value=secone.pk)" 
                        @click="goToOpenMenu($event, $event.target.value=secone.pk); closeMegaBox()"
                        :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'"
                        >
                        {{ secone.fields['ds1desc']}}
                      </router-link>
                      </span>
                      
                    </li>
                  </ul>
                </div>
                <!-- :class="[secone.pk===gml2 ? 'menulevel2' : '']"  -->
                <div class="col " style="min-width: 33%;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
                  <!-- <header>Email Services</header> -->
                  <ul class="mega-links">
                    <li v-for="sectwo in divsectwo" :key="sectwo.did2"  style="line-height: 3rem;">
                      <span v-if="sectwo.fields['dsshowdesc']"  
                        :style="colortheme === 'darkTheme' ? 'color: white; line-height: 1.5rem; text-align: justify; font-size: 14px;' : 'color: #003; line-height: 1.5rem; text-align: justify; font-size: 14px;'"
                        >
                        <div v-html="sectwo.fields['ds2desc']"></div>
                        <router-link 
                        :to="{path: sectwo.fields['ds2link']}" 
                        @mouseover="getsecthreeId($event, $event.target.value=sectwo.did2)"
                        :id="`${sectwo.pk}`"
                        :style="colortheme === 'darkTheme' ? 'color: white; width: auto; padding-top: 10px; padding-bottom: 10px; margin-left: 60px; margin-right: 60px;' : 'color: whitesmoke; width: auto; padding-top: 10px; padding-bottom: 10px;  margin-left: 60px; margin-right: 60px;'"
                        class="btn btn-primary"
                        >
                          <span data-bs-dismiss="mega-box">{{ sectwo.fields['dsshortdesc']}}</span>
                        </router-link>
                      </span>
                      <span v-else>
                        <router-link 
                        :to="{path: sectwo.fields['ds2link']}" 
                        @mouseover="getsecthreeId($event, $event.target.value=sectwo.did2)"
                        @click="closeMegaBox()"
                        :id="`${sectwo.pk}`"
                        :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'"
                        >
                          <span class="formatlinks" data-bs-dismiss="mega-box">{{ sectwo.fields['dsshortdesc']}}</span>
                        </router-link>
                      </span>
                    </li>
                  </ul>
                </div>
                <!-- :class="[sectwo.did2===gml3 ? 'menulevel3' : ''] -->
                <div class="col formatrow4" style="min-width: 33%;" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
                  <ul class="mega-links">
                    <li v-for="(md, index2) in menualldesc" :key="index2.mfid_id">
                      <div  v-if="index2 === index">
                        <div v-html="md.lscaption" ></div>
                        <div><img :src="`${md.lsimage}`" ></div>
                        <div v-html="md.lsecdesc" style="font-size: 14px; line-height: 1.5rem; padding: 10px; padding-top: 20px;" ></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<!-- Offcavas starts here-->
<div v-if="showoffc === true"  class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel"
      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
  >
  <div class="offcanvas-header"  style="padding-top: 50px;" v-if="showoffc === true" >
   <router-link :to="{path: '/'}" class="navbar-brand" style="margin: 0 auto;">
     <!-- <img loading="lazy" class="imglogo" src="@/assets/nblogo.png" /> -->
     <img loading="lazy" class="imglogo" src="@/assets/nbblacklogo.png" v-if="colortheme === 'darkTheme'" style="margin: 0 auto; padding-top: 30px;"  />
     <img loading="lazy" class="imglogo" src="@/assets/nblogo.png" style="margin: 0 auto; padding-top: 30px;" v-else />
    </router-link>
   
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body" v-if="showoffc === true" 
      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
    >
    <!--  Accordion top menu--> 
    <div class="accordion accordion-flush outeraccordion" id="accordionOffCanvas"
       :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
      >
       <!-- Top Leve menu-->
      <div class="accordion-item" v-for="(menu, index) in mainmenu" :key="menu.mid"
          :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
       >
        <h2 class="accordion-header" :id="`flush-heading${menu.mid}`">
          <button class="accordion-button collapsed forminnerbody" type="button" data-bs-toggle="collapse" :data-bs-target="`#flush-collapse${menu.mid}`" aria-expanded="false" :aria-controls="`flush-collapse${menu.mid}`"
            :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: #252525; color: white;'"
          >
            {{ menu.mdesc }}
          </button> 
        </h2>
        <div :id="`flush-collapse${menu.mid}`" class="accordion-collapse collapse" :aria-labelledby="`flush-heading${menu.mid}`" data-bs-parent="#accordionOffCanvas"
            :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
         >
          <div class="accordion-body forminnerbody"
              :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
           >
            <!-- top body text and inner head text-->
            <div v-for="(md, index2) in menualldesc" :key="md.mfid_id"
                :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
             >
              <div v-if="index2 === index"
                  :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
               >
                <!-- <div  class="forminnerbody topcaption forminnerbody">{{ md.msdesc }}</div> -->
                <div v-html="md.fsecdesc" class="forminnerbody extendedformatting"></div>
                 <!-- second level menu -->
                <div v-for="sec1 in divseconeinfo"
                  :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
                 >
                  <div v-if="sec1.mfid === md.mids"
                      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
                  >
                    <div v-if="sec1.hasdropdown === false" class="nodropdown">
                      <router-link :to="{path: sec1.ds1link}" 
                      style="text-decoration: none; color: white; font-size: 14px;"
                      :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'"
                      @click="hideOffcava()"
                      >{{ sec1.ds1desc}}
                    </router-link>
                    </div>

                    <button class="accordioninner" 
                    @click.prevent="openAccordion()"  
                    @mousedown="getAccordion()"
                    v-else>{{sec1.ds1desc}}</button>
                      <div class="panel" 
                          :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
                        >
                         <!-- Final menu links-->
                        <div v-for="dsv2 in divsectwoinfo"
                            :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
                        >
                          <div v-if="dsv2.ds1fid === sec1.did"
                              :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
                          >
                            <span v-if="dsv2.dsshowdesc"
                                :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
                            >
                              <div v-html="dsv2.ds2desc" class="forminnerbody"></div>
                            </span>
                            <div v-else class="formatlinks">
                              <router-link :to="{path: dsv2.ds2link}" class="linksfmt" @click="hideOffcava()">{{ dsv2.dsshortdesc}}</router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
                      
              <!-- bottom body text-->
              <div  v-if="index2 === index" class="forminnerbody">
                <div v-html="md.lscaption" class="forminnerbody"></div>
                <div><img :src="`${md.lsimage}`" style="width: 100%;"></div>
                <div v-html="md.lsecdesc" class="forminnerbody extendedformatting"></div>
              </div>
            </div>

          </div>
          </div>
        </div>
      </div>
    </div>
</div>

</template>


<script>
import { mapGetters } from 'vuex';

import axios from 'axios';
import SearchAutocomplete from '@/components/SearchAutocomplete.vue'
import {  useRouter } from "vue-router";
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { useToast } from "vue-toastification";
import '../globalDeclare';
//  import { mapState } from 'vuex';

export default {

  props: ['breadcrumbdata', 'breadcrumbtheme'],
  components: {
    SearchAutocomplete
  },
  // props: ['user'],
  data () {
    const lang = localStorage.getItem('lang')  || 'en';
    return {
      lang: lang,
      mainmenu: [],
      menualldesc: [],
      divsecone: [],
      divsectwo: [],
      getmenu: '',
      getmenutwo: '',
      id: 0,
      items: [],
      getlang: localStorage.getItem("lang"),
      gml1: parseInt(localStorage.getItem("al1active")),
      gml2: parseInt(localStorage.getItem("al2active")),
      colortheme: localStorage.getItem('colorTheme'),
      myImgUrl: myUrlPath,
      divseconeinfo: [],
      divsectwoinfo: [],
   
      showmegbox: false,
      showoffc: false,
      isChecked: false, // Default state
      tooltipmsg: '',
      // computed: mapState(['accessToken'])
      switchdark: '',
      switchlight: '',
      tooltip: 'tooltip'
    }
  },
  beforeCreate() {

},
  created() {
      window.addEventListener('popstate', this.popstateEventAction );
    },
    computed: {
      ...mapGetters(['user'])
    },
  methods: {
    showOffcava() {
      this.showoffc = true;
      
    },  


  // clickApiOn() {
  //   document.getElementById('checkifcheck').switchButton('on');
  // },
  // clickApiOff() {
  //   document.getElementById('checkifcheck').switchButton('off');
  // },



    hideOffcava(){
      this.showoffc = false;

    },

    openAccordion(){
        //---------Accordion-----------------------------
        let acc = document.getElementsByClassName('accordioninner');
        let i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          this.classList.toggle("active");
          let panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
          } 
        });
      }
    },

   getAccordion(){
    this.openAccordion()
   },

    popstateEventAction() {
        // ... some action triggered when the back button is clicked
        this.removePopstateEventAction();
      },
      removePopstateEventAction() {
        window.removeEventListener('popstate', this.popstateEventAction);
      },

    selectItem($event){
    let id = parseInt($event.target.value)
      try {
        var ael1 = document.getElementById(id);
        let wkid = $event.target.value
        localStorage.setItem('al1active', $event.target.value)
        let getmenuid = localStorage.getItem('al1active')
        if (getmenuid === wkid) {
          ael1.classList.add("active")
        }
        
      }catch(error) {
        console.log(error)
      }
    },
    onChange($event) {
      let getVal = $event.target.value;
      axios.get(`getsearchdata/${getVal}`).then(
      response => {
        // this.filteredList = response.data
        this.items = response.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

  changeLangEn($event){
    let glangen = $event.target.value
    localStorage.setItem('lang', glangen)
    window.location.reload();
  },
  changeLangFr($event){
     let glangfr = $event.target.value
      localStorage.setItem('lang', glangfr)
      window.location.reload();
  },

    getId($event) {
      let id = parseInt($event.target.id);
      var ael = document.getElementById(id);
      localStorage.setItem('al1active', parseInt($event.target.value))
      this.showmegbox = true
      let x = document.getElementsByClassName("menulevel1");
      if(x.length > 0) 
        { 
          x[0].classList.remove("menulevel1"); 
        }
        ael.classList.add("menulevel1")
   

      this.divsectwo = [];
      axios.get(`getdivsecone/${id}`).then(
      response => {
        this.divsecone = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    getsectwoId($event) {
      let id = parseInt($event.target.id);
      axios.get(`getdivsectwo/${id}`).then(
      response => {
        this.divsectwo = response.data.data
        this.$router.push(`/${divsectwo}`)
        // console.log(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
      // console.log(id);
    },

    getsecthreeId($event){
      let id3 = $event.target.id
    },
    closeMegaBox() {
      this.showmegbox = false
    },

    noDispalay($event){
      $event.preventDefault()
    },

    async goToOpenMenu($event) {
      try{
        id = ($event.target.value);
      await axios.get(`getds1link/${id}`).then(
      response => {
        var getmenu = response.data['ds1link']
        this.$router.push({path: `${getmenu}`})
        // console.log(response.data.data)
      })
      }catch(error) {
        console.log(error)
      }
    },
    async goToOpenMenuTwo($event){
      try{
        id = ($event.target.value);
      await axios.get(`getds2link/${id}`).then(
      response => {
        var getmenutwo = response.data['ds2link']
        this.$router.push(`${getmenutwo}`)
        this.$router.push({path:`${getmenutwo}`})
              // console.log(response.data.data)
      })
      }catch(error) {
        console.log(error)
      }
    },

    getStatus() {
      let statusidchk = document.getElementById('themechecked');
      if (statusidchk.checked=true && this.nbTheme === 'darkTheme') {
          localStorage.removeItem('nbtheme')
          localStorage.removeItem('nbtheme1')
          localStorage.setItem('nbtheme', 'lightTheme');
          localStorage.setItem('nbtheme1', 'lightgreyTheme');
         
      }else{
        localStorage.removeItem('nbtheme')
        localStorage.removeItem('nbtheme1')
        localStorage.setItem('nbtheme', 'darkTheme');
        localStorage.setItem('nbtheme1', 'darkgreytheme');
      
      }
      this.$router.go(0)
    },

    toggleSwitch(event) {


      this.isChecked = event.target.checked;
      localStorage.setItem("switchState", this.isChecked); // Save state to localStorage

      if(this.isChecked === false) {
        localStorage.setItem('colorTheme', 'lightTheme');
    
        this.$router.go(0)
      }else{
        localStorage.setItem('colorTheme', 'darkTheme');
     
        this.$router.go(0)
      }
     
    },
    showToolTip() {
      if(this.isChecked === false) {
        this.tooltipmsg = 'Switch to dark theme';
      }else{
        this.tooltipmsg = 'Switch to light theme';
      }
    },
 

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll animation
      });
    },
  },

mounted() {
 
 
  let mycid = localStorage.getItem('al1active')
  let mylang = localStorage.getItem('lang')

  try {
    if(mycid != "") {
    let x1 = document.getElementsByClassName(mycid);
    x1.classList.add("menulevel1")
  }
  }catch(error){
    console.log(error)
  }
  axios.get('menualldesc/').then(
    response => {
      this.menualldesc = response.data
    })
    .catch((error) => {
      console.log(error)
    })

  axios.get('mainmenu/').then(
    response => {
      this.mainmenu = response.data
    })
    .catch((error) => {
      console.log(error)
    })

    axios.get('divsecone/').then(
    response => {
      this.divseconeinfo = response.data
    })
    .catch((error) => {
      console.log(error)
    })

    axios.get('divsectwo/').then(
    response => {
      this.divsectwoinfo = response.data
    })
    .catch((error) => {
      console.log(error)
    })
  
    // Check localStorage for saved state on load
  const savedState = localStorage.getItem("switchState");
  this.isChecked = savedState === "true"; // Set to true if saved state is "true"

  if(this.getlang === 'en') {
      this.switchdark = "Light theme"
      this.switchlight = "Dark theme"
    
    }
  if (this.getlang === 'fr') {
      this.switchdark = "Thème lumière"
      this.switchlight = "Thème sombre"
   
    }
 
  }

}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;   
  font-family: 'Poppins', sans-serif;
}

.dropdown .dropdown-menu {
  display: none;
  background: white;
  padding-left: 10px;
  width: 60px;
}
.dropdown:hover>.dropdown-menu, 
.dropend:hover>.dropdown-menu {
  display: block;
  margin-top: 0;
  margin-right: 0;
}
.setbgdark {
  background-color: black;
  color: white;
}


.topnavbar {

  text-align: right; 
  color: white;
  max-width: 100%;
  padding-right: 80px;
  height: 40px;
}
.ultopnav li {
  list-style-type: none;
  margin-left: 15px;
  font-size: 15px;
 }
 
nav{
  position: fixed;
  z-index: 99;
  width: 100%;
}
.brandlogo {
  max-width: 200px;
}
.formatlinks:hover {
  color: orangered;
}
/*Top navigation wrapper*/
nav .wrapper{
  position: relative;
  width: 100%;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a{
  color: #f2f2f2;
  font-size: 14px;
  font-weight: 100px;
  text-decoration: none;
}
.wrapper .nav-links{
  display: inline-flex;
}

.nav-links li{
  list-style: none;
}
.nav-links li a{
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  padding: 9px 15px;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  color: orangered;
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px black;
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  padding-left: 50px;
  padding-right: 50px;
}

.mega-box .content{
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px #003;
}
.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header{
  color: black;
  font-size: 20px;
  font-weight: 500;
}
.content {
  padding: 40px;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
  padding: 0 20px;
}
.contentformat img {
  width: 100%;
  height: 100%;
}
.contentformat {
  background-color: grey;
  color: white;
  height: 400px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: black;
  font-size: 14px;
  display: block;
}
.row .mega-links li a:hover{
  color: orangered;
}
.wrapper .btn{
  color: black;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}
.iconimage {
  width: 30px;
}
.iconimage:hover {
  width: 35px;
  background-color: orange;
  cursor: pointer;
  transition: ease-out;
  transition-duration: 2s;
}
.iconimage {
  visibility: hidden;
}
.offcanvas {
  padding: 20px;
  background-color: black;
}
.btn-close{
  background-color: white;
  font-size: 20px;
}
.fa-magnifying-glass {
  width: 10px;
}
.formatrow1, .formatrow4 {
  max-width: 30%;
  overflow-wrap: break-word;
  padding: 10px;
}
.formatrow2, .formatrow3 {
  width: 20%;
}
.formatrow1 {
  padding-right: 20px;
}
.topcaption {
  width: 100%;
}
.fonticonformat {
  width: 60px;
  margin-left: 10px;
  color: white;
}
/*------------------ Accordion----------------------*/
.accordioninner {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-bottom: solid thin white;
}



.accordioninner:after {
  content: '\002B';

  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.outeraccordion {
  margin-top: 30px;
  margin-bottom: 30px;
}
.offcanvas-body {
  margin-bottom: 20px;
}
.accordion-button {
  height: 50px;
  padding: 10px;

}

.forminnerbody {
  padding: 10px;
}
.extendedformatting{
  padding: 20px;
  font-size: 14px;
}
.formatlinks {
  padding: 20px;
  text-decoration: none;
}
.formatlinks .linksfmt {
font-size: 14px;
}
.formatfa {
  font-size: 20px; 

}
.formatfa:hover {
  transition-duration: 0.5s;
  transform: scale(1.5);
  transition: transform .2s;
  cursor: pointer
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed)::after {
  filter: invert(50%);
}

nav input{
  display: none;
}
.imglogo {
  width: 200px;
  margin-left: 120px;
}
.formatfside {
  font-size: 12px;
  line-height: 1.3rem;

}

.setEnLang {
  color: red;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  list-style-type: none;
}
.setEnLang:hover {
  color: red;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  list-style-type: none;
}

.setFrLang {
  color: red;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  list-style-type: none;
}
.setFrLang:hover {
  color: red;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  list-style-type: none;
}

.noColoren {
  color: rgb(4, 1, 17);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  list-style-type: none;
}
  .noColorfr {
  color: rgb(4, 1, 17);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  list-style-type: none;
}
.setwidthdp{
  min-width: inherit;
  max-width: 80px;
  padding-right: 10px;
  background-color: whitesmoke;
}
.langsh {
  color: red;
}
.menulevel1 {
  border-bottom: solid orangered thin;

}
.menulevel3 {
  position: relative;
}
.nodropdown {
  padding: 20px;
  color: white;
  border-bottom: solid thin white;
}
.offcanvasvisibility {
  color: white;

}
.wrappersec1 {
  max-width: 30%;
  float: left;
}
.wrappersec2 {
  max-width: 70%;
  float: left;
}

.nbsearch {
  width: 20px; 
  height: 20px; 
  margin-right: 20px; 
  padding-top: 14px;
  cursor: pointer;
}
.nbsearch:hover {
  width: 25px;
  height: 25px;
}
.nbmobilemenu {
  float: right; 
  margin-top: 15px; 
  padding-right: 100px; 
  font-size: 20px; 
  color: white; 
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer; 
}

.nbmobilemenu:hover {
  font-size: 25px;
}
.topmenubarcss {
  height: 50px; background-color: #003; position: fixed;
      top: 0; width: 100%; z-index: 999999999;
}
.setdarkbg1 {
  height: 50px; background-color: rgb(29, 28, 28); position: fixed;
  top: 0; width: 100%; z-index: 999999999;
  .nav-links li a{
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }
}
.setnavbgcolor {
  background-color: #d0d2d4; 
  padding-top: 20px; 
  height: 130px; 
  border-bottom: thick solid #003;
  color: #003;
}
.setnavbgcolor1 {
  background-color: #d0d2d4; 
  color: black;
  font-family: 'Times New Roman', Times, serif;

}
.setdarkbgnav {
  background-color: black; padding-top: 20px; height: 130px; 
  .nav-links li a{
    color: white;
  }
}
.setdarkbgnav {
  background-color: black;
  .nav-links li a{
    color: white;
  }
}
.setnavbgcolor2 {
  background-color: #d0d2d4; 
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.setdarkbgnav3 {
  background-color: black;  margin-top: -24px; margin-left: 40px; margin-right: 30px;
  .row .mega-links li a{
    color: white;
  }
  .nav-links li a{
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }
}
.wrappersec2 {
  display: none;
}

/*-------------BUTTON SWITCH*/
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-top: 15px;
  margin-right: 25px;
  margin-left: -10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #464242;
}

input:focus + .slider {
  box-shadow: 0 0 1px #464242;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* Megabox delay transition very important to reduce flashiness*/
.mega-box {
  transition-delay: 0.2s; /* Add a slight delay to dropdown display */
}
img {
  object-fit: cover;
}
/*------------------ Accordion end----------------------*/
@media screen and (max-width: 600px) {
  .wrappersec2 {
    visibility: hidden;
  }
  .brandlogo {
    margin-left: 140px;
    height: 6vh;
  }
}
@media screen and (max-width: 600px) {
  .wrappersec2 {
    visibility: hidden;
  }
}

@media screen and (min-width: 768px) {
  .wrappersec2 {
    visibility: hidden;
  }
  }

  @media screen and (max-width: 768px) {
    .wrappersec2 {
      visibility: hidden;
    }
    }


@media screen and (min-width: 970px) {
  .wrappersec2 {
    visibility: visible;
  }
}
@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    height: 100vh;
    width: 100%;
    max-width: 350px;
    left: -100%;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #003;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }

  .menu-btn{
    padding-right: 20px;
    
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  

  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: black;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #3A3B3C;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
  .wrapper{
    visibility: visible;
  }

  .mega-box {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 1200px) {
.wrappersec2 {
  display: block;
}
.mega-box {
  width: 100%;
}
.nbmobilemenu {
  visibility: hidden;
}
.nbsearch {
  margin-right: -16px;
}
.brandlogo {
  margin-top: -5px;
}

}

@media screen and (min-width: 1600px) {
  .wrappersec2 {
    display: block;
  }
  .mega-box {
    width: 100%;
  }
  .nbmobilemenu {
    visibility: hidden;
  }
  .nbsearch {
    margin-right: -16px;
  }
  .brandlogo {
    margin-top: -5px;
  }
  .topbarformat {
    margin-right: 200px
  }
  }
</style>
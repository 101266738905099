<template>
  <div class="tableformatdiv">
    <table class="table table-hover table-striped" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
      <thead class="trcss" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
        <tr @click.stop :style="colortheme === 'darkTheme' ? darkstyle :  ''">
          <th @click.stopscope="col" v-for="tdata in getTableCaptions" :width="`${tdata.tbcapwidth}`" class="trcss" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
            <span>{{ tdata.tbcapdesc}}</span>
        </th>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="gjob in getjobs" @click.stop >
         <td class="tdlinkcss classgetid">{{ gjob.jobposition}}</td>
          <td class="tdlinkcss" >{{ gjob.jobdepartment}}</td>
          <td class="tdlinkcss">
            <div  v-for="jrg in getregions">
              <span  v-if="jrg.jobrgid===gjob.jobregion">
                {{ jrg.country}},{{ jrg.regprov }},{{jrg.city}}
              </span>
            </div>
          </td>
          <td class="tdlinkcss">{{ dateTime(gjob.jvaliduntil)}}</td>
          <td class="tdlinkcss"> 
            <a :href="`${myPicUrl}${gjob.jobpdf}`">
              <i class="fa-regular fa-circle-down"></i>
            </a>
          </td>
          <td class="tdlinkcss" style="cursor: pointer;">
                <font-awesome-icon icon="fa-solid fa-circle-chevron-left" style="font-size: 26px;" 
                data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click.prevent="getJobId($event=`${gjob.jobid}`, $event1=`${gjob.jobrefnumber}`)" />Apply
          </td>
        </tr>
      </tbody>
    </table>


  
  </div>

    <!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade modaldtformat" 
  id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" 
  aria-labelledby="staticBackdropLabel" aria-hidden="true" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
  <div class="modal-dialog modal-lg" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
    <div class="modal-content" :style="colortheme === 'darkTheme' ? darkstyle :  ''">
      <div class="modal-header" :style="colortheme === 'darkTheme' ? darkstyle :  'background-color: #003; color: white;'">
        <h5 class="modal-title" id="staticBackdropLabel">CANDIDATE'S INFORMATION - {{ pjnumber }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" :style="colortheme === 'darkTheme' ? `${darkstyle} background-color: white;` :  'background-color: white;'"></button>
      </div>
      <div class="modal-body">
        <input type="hidden" :value="`${pjobid}`" />
        <div class="formcssformat col-md-12" >
          <form @submit.prevent style="background-color: #F8F8F8; color: black; padding: 30px; border-radius: 5px; opacity: 0.9;">
            <div class="mb-3">
              <label class="lblformat">FIRST NAME:<span class="starred">*</span></label>
              <input type="text" name="firstName" v-model="firstName" class="form-control form-control-sm formattxt"  id="exampleInputPassword1">
            </div>
            <div class="mb-3">
              <label class="lblformat">LAST NAME:<span class="starred">*</span></label>
              <input type="text" name="lastName" v-model="lastName" class="form-control form-control-sm formattxt"  id="exampleInputPassword1">
            </div>
            <div class="mb-3">
              <label class="lblformat">E-MAIL:<span class="starred">*</span></label>
              <input type="email" name="email" v-model="email" class="form-control formattxt" id="exampleFormControlInput1" placeholder="name@example.com">
            </div>
            <div class="mb-3">
              <label class="lblformat">SELECT A REGION: <span class="starred">*</span> </label>
              <select @change="handleChange" v-model="selected" name="select" class="form-select form-select-sm formattxt" aria-label=".form-select-sm example">
                <option value="" disabled>Open this select menu</option>
                <option v-for="erg in getregions" v-bind:value="{ id: erg.jobrgid, text: erg.country }">{{erg.country}}</option>

              </select>
              
            </div>
            <div class="mb-3">
              <!-- <label for="formFileSm" class="form-label">Small file input example</label>
              <input class="form-control form-control-sm" id="formFileSm" type="file"> -->
              
              
            </div>

 
            <div class="progress" style="margin-top: 40px;" >
              <progress max="100" :value.prop="uploadPercentage" class="progresscss"></progress>
              </div>
              <div class="mb-3">
                  <label for="formFileSm" class="form-label lblformat">SELECT A FILE: <span class="starred">*</span> </label>
                  <input class="form-control form-control-sm file formattxt" ref="fileInput" id="formFileSm" type="file" multiple @change="onFileSelect">
              </div>
           

            <div class="mb-3 form-check" style="margin-top: 10px;">
              <div class="lblformat" style="margin-top: 50px;" >
                <span class="lblformat"> <span class="starred">*</span>Check to accept our recruitment policy</span>
                <input type="checkbox"  v-model="eulachecked" name="EULA" class="form-check-input " id="exampleCheck1">
              </div>
            </div>
            
            
          </form>
        </div>
      </div>
      <div class="modal-footer" :style="colortheme === 'darkTheme' ? darkstyle :  'background-color: #003;'">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit"  class="btn btn-primary" @click.prevent="UploadAllFiles()">Submit</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
require('@/globalDeclare.js');
import { useToast } from "vue-toastification";


export default{
  props: ['getTableCaptions', 'getjobs', 'getregions', 'colortheme', 'darkstyle', 'myjobid', 'pdflink'],
  components: { },
  data() {
    return {
        myPicUrl: '',
        colortheme: localStorage.getItem('colorTheme'),

        images: [],
        isDragging: false,
        filenames: [],
        firstName: '',
        lastName: '',
        email: '',
        selected: '',
        jobpdf: '',
        jobrefid: this.$route.params.jobid,
        getregions: [],
        selected: '',
        selectedid: 0,
        file: '',
        getjobnumber: '',
        uploadPercentage: 0,
        eulachecked: false,
        getEmail: '',
        getRefId: '',
        getFirstName: '',
        content: "<h1>Some initial content</h1>",
        returnmsg: 'employeemsg',

        pjobid: '',
        pjnumber: ''
    
    }
  },
methods: {
async getJobId($event, $event1){
  let mvs=this
  let getgjid = $event
  let getpjnumber = $event1
  mvs.pjobid = getgjid
  mvs.pjnumber = getpjnumber
},

async getLinkedID($event) {
      this.$emit('getLinkedID', $event.target.value)
  },
removeClicked() {
$('table thead th').off('click');
},
dateTime(value) {
    return moment(value).format('YYYY-MMMM-DD');
  },

  //form methods

  selectFiles() {
                    this.$refs.fileInput.click();
                },
         handleChange(){
            let selectedid = this.selected.id
            this.selectedid = selectedid
        },
      
                onFileSelect(event) {
                    event.preventDefault
                    this.file =  this.$refs.fileInput.files[0];
                    if(this.file.length === 0) return;
                },
           
        
      async UploadAllFiles() {
        await axios.get(`getallSingleCareer/${this.pjobid}`).then(
            response => {
                this.getEmail = response.data[0].jobapemail
                this.getRefId = response.data[0].jobrefid
              })
              .catch((error) => {
                  console.log(error)
            })  
        if(this.firstName === '') {
          let e = "First name is required."
          const toast = useToast();
            toast.error(e, {
            // Optional configuration per toast
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: "✅",  // Custom icon
        }); 
          return;
        } 
        else if(this.lastName === '') {
          let e = "Last name is required."
          const toast = useToast();
          toast.error(e, {
          // Optional configuration per toast
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: "✅",  // Custom icon
        }); 
          return;
        }
        else if(this.email === '') {
          let e = "Email is required."
          const toast = useToast();
          toast.error(e, {
          // Optional configuration per toast
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: "✅",  // Custom icon
        }); 
          return;
        }
        else if( this.selected === '') {
          let e = "Job region is required."
          const toast = useToast();
            toast.error(e, {
            // Optional configuration per toast
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: "✅",  // Custom icon
        }); 
          return;
        }
     
        else if( this.file === '') {
          let e = "Please upload a resume"
          const toast = useToast();
            toast.error(e, {
            // Optional configuration per toast
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: "✅",  // Custom icon
          }); 
          return;
        }
        else if( this.eulachecked === false) {
          let e = "Please acknowledge and accept recruitment clauses."
          const toast = useToast();
          toast.error(e, {
          // Optional configuration per toast
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: "✅",  // Custom icon
        }); 
          return;
        }
        else if(this.getEmail === this.email && this.getEmail === this.email) {
            let e = "You already applied for this job. Dual submission is not acceptable."
            const toast = useToast();
            toast.error(e, {
            // Optional configuration per toast
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: "✅",  // Custom icon
          }); 
              return;
            }
        else {
           
        this.file = this.$refs.fileInput.files[0];
        let formData = new FormData()
        formData.append("jobapfirstname", this.firstName)
        formData.append("jobaplastname", this.lastName)
        formData.append("email", this.email)
        formData.append("jobpdf", this.file)
        formData.append("jobrefid", this.pjobid)
        formData.append("jobregion", this.selectedid)
        formData.append("jobrefnumber", this.pjnumber)
        
        //set the form headers
        let axiosConfig = {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
          }.bind(this)
            }
        //send the data to backend using the api link          
        axios.post('getallcareerapplication/', formData, axiosConfig).then(response => {
          console.log(response.data)
                    if (response.data.detail) {
                      // let e = "Record posted successfully."
                      const toast = useToast();
                      toast.success(response.data.detail, {
                      // Optional configuration per toast
                      position: "top-right",
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      icon: "✅",  // Custom icon
                      }); 
                    }
                    else if(response.data.conflict) {
                      const toast = useToast();
                      toast.error(response.data.conflict, {
                      // Optional configuration per toast
                      position: "top-right",
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      icon: "✅",  // Custom icon
                      }); 
                    }
                })
                .catch(e => {
                  const toast = useToast();
                  toast.error(e, {
                      // Optional configuration per toast
                      position: "top-right",
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      icon: "✅",  // Custom icon
                      }); 

            })
            formData = ''
            this.file = ''
            this.firstName = ''
            this.lastName = ''
            this.email = ''
            this.jobpdf = ''
            this.$refs.fileInput.value = null
            this.uploadPercentage = 0
            this.selected = ''
        }
        
      }
},
async mounted() {
    this.myPicUrl = myUrlPath

    await axios.get('getallcareerallregions/').then(
      response => {
          this.getregions = response.data
        })
    .catch((error) => {
        console.log(error)
        })  
     

}



}

</script>
<style scoped>
img {
  object-fit: cover;
}
.tablediv {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 100px;
  padding-bottom: 150px;
}
.fa-circle-down {
  font-size: 30px;
  color: orangered;
  cursor: pointer;
}
.classgetid {
  cursor: pointer;
}
.trcss {
color: rgb(138, 56, 27);
}
.modaldtformat {
  z-index: 9999999;
}

.progresscss {
  width: 100%;
}
.formatcaninfo {
  padding: 20px;
  font-weight: bold;
  font-size: 40;
  color: orangered;
  text-align: center;
}

.lblformat {
  margin-top: 16px;
  width: 100%;
  color: #003;
  padding: 10px;
  font-weight: bold;
}
.form-check-input {
  width: 30px;
  height: 30px;
}
.starred {
  color: orangered;
  font-size: 20px;
}
.formattxt {
  background-color: rgb(245, 245, 240);
}
 /*===================MEDIA SCREENS TAMPLATES===============*/
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .classfilter {
    margin-left: 20px;
    margin-right: 20px;
  }
.tableformatdiv {
  overflow-x: scroll
}

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .tableformatdiv {
    overflow-x: scroll
  }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    
 } 
</style>
<template>
      <div class="container">
        <div class="row align-items-start">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 formtcont" v-for="acsdata in allcardsdata">
            <div class="boxdataimg">
              <img :src="`${myPicUrl}${acsdata.cdsecimage}`" class="sectionimage">
            </div>

              <div  class="slide-info card shadow">
                <div  class="spantext">
                  <a :href="acsdata.cdseclink" class="gradient-text"><p v-html="acsdata.cdsecshortdesc"></p></a>
                  <p v-html="acsdata.cdsecdesc" class="desctext"></p>
                  <router-link :to="{path: `${acsdata.cdseclink}`}" class="linktext"><div class="linkspantext">{{acsdata.cdseclinktext}}<i class="fa-solid fa-square-arrow-up-right linkarrow"></i></div></router-link>
        
                </div>
              </div>
  
          </div>
        </div>
      </div>
  </template>
 
  <script>
  require('@/globalDeclare.js');
  
  export default{
   
    props: ['allcardsdata'],
  
    data(){
      return {
        myPicUrl: '',
      }
    },
    mounted() {
      this.myPicUrl = myUrlPath
    }
  }
  </script>
  
  <style scoped>
  .newslearnmore {
    float: right; 
    padding-right: 30px;
  }
  .sectioncontrol {
     height: 100%;
     margin-top: 50px;
     margin-bottom: 40px;
     padding-bottom: 80px;
     padding-top: 20px;
  }
  .sectionimage {
    height: 100%;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }
  .boxdata{
    position: relative;
    width: 350px;
    height: 320px;
    margin-top: -100px;
    margin-right: 50px;
    float: right;
    border: solid orange thick;
    z-index: 1;
  }
  .slide-info {
 
    position: relative;
    margin-top: -120px;
    margin-left: 250px;
    width: 350px;
    height: auto;
    border-radius: 5px;
  }
  .spantext {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    
  }
  .gradient-text {
  font-size: 30px;
  width: 100%;
  font-weight: bold;
  text-decoration: none;
  color: #003;
  }
  .desctext{
    font-size: 20px;
  }
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 80%;
  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: maroon;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .align-items-start {
    padding-top: 70px;
  }

 /*===================MEDIA SCREENS TAMPLATES===============*/
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 598px) {
 
  .card {
    width: 100%;
    margin-left: 0px;
  }
  .boxdata{
    position: relative;
    width: 400px;
    height: 320px;
    margin-top: -100px;
    margin-right: 50px;
    float: right;
    border: solid orange thick;
    z-index: 1;
  }
  .boxdataimg {
    width: 100%;
    height: 30vh;
  }
}

@media only screen and (min-width: 400px) {

  .card {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 60px;
  }
   .boxdata{
    position: relative;
    width: 400px;
    height: 320px;
    margin-top: -100px;
    margin-right: 50px;
    float: right;
    border: solid orange thick;
    z-index: 1;
  }
  .boxdataimg {
    width: 100%;
    height: 30vh;
  }
}

  /*===================MEDIA SCREENS TAMPLATES===============*/
 /* Extra small devices (phones, 600px and down) 
 @media only screen and (max-width: 600px) {
  .sectioncontrol {
    height: 100%;
    margin-top: -100px;
    margin-bottom: 40px;
  }
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 100%;
  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: maroon;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .slide-info {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 420px;
    height: auto;
    border-radius: 5px;
  }
  .col {
    margin-top: 100px;
  }
  .card {
    margin-top: -30px;
  }

  .formtcont {
    margin-bottom: 70px;
  }
  
 }*/

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .sectioncontrol {
    height: 100%;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 100%;
    margin: 0 auto;
  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: maroon;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .slide-info {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 550px;
    height:auto;
    border-radius: 5px;
  }
  .col {
    margin-top: 100px;
  }
  .card {
    width: 100%;
    margin-top: -30px;
  }

  .formtcont {
    margin-bottom: 70px;
  }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  .sectioncontrol {
    height: 100%;
    margin-top: -50px;
    margin-bottom: 40px;
   }  
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 90%;
  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: maroon;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .slide-info {
    position: relative;
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
    width: 260px;
    height:auto;
    border-radius: 5px;
  }
  .col {
    margin-top: 100px;
  }
  .sectionimage {
    width: 100%;
  }
  .formtcont {
    margin-bottom: 70px;
    width: 100%;
  }
  .card {
    width: 90%;;
  }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
 
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 90%;
  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: #003;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .slide-info {

    position: relative;
    margin-top: -30px;
    margin-left: 60px;
    width: 280px;
    height:auto;
    border-radius: 5px;
  }

  .formtcont {
    width: 50%;
  }
  .card {
    width: 90%;;
  }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .sectioncontrol {
    height: 100%;
    margin-top: -50px;
    margin-bottom: 40px;
   }  
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 80%;
  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: orange;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .slide-info {

    position: relative;
    margin-top: -120px;
    margin-left: 90px;
    width: 340px;
    height: auto;
    border-radius: 5px;
  }
  .formtcont {
    width: 50%;
  }
  .card {
    width: 90%;;
  }
 } 


 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1400px) {
  .sectioncontrol {
    height: 100%;
    margin-top: -50px;
    margin-bottom: 40px;
   }  
  .boxdataimg {
    position: relative;
    border: solid thick orange;
    width: 80%;

  }
  .linktext {
    text-decoration: none;
    font-weight: bold;
    float: right;
    color: maroon;
  }
  .linkarrow {
    padding-left: 10px;
    font-size: 26px;
    color: orange;
  }
  .linkspantext{
    padding-bottom: 20px;
  }
  .slide-info {

    position: relative;
    margin-top: -120px;
    margin-left: 90px;
    width: 340px;
    height: auto;
    border-radius: 5px;
  }
  .formtcont {
    width: 33%;
  }
  .card {
    width: 90%;
    margin-left: 60px;
  }
 } 
</style>
<template>

        <div class="container">
          <div class="titletext">
            <p v-html="secquestion"></p>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div v-html="seconetext" class="rightsidetext"></div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div v-html="sectwotext" class="rightsidetext"></div>
            </div>
          </div>
        </div>

</template>

<script>

export default {
  props: [ 'secquestion', 'seconetext', 'sectwotext',]
}
</script>

<style scoped>
.titletext {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 54px;
  font-weight: bold;
  color: grey
}
.rightsidetext {
  border-left: solid thick orangered;
  padding-left: 50px;
}
img {
  object-fit: cover;
}
/*==============MEDIA QUERIES=====================*/
@media only screen and (max-width: 600px) {
.rightsidetext {
  margin-top: 100px;
}
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .rightsidetext {
    margin-top: 100px;
  }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  
 }

 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {

}

  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {

}
</style>
<template>
     <!-- Terms of Use Acceptance Bar -->
      <div v-if="showTermsBar" class="terms-bar" :colortheme="colortheme" :darkstyle="darkstyle">
        <p>By using this site, you agree to our <a href="/terms">Terms of Use</a>.</p>
        <button @click="acceptTerms">Accept</button>
      </div>
  </template>

  <script>
export default {
  data() {
    return {
      showTermsBar: false,  // Controls whether the terms bar is displayed
    };
  },
  methods: {
    acceptTerms() {
      // Hide the terms bar when the user clicks accept
      this.showTermsBar = false;
      // Store the acceptance in localStorage
      localStorage.setItem('termsAccepted', 'true');
    }
  },
  created() {
    // Check if the user has already accepted the terms
    const termsAccepted = localStorage.getItem('termsAccepted');
    // Show the terms bar if they haven't accepted yet
    if (!termsAccepted) {
      this.showTermsBar = true;
    }
  }
};
</script>
<style>
/* Basic content styling */
.content {
    min-height: 100vh;
    padding: 20px;
  }
  
  /* Sticky bottom bar */
  .terms-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .terms-bar p {
    margin: 0;
    display: inline-block;
    padding-right: 10px;
  }
  
  .terms-bar button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .terms-bar a {
    color: #ffffff;
    text-decoration: underline;
  }
  img {
    object-fit: cover;
  }
  
</style>

<template>

  

    <div class="tablediv" style="padding-bottom: 50px;">
      <div v-html="careerpagetopinfo">
      
      </div>
      <DynamictableFormincluded :getTableCaptions="tabledata" :getjobs="careerpagejobs" 
        :getregions="careerregions" @click="getLinkedID($event)" :colortheme="colortheme" :darkstyle="darkstyle"  />

        <div v-html="careerpagebottominfo" style="padding-top: 50px;">
          
        </div>
    </div>


  </template>
  
  <script>
  import DynamictableFormincluded from './DynamictableFormincluded.vue';

  import axios from 'axios';
  import { useRoute, useRouter } from 'vue-router'
  require('@/globalDeclare');
  
  const router = useRouter()
  const route = useRoute()
  
  
  export default {
    props: ['colortheme', 'darkstyle', 'myjobid', 'pdflink', 'careerpagetopinfo', 'careerpagebottominfo'],
    name: 'JoinOurteam',
    components: {
      DynamictableFormincluded, 
  },
  data() {
    return {
    alljoinourteamdata: [],
    aboutgeneral: [],
  
    jotlefttext: '',
    joteampositiontext:  '',
    joteambottomtext:  '',
  
    tabledata: [],
    careerpagejobs: [],
    careerregions: [],
    getjobposting: [],
    
    histsearchid: 0,
    publicPath: window.location.origin,
    colortheme: localStorage.getItem('colorTheme'),
    }
  },
  methods: {

  getLinkedID($event) {
    let lkId = $event.target.value
    let locallang = localStorage.getItem('lang')
    let vm = this
    if(locallang === 'en'){
      vm.$router.push({name: 'jobposting', params: {jobid: lkId}})
    }
    if(locallang === 'fr'){
      vm.$router.push({name: 'offredeemploi', params: {jobid: lkId}})
    }
    
    //-----------All career page jobs-------------
    axios.get(`getuniquejob/${lkId}`).then(
          response => {
              this.getjobposting = response.data
              console.log(this.getjobposting)
        })
        .catch((error) => {
            console.log(error)
          }) 
  
        
  }
  },
  
mounted() {
      //-----------All invest page report data--------------
      axios.get('getjoinourteaminfo/').then(
      response => {
          this.alljoinourteamdata = response.data
          this.jotlefttext = response.data[0].jotlefttext
          this.joteampositiontext = response.data[0].joteampositiontext
          this.joteambottomtext = response.data[0].joteambottomtext

      })
    .catch((error) => {
        console.log(error)
      }) 

  
  //-----------All career table captions-------------
  axios.get('getallcareertablecaption/').then(
        response => {
            this.tabledata = response.data
      })
      .catch((error) => {
          console.log(error)
        })  
    
  //-----------All regions-------------
  axios.get('getallcareerallregions/').then(
        response => {
            this.careerregions = response.data
            console.log(response.data)
      })
      .catch((error) => {
          console.log(error)
        }) 
    
  //-----------All career page jobs-------------
  axios.get('getallcareerspagejobs/').then(
        response => {
            this.careerpagejobs = response.data
            console.log(response.data)
      })
      .catch((error) => {
          console.log(error)
        }) 
  
  
  }
  }
  </script>
  
  <style scoped>
  
   .tablediv {
    padding-left: 10px;
    padding-right: 30px;
  
}
  /*===================MEDIA SCREENS TAMPLATES===============*/
   /* Extra small devices (phones, 600px and down) */
   @media only screen and (max-width: 600px) {

    .tablediv {
      padding-left: 10px;
      width: 100%;
    }


   }
  
   /* Small devices (portrait tablets and large phones, 600px and up) */
   @media only screen and (min-width: 600px) {

   }
   
   /* Medium devices (landscape tablets, 768px and up) */
   @media only screen and (min-width: 768px) {
 
   }
  
   /* Large devices (laptops/desktops, 992px and up) */
   @media only screen and (min-width: 992px) {
 
   }
   
   /* Extra large devices (large laptops and desktops, 1200px and up) */
   @media only screen and (min-width: 1200px) {
 
   } 
  
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1400px) {

    } 
  
  </style>
  
<template>
  <!--     Loop through displayedPosts to display each post     -->
  
    <div class="container">
      <div  :key="index" :class="slideshowlk" class="slide-info"  data-aos="fade-up" data-aos-duration="2500" >
        <div class="row carouselrow">
          <div class="col-sm-12 col-md-7 collarge">
            <!-- <div v-html="photo.slidedesc"  class="courselphotodesc">
            </div> -->
            <div v-html="slidetext" id="vHTMLDiv" class="slidetext" data-aos="fade-up">
            </div>
                 
            <span align="right"><router-link :to="{path: slidelmorelink}" class="newslearnmore">{{ slidelmoretext}}<i class="fa-brands fa-readme"></i></router-link></span>
          </div>
          <div class="col-sm-12 col-md-5 colsmall carouselcol5">
            <img class="imgsize pulse seccarouselimage" :src="`${myPicUrl}${slideimage}`" alt="">
            <div class="carousellinktextdiv card show">
              <div v-html="slideimagetext" class="carousellinktext"></div>
            </div>
        </div>
      </div>
    </div>  
    </div>
  
  </template>
  
  <script>
  import axios from 'axios';
  require('@/globalDeclare.js');
  
  export default {
    props: ['slidetext', 'slidelmorelink', 'slidelmoretext', 'slideimage', 'slideimagetext'],
      data() {
          return {
            myPicUrl: '',
          }
      },
      mounted(){
        this.myPicUrl = myUrlPath
      },
   
    
  }
  </script>
  
  <style scoped>
  .seccarouselshow {
    padding: 30px;
  }
  .sectioncarouselslide {
    background-color: rgb(230, 234, 238);
  }
  .btn-lg, .fa-square-caret-left, .fa-square-caret-right {
    font-size: 68px;
    margin-top: -100px;
  }
  .newslearnmore{
    font-size: 20px;
    padding-right: 30px;
    color: orangered;
    text-decoration: none;
    padding-top: 30px;
  }
  .fa-readme {
    padding-left: 10px;
    color: orangered;
  }
  .slide-info {
    padding-top: 100px;
  }
  img {
    object-fit: cover;
  }
  
    /*===================MEDIA SCREENS TAMPLATES===============*/
   /* Extra small devices (phones, 600px and down) */
   @media only screen and (max-width: 600px) {
    .imgsize {
      width: 100%;
    }
    .newslearnmore {
      float: right;
    }
    .carousellinktextdiv {
      text-align: center;
      margin-bottom: 100px;
    }

   }
  
   /* Small devices (portrait tablets and large phones, 600px and up) */
   @media only screen and (min-width: 600px) {
    .imgsize {
      width: 100%;
      padding-top: 30px;
    }
    .newslearnmore {
      float: right;
    }
    .carousellinktextdiv {
      text-align: center;
    }
 
   }
   
   /* Medium devices (landscape tablets, 768px and up) */
   @media only screen and (min-width: 768px) {
    .imgsize {
      width: 100%;
      padding-top: 30px;
    }
    .collarge {
      width: 100%;
    }
    .colsmall {
      width: 100%;
      padding-top: 90px;
    }
    .col-md-5 {
      margin-top: -80px;
    }
    .carousellinktextdiv {
      width: 60%;
      margin-top: -26px;
      margin-left: -9px;
      padding: 10px;
    }
   }
   
   /* Large devices (laptops/desktops, 992px and up) */
   @media only screen and (min-width: 992px) {
    .carousellinktextdiv {
      width: 80%;
      margin-top: -26px;
      padding: 10px;
      text-align: left;
    }
    .collarge {
      width: 70%;
    }
    .colsmall {
      width: 30%;
      padding-top: 90px;
      margin-top: -170px;
    }
   }
   
   /* Extra large devices (large laptops and desktops, 1200px and up) */
   @media only screen and (min-width: 1200px) {
    .seccarouselimage{
      width: 80%;
    }
    .collarge{
      width: 60%;
      padding-top: 50px;
      padding-right: 40px;
      margin-top: -200px;
    }
    .colsmall{
      width: 40%;
      margin-top: -300px;
    }
    .imgsize {
      height: 40vh;
      width: 500px;
    }
    .seccarouselshow {
      padding-left: 100px;
      padding-right: 100px;
    }
   } 
   /* Extra large devices (large laptops and desktops, 1200px and up) */
   @media only screen and (min-width: 1400px) {
    
    
   } 
  </style>
<template>
  <div class="mega-menu" style="display: flex;  align-items: flex-start;">
    <ul style="display: inline-flex;">
      <li
        v-for="x in 4"
        :key="x"
        class="menu-item"
        @mouseover="showDropdown(x)"
        @mouseleave="hideDropdown(x)"
      >
        Menu {{ x }}
        <div v-if="isDropdownVisible[x]" class="dropdown" style="width: 500px; margin-left: 100px;">
          <!-- Dropdown content with pointer arrow -->
          <div class="dropdown-arrow"></div>
          <div class="dropdown-content" style="">
            <a href="#" @click.prevent="linkClicked('Link 1')">Link 1</a>
            <a href="#" @click.prevent="linkClicked('Link 2')">Link 2</a>
            <a href="#" @click.prevent="linkClicked('Link 3')">Link 3</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDropdownVisible: {}, // Track dropdown visibility for each item
    };
  },
  methods: {
    showDropdown(index) {
      this.isDropdownVisible[index] = true;
    },
    hideDropdown(index) {
      this.isDropdownVisible[index] = false;
    },
    linkClicked(link) {
      console.log(`${link} clicked!`);
      // Hide all dropdowns when a link is clicked
      Object.keys(this.isDropdownVisible).forEach((key) => {
        this.isDropdownVisible[key] = false;
      });
    },
  },
};
</script>

<style scoped>
.mega-menu {
  position: relative;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
}

.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white; /* Arrow color */
  position: absolute;
  top: -10px; /* Adjust this to position the arrow above the dropdown */
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content {
  padding: 10px;
}

.dropdown-content a {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
img {
  object-fit: cover;
}
</style>

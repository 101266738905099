<template>
    <div v-if="OpenClose" class="modal fade show generalModal" 
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog"
        style="display: block"
        >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header headerfooterformat">
            <h5 class="modal-title">Leadership Information</h5>
            <button type="button" class="btn-close" @click="OpenCloseForm()" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-for="pinfo in getprofileInfo">
              <div class="container">
                <div class="row align-items-start">
                  <div class="col-sm-3 leftsideformat">
                    <div>
                      <div><img :src="`${myPicUrl}${pinfo.empphoto}`" class="formatimage"></div>
                      <div class="formatempname">{{pinfo.empname}}</div>
                      <div class="formatemptitle">{{pinfo.emptitle}}</div>
                      <!-- <div class="formatempdatejoined">{{pinfo.datejoined}}</div>
                      <div class="formatdateappointed">{{pinfo.appointmentdate}}</div> -->
                    </div>
                  </div>
                  <div class="col-sm-9 rightsideformat">
                    <div v-html="pinfo.empdetails" class="formatempdetails" style="border-inline-start: 4px dotted orangered;"</div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer headerfooterformat">
            <button type="button" @click="OpenCloseForm()" class="btn btn-secondary" :class="'btn btn-'+variant">Close</button>
          </div>
        </div>
      </div>
    </div>
    <button type="button" @click="OpenCloseForm()" class="btn btn-secondary formatmodalbtn" :class="'btn btn-'+variant">View Profile</button>
  </template>
  <script>
  require('@/globalDeclare.js');
  export default{
    name: 'GeneralModal',
    props: ['visible', 'variant', 'getprofileInfo'],
    // props: {
    //   visible: Boolean,
    //   variant: String,
    // },
    data() {
      return {
        OpenClose: this.visible,
        myPicUrl: ''
      }
    },
    methods: {
      OpenCloseForm() {
        this.OpenClose = !this.OpenClose
      }
    },
    watch: {
      visible: function(newVal, oldVal){
        this.OpenClose = newVal
        console.log('new ' + newVal + '==' + oldVal)
      }
    },
    mounted() {
      this.myPicUrl = myUrlPath
    }
  }
  </script>
  <style scoped>
  .formatmodalbtn {
    margin-top: 30px;
  }
  /*.leftsideformat {
    border-inline-start-width: 6px;
    border-inline-start-style: solid;
    border-inline-start-color: #003;
  }*/
  /*.rightsideformat {
    border-inline-start-width: 4px;
    border-inline-start-style: solid;
    border-inline-start-color: orangered
  }*/
  .formatimage {
    width: 100%;
  }
  .formatempname {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  .formatemptitle {
    text-align: center;
    color: orangered;
    width: 100%;
  }
  .formatempdetails {
    padding: 20px;
    text-align: justify;
  }
  .headerfooterformat {
    background-color: #003;
    color: white;
  }
img {
  object-fit: cover;
}
  </style>
  
  
  
<template>

  <NbNavbar />

  <router-view/>
  <a class="fixedButton" data-bs-toggle="modal" data-bs-target="#contactusModal">
    <div>
      <btn  class="btn" :style="colortheme === 'darkTheme' ? 'background-color: whitesmoke; color: #003;' : 'background-color: black; color: whitesmoke;'">
        <font-awesome-icon icon="fa-solid fa-envelope" style="position: relative; font-size: 30px; margin-left: -20px; margin-top:5px;"/>
     </btn>
    </div>
 </a>

 <div class="modal cookiesmodal fade" id="contactusModal" tabindex="-1" aria-labelledby="contactusModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="contactusModalLabel" style="color: white;">Information Request Form</h5>
        <font-awesome-icon class="closebtn" icon="fa-solid fa-circle-xmark" aria-label="Close" data-bs-dismiss="modal" />
      </div>
      <div class="modal-body" >
        <NbContactus />
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade searchModal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
         :style="colortheme === 'darkTheme' ? 'background-color: white; color: #003;' : 'background-color: whitesmoke; color: #003;'"
        ></button>
      </div>
      <div class="modal-body">
        <SearchAutocomplete />
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
<FooterPage />
</template>

<script>
// import GsrNavbar from './components/GsrNavbar.vue';
import FooterPage from './components/FooterPage.vue';
import NbContactus from './components/NbContactus.vue';
import NbNavbar from './components/NbNavbar.vue';
import NbNavbarwhite from './components/NbNavbarwhite.vue';
import SearchAutocomplete from './components/SearchAutocomplete.vue';

export default {
  components: {FooterPage, NbContactus, NbNavbar, SearchAutocomplete, NbNavbarwhite},
  data() {
    return {
      lang: localStorage.getItem('lang'),
      colortheme: localStorage.getItem('colorTheme'),
      menuname: localStorage.getItem('menuname'),

      searchQuery: '',
      results: [],
      showModal: false
    }
  },
  
  methods: {
    performSearch() {
      if (this.searchQuery.trim() !== '') {
          fetch(`getsearchpages/${this.searchQuery}`)
              .then(response => response.json())
              .then(data => {
                  this.results = data;
              })
              .catch(error => console.error('Error:', error));
          } else {
              this.results = [];
          }
      },
  
  },
  mounted(){


    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    // let menuname = localStorage.getItem('menuname')
    let getlang = localStorage.getItem('lang');
    // check for langauge theme presence or set default
    if (getlang === 'en') {
      this.lang = 'en'
    }
    else if (getlang === 'fr') {
      this.lang = 'fr'
    }
    else {
      localStorage.setItem('lang', 'en')
    }

    if(this.colortheme === null) {
      localStorage.setItem('colorTheme', 'lightTheme')
    }
  }

}
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}



nav a.router-link-exact-active {
  color: #42b983;
}

.fixedButton{
  position: fixed;
  bottom: 50px;
  right: -30px; 
  z-index: 999999;
  padding-right: -20px;
}

.btn {
  border-radius: 30px; 

  background-color: rgb(105, 74, 74); 
  border: solid thin white; 
  color: white; 
  margin-top: 20px;
  width: 100px;
  height: 50px;
  font-size: 14px;
}
.btn:hover {
  width: 130px;
  transition: 0.5s;
}
.setbgdark {
  background-color: black;
  color: white;
}
.setbglikght {
  background-color: white;
  color: #003;
}
.closebtn {
  width: 50px;
  height: 50px;
  margin-right: -30px;
  margin-top: -40px;
  color: #003;
  cursor: pointer;

}
.fa-circle-xmark {
  color: rgb(85, 85, 110);
}
#contactusModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:999999999;
}
.modal-content {
  background-color: black;
}
.modal-body {
  background-color: white;
  color: black;
}
.searchModal{
  margin-top: 5% !important; /* Change the percentage as needed */
}

.cookiesmodal  {
  margin-top: 4% !important;

}
</style>

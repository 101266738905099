<template>
    <div>
      <Carousel :autoplay="10000" :autoplay-timeout="20000"  :wrap-around="true" class="carousel carousel__viewport ">
        <Slide class="carousel-slide " v-for="slide in carouselImages" :key="slide.vid">
          <img :src="`${imagepath}${slide.valuespng}`" class="carsimage"/>
          <div class="carousel-text" v-html="slide.valuesdetail"></div>
        </Slide>
  
        <template #addons >
          <div class="VueCarousel-pagination ">
            <Pagination  />
          </div>
            
  
      </template>
      </carousel>
    </div>
  
  
    
    </template>
    
    <script>
    import { defineComponent } from 'vue'
    import { Carousel, Pagination, Slide } from 'vue3-carousel'
    
    import 'vue3-carousel/dist/carousel.css'
    
    export default defineComponent({
      name: 'NbRealslidehow',
      props: ['carouselImages', 'imagepath'],
      components: {
        Carousel,
        Slide,
        Pagination,
      },
      data: () => ({
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
    }),
    })
    </script>
   <style scoped>
   .carousel-container {
    position: relative; /* Ensure the container allows absolute positioning inside */
  }
  
  .carousel-slide {
    position: relative;
    min-width: 100%;
    height: 100%;
    transition: 20s ease;
    
  
  }
  
  .carousel-slide img {
    width: 100%; /* Make sure the image covers the full slide */
    height: auto;
    object-fit: cover;
  }
  
  .carousel-text {
    position: absolute;
    margin-top: 50px; /* Adjust this based on your design */
    width: auto;
    text-align: left;
    font-size: 48px; 
    color: #ecf0f1;
    left: 20px; /* Adjust for horizontal positioning */
    z-index: 10; /* Make sure the text is above the carousel content */
    color: white; /* Text color, adjust for contrast */
    font-size: 24px; /* Adjust text size */
    background-color: rgba(0, 0, 0, 0.5); /* Optional background for readability */
    padding: 10px;
    margin-left: 120px;
    border-radius: 5px; /* Optional styling for the text box */
    
  }
  
  .carousel__viewport {
    perspective: 2000px;
  }
  
  .carousel .VueCarousel-pagination {
    display: flex; /* Align dots horizontally */
    justify-content: center; /* Center the dots */
    margin-top: -100px; /* Add some space above or below the dots */
    position: relative;
  }
  .carousel .VueCarousel-dot{
    width: 30px; /* Adjust size of dots */
    height: 30px;
    background-color: #ddd; /* Default dot color */
    border-radius: 50%; 
    margin: 0 auto;
    margin-top: -50px;
  }
  
  
  .carousel .VueCarousel-dot-active {
    background-color: #af126e; /* Active dot color (e.g., blue) */
  }
  
  .carousel .VueCarousel-dot:hover {
    background-color: #555; /* Change color on hover */
  }

  .carousel .slide-fade-enter-active {
    transition: all 30s ease-out 20s;
  }
  
   .carousel__viewport {
     perspective: 2000px;
   }
  
   .carousel__track {
     transform-style: preserve-3d;
   }
   
   .carousel__slide--sliding {
     transition: 2s;
   }
   
   .carousel__slide--active {
     opacity: 1;
     transform: rotateY(0) scale(1.1);
   }
   .carousel-slide .carsimage {
    width: 100%; 
    height: 60vh; 
    z-index: -999999
   }
   .carsimage {
    object-fit: cover;
   }
   @media only screen and (max-width: 600px) {
    .carousel-slide .carousel-text {
      margin-left: 30px;
      width: 80%;

    }
    .carousel-slide .carsimage {
      width: 100%; 
      height: 50vh; 
      z-index: -999999
     }
   }
   @media only screen and (min-width: 600px) {
    .carsimage {
      width: 100%; 
      height: 40vh; 
      z-index: -999999
     }
   }
   
   @media screen and (min-width:769px) {
    .carousel-slide .carsimage {
      width: 100%; 
      height: 70vh; 
      z-index: -999999
     }
   }
   
   @media only screen and (min-width: 992px) {
    .carousel-slide .carsimage {
      width: 100%; 
      height: 70vh; 
      z-index: -999999
     }
   }
   @media only screen and (min-width: 1200px) {
    .carousel-slide .carsimage {
      width: 100%; 
      height: 70vh; 
      z-index: -999999
     }
   }

   @media only screen and (min-width: 1400px) {
    .carousel-slide .carsimage {
      width: 100%; 
      height: 90vh; 
      z-index: -999999
     }
   }

   @media only screen and (min-width: 1600px) {
    .carousel-slide .carsimage {
      width: 100%; 
      height: 90vh; 
      z-index: -999999
     }
   }
</style>
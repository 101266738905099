
<template>
    <form @submit.prevent="handleSubmit" class="form-signin rounded">
    
        <input type="text" class="frmcontrol mt-3"  v-model="firstname" placeholder="* please provide your name." required/>      
    
        <div class="input-wrapper mt-3">
            <input type="email" class="frmcontrol" v-model="email" placeholder="* Please enter email." required/>
            <i class="fa-solid fa-envelope"></i>
        </div>    
        
        <textarea type="text" style="height: 300px;"  rows="20" cols="10"  class="frmcontrol mt-3 textarea" v-model="usermessage" placeholder="* Please how can we assist you? Want to be contacted? Provide a contact number (optional)." required>
        </textarea>
        
        <input class="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" checked style="margin-top: 30px;">
        <label class="form-check-label" for="flexCheckCheckedDisabled" style="color: black; font-size: 12px; font-size: 12px; margin-top: 30px; padding-left: 10px;">
        By ticking this box, you are providing consent for GSRCRIC to contact you.
        </label> <br />
    
        <button  type="submit" class="btn btn-primary">Send message</button>
    </form>
    </template>
    

    <script>
    import axios from 'axios'
    import { useToast } from "vue-toastification";
    
    export default {
        name: 'NbContactus',
        components: {  },
        data() {
            return {
                firstname: '',
                email: '',
                usermessage: '',
                consenttosentmsg: false,
                returnmsg: 'contactusmsg',
            }
    },
    methods:{
        async handleSubmit() {
            let el = document.getElementById('flexCheckCheckedDisabled')
            if(el.checked) {
                this.consenttosentmsg = true
                console.log(this.consenttosentmsg)
            }
            else{
                this.consenttosentmsg = false
                console.log(this.consenttosentmsg)
            }
            const data = {
                name: this.firstname, 
                email: this.email, 
                message: this.usermessage,
                consenttosentmsg: this.consenttosentmsg,
                filtertext: this.returnmsg
            }
            console.log(data)
            await axios.post('receiveusermessage/', data)
            .then(response => {
                
                   const toast = useToast();
                   toast.success("Message sent successfully!", {
                    // Optional configuration per toast
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: "✅",  // Custom icon
                }); 
                   
        
            }).catch(err => {
               
                const toast = useToast();
                   toast.error("Message not sent. check enteries and try again!", {
                    // Optional configuration per toast
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: "✅",  // Custom icon
                });  

                // console.log(err)
            })  
            this.firstname= ''
            this.email = ''
            this.usermessage = ''
            this.consenttosentmsg = false           
            // this.$router.push({name: 'otpverify'});
        }
        
        }
    
    }
    </script>
    
<style lang="scss" scoped>
/*@import '../assets/scss/_toast.scss';
@import '../assets/scss/_toastContainer.scss';
@import '../assets/scss/_variables.scss';
@import '../assets/scss/index.scss';
@import '../assets/scss/_progressBar.scss';
@import '../assets/scss/_icon.scss';
@import '../assets/scss/_closeButton.scss';
@import '../assets/scss/animations/_bounce.scss';
@import '../assets/scss/animations/_fade.scss';
@import '../assets/scss/animations/_slideBlurred.scss';*/

    img {
        border-radius: 60%;
        text-align: center;
      }
    .imgdiv {
        text-align: center;
        margin-bottom: 30px;
    }
    .form-signin {
        width: auto;
        min-width: 400px;
        min-height: 600px;
        padding: 15px;
        margin: auto;
        background-color: whitesmoke;
    }
      
    .form-signin .checkbox {
        font-weight: 400;
    }
    
    .form-signin .form-floating:focus-within {
        z-index: 2;
    }
      
    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .input-wrapper {
        display:inline-block;
        position: relative;
        width: 100%;
    }
    
    .frmcontrol {
        width: 100%;
        border: none;
        border-bottom: solid thin rgb(24, 20, 20);
        height: 40px;
        margin-bottom: 20px;
        padding-inline-start: 15px;
        background-color: whitesmoke;
        color: black;
        }
        
    .frmcontrol:focus {
        border: none;
        border-bottom: solid thin #003;
        outline: none;
        transform: scale(0.99);
    }
    .fa-eye, .fa-eye-slash {
        margin-left: -30px;
        z-index: -1;
        cursor: pointer;
        font-size: 20px;
        color: grey;
    }
    
    .fa-envelope{
        margin-left: -30px;
        z-index: -1;
        font-size: 20px;
        color: rgb(210, 207, 207);
    }
    
    
    .input-wrapper1 {
        display:inline-block;
        position: relative;
        width: 100%;
    }
    .form-check {
        margin-top: 20px;
    }
    .btn-primary {
        margin-top: 20px;
    }
    .fa-user {
        font-size: 25px;
        margin-right: 20px;
    }
    .Toastify__toast-container--top-right {
        margin-top: 250px; /* Adjust this value for the desired margin */
        margin-right: 10px;
      }
    textarea {
        height: 400px;
        padding: 10px;
    }
    img {
        object-fit: cover;
      }
    </style>
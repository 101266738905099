<template>
<div class="eventscss" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'">
    <div class="row">
        <div class="col-xs-12 col-sm-12 colformat">
        <div class="accordion accordion-flush" :id="`accordionExample${accodionid}`"
        :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
        >
            <div class="accordion-item" v-for="(evt, index) in eventdata" :key="index"
              :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' : 'background-color: whitesmoke; color: #003;'"
            >
                <h2 class="accordion-header" :id="`headingOne${evt.upevid}`">
                <button class="accordion-button" type="button" :id="`accbtn${index%2}`" data-bs-toggle="collapse" :data-bs-target="`#collapseOne${evt.upevid}`" aria-expanded="false" :aria-controls="`collapseOne${evt.upevid}`">
                    <span>{{ evt.upevshortdesc }}</span>
                </button>
                </h2>
                <div :id="`collapseOne${evt.upevid}`" class="accordion-collapse collapse" :aria-labelledby="`headingOne${evt.upevid}`" :data-bs-parent="`#accordionExample${accodionid}`">
                <div class="accordion-body">
                    <div v-html="evt.upevdesc"></div>
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>

export default {

    props: ['eventdata', 'accodionid'],
    data() {
      return {
        colortheme: localStorage.getItem('colorTheme')
      }
    }
}
</script>

<style scoped>
#accbtn0 {
    background-color:whitesmoke;

  }
  #accbtn1 {
    background-color:rgb(236, 234, 236);
  }
  .accordion {
    border-top: solid thin orangered;
    border-bottom: solid thin orangered;
  }
img {
  object-fit: cover;
}
  /*==============MEDIA QUERIES=====================*/
@media only screen and (max-width: 600px) {
    .colformat {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  
   /* Small devices (portrait tablets and large phones, 600px and up) */
   @media only screen and (min-width: 600px) {
 
   }
   
   /* Medium devices (landscape tablets, 768px and up) */
   @media only screen and (min-width: 768px) {
    
   }
  
   
   /* Large devices (laptops/desktops, 992px and up) */
   @media only screen and (min-width: 992px) {
  
   }
   
   /* Extra large devices (large laptops and desktops, 1200px and up) */
   @media only screen and (min-width: 1200px) {
    
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1400px) {
  
  }
  
    /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1600px) {
  
  }
</style>

<template>
  <!-- <div v-if="loading" class="spinner-container" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white;' :  'background-color: whitesmoke; color: #003;'"> 
    <img src="@/assets/loading-white.gif" alt="Loading..." class="spinner" style=" border-radius: 5px;"/>
  </div>
  <div v-else > -->
    <section class="secimage" v-show="showpictureimage === true">
      <div class="txtdataimage">
        <h1  v-html="imagetext" :class="formatvhtml"></h1>
      </div>
      <div>
          <img :src="poster" class="imgcss" />
    </div>
    <div class="overlayimage"></div>
  </section>

<section v-if="showpictureimage === false" style="margin-top: 140px;">

</section>
   
    
<!------Top banner section-->
<section  
  class="allpagesbanner slidebannerformat" 
  v-show="showallpagesbanner === true"  :style="colortheme === 'darkTheme' ? `${darkstyle}` :  `${allpagesbannercolor};`">
  <AllPagesBanner :bannertext="mbtnsectext" :colortheme="colortheme" />
</section>
    
    
    <!------ TOP GENERIC PAGE INFORMATION ENDS HERE-->
  
    <section :style="colortheme === 'darkTheme' ? darkstyle :  ''">
        <div class="container containerformat">
            <div >
                <p v-html="newstext">
        
                </p>
            
                <!-- Pagination Controls -->
                <div v-if="totalPages > 1"  style="padding-top: 100px;padding-bottom: 20px;">
                <div style="float: left;">
                    <span @click.prevent="fetchItems(currentPage - 1)" :disabled="!prevLink">
                    <font-awesome-icon icon="fa-solid fa-circle-chevron-left"  class="arrowicons"/>
                    </span>
                </div>
                
                <!-- <button v-for="page in totalPagesArray" :key="page" @click.prevent="fetchItems(page)" :class="{ 'active': currentPage === page }">
                    {{ page }}
                </button> -->
                <div style="float: right;">
                    <span @click="fetchItems(currentPage + 1)" :disabled="!nextLink" 
                    :style="colortheme==='darkTheme' ? 'color: white' : 'color:black'">
                    <font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons"  />
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
  

    <!------ BOTTOM GENERIC PAGE INFORMATION STARTS HERE -->

    <DesignYourway :designuwdata="designuwdata" />


  <!-- </div> -->
  
</template>
    
<script>
    import axios from 'axios';

    import AllNews from "@/components/AllNews.vue";
   

    //=============ALL GENERIC IMPORTS STARTS HERE============================
    import DesignYourway from '@/components/DesignYourway.vue';
    require('@/defaultPath');
  
    
    export default {
      name: 'AllNews',
      components: { AllNews, DesignYourway

      },
     
      data() {
        return {
            //===========SPECIFIC PAGE DATA VARIABLES HERE
            // newsdata: [],
            newsid: '',
    
            items: [],
            currentPage: 1,
            totalPages: 0,
            totalItems: 0,
            nextLink: null,
            prevLink: null,
            readnews: '',

            //-----------ALL PAGES VARIABLES====================

            poster: '',


            pvpictureimage: false,

            allpages: [],
            allslidesinfo: [],
            allslidestext: '',

            //three section cards variables
            allcardsinfo: [],
            allcardmdcol: 3,

            //pagesettings
            myImgUrl: myUrlPath,
            imagetext: '',
            btmimagetext: '',
            colortheme: localStorage.getItem('colorTheme'),
            showslideshow: false,
            showvideo: false,
            showpictureimage: false,
            showbanner: false,
            darkstyle: '',
            alternatedarkstyle: '',
            whitesmokestyle: '',
            submenustyle: '',
            allcardtoptext: '',
            showallpagesbanner: false,
            allpagesbannercolor: '',


            mbtnsectext: '',
            bgcolor: '',
            setbannercolor: false,

            breadcrumbdata: [],

            loading: true, // Initially true to show the spinner
            
            defaultURL: defaultPath,
            lang: localStorage.getItem('lang'),
            // showleftsec: false,
            // showrightsec: false,

            //history in years
            allyears: [],
            yearimages: '',
            getlastimage: '',
            imgget: false,
            aboutdesc: '',


            //everything news
            newstitle: '',
            newslinktext: '',
            newslink: '',
            allnews: [],
            cardpress: [],
            newscat: [],

            images: [],
            imagelinkpath: '',
            linkdisabled: 'news',
        
        
        }
      },
    
    methods: {
      //-------------------SPECIFIC PAGE METHODS HERE----------------------
      async getclickedId($event){
      let getid = $event.target.value
      
      if(getid === 'getAll'){
        axios.get('getallnews/').then(
        response => {
            this.newsdata = response.data
      }) .catch((error) => {
          console.log(error)
      }) 
      }else {
        axios.get(`getsinglcat/${getid}`).then(
        response => {
            this.newsdata = response.data
      }) .catch((error) => {
          console.log(error)
      })  
      }
    },
    fetchItems(page = 1) {
        axios.get(`getnewspagination/?page=${page}`)
          .then(response => {
            this.items = response.data.results;
            this.newstext = response.data.results[0].newstext
            this.currentPage = response.data.current_page;
            this.totalPages = response.data.total_pages;
            this.nextLink = response.data.links.next;
            this.prevLink = response.data.links.previous;
          })
          .catch(error => {
            console.error("Error fetching items:", error);
          });
      },



    // ----------------Used in all pages-------------------------------------
    fetchItemsAllPages(page = 1) { 
    axios.get(`getallsliderspaginationfilter/?filtertext=retailfilter1&page=${page}`)
      .then(response => {
        this.items = response.data.results;
      
        this.slidetext = this.items[0].slidetext
        this.slidelmorelink = this.items[0].slidelmorelink
        this.slidelmoretext = this.items[0].slidelmoretext
        this.slideimage = this.items[0].slideimage
        this.slideimagetext = this.items[0].slideimagetext
        
        const count = response.data.count; // Total number of records
        const pageSize = 1; // Adjust this to match the page size used in your backend
        this.totalPages = Math.ceil(count / pageSize);

        this.currentPage = response.data.current_page;
        this.nextLink = response.data.links.next;
        this.prevLink = response.data.links.previous;
      
      })
      .catch(error => {
        console.error("Error fetching items:", error);
      });
      
      },
      moveNext(){
        let thisx = this
        let page = thisx.currentPage + 1
        this.fetchItems(page)
      },
  
    logChange(event) {
        this.showImage = true
        this.histsearchid = event
    
        axios.get(`gethistorydataid/${this.histsearchid}`).then(
            response => {
                this.yearimages = response.data
        })
            .catch((error) => {
                console.log(error)
            })
        }
    },
  

  //=========================COMPUTED SECTION==========================
  computed: {
    //---------------------use in all pages------------------------
      totalPagesArray() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },

  },

//===========================MOUNTED HOOK SECTION===================================
async mounted() {
    // Simulate a load time with a timeout (replace this with real data fetching)
    // setTimeout(() => {
    //   this.loading = false; // Hide spinner after data is loaded
    // }, 2000);
     //--------NEWS PAGE MOUNTED SECTION--------------------------- 

    this.fetchItems();

    axios.get('getnewscategories/').then(
        response => {
            this.newscategory = response.data
      }) .catch((error) => {
          console.log(error)
      })
      
      axios.get('getallnews/').then(
        response => {
            this.newsdata = response.data
      }) .catch((error) => {
          console.log(error)
      })  

  
  // ------------------Design it your way section------------------
  await axios.get('getdesignyourwaysection/news').then(
    response => {
      this.designuwdata = response.data
    }).catch((error) => {
      console.log(error)
    }) 

  //---------------------ALL PAGES MOUNTED SECTION-------------------------------------
  localStorage.setItem('menuname', 'engineeringsupport')
     //-------------get page theme------------------
    this.nbTheme = localStorage.getItem('nbtheme')

    this.fetchItemsAllPages();
    this.myPicUrl = myUrlPath
    //-------------page carousel section------------------
  await axios.get('getslideshow/news').then(
    response => {
      this.carouselSlides = response.data
    }).catch((error) => {
      console.log(error)
    })  

    //------------------slider section------------------
  // await axios.get('getclientsliders/news').then(
  //   response => {
  //     this.images = response.data
  //   }).catch((error) => {
  //     console.log(error)
  //   })  

    //-------------videos scetion------------------
  await axios.get('allpagespv/news').then(
    response => {

      this.poster = this.myImgUrl + response.data[0].pvposter

    })
    .catch((error) => {
        console.log(error)
      })

  //Breadcrum info
  await axios.get('getbreadcrumb/news').then(
      response => {
        // this.allpages = response.data
        this.breadcrumbdata = response.data
      })
      .catch((error) => {
          console.log(error)
        })

    //-------------all pages settings------------------
  await axios.get('getwebsitesettings/news').then(
      response => {         
        this.menuname = 'retail'
        localStorage.setItem('menuname', this.menuname)
        this.webdesc = response.data[0].webdesc
        this.showslideshow = response.data[0].showslideshow
        this.showvideo = response.data[0].showvideo
        this.showpictureimage = response.data[0].showpictureimage
        this.topmenubarbgcolor = response.data[0].topmenubarbgcolor
        this.mainmenubarcolor = response.data[0].mainmenubarcolor
        this.darkstyle = response.data[0].darkstyle
        this.alternatedarkstyle = response.data[0].alternatedarkstyle
        this.whitesmokestyle = response.data[0].whitesmokestyle

        this.submenustyle = response.data[0].submenustyle
        this.allcardtoptext = response.data[0].allcardtoptext
        this.showallpagesbanner = response.data[0].showallpagesbanner
        this.allpagesbannercolor = response.data[0].allpagesbannercolor

      })
    .catch((error) => {
        console.log(error)
    })

    //------------Container Fluid Section---------------
  // await axios.get('getcontainerfluiddata/news').then(
  //     response => {
  //         this.contfluiddata = response.data
  //   })
  //   .catch((error) => {
  //       console.log(error)
  //     })


    //-------------all pages banner------------------
  await axios.get('getbannerinfo/news').then(
    response => {
        this.mbtnsectext = response.data.mbtnsectext
          this.bannerbgcolor = response.data.bgcolor
          this.bgcolor = response.data.bgcolor
          this.setbannercolor = response.data.setbannercolor
    }) .catch((error) => {
        console.log(error)
      })

  //===========================INCLUDE IN ALL PAGES ENDS HERE===================================
  
    }  
  }
</script>
<style lang="scss" scoped>
    .disabledbutton {
        background-color: #ccc;
        cursor: not-allowed;
    }
  .arrowicons {
    font-size: 48px;
    cursor: pointer;
  }
  .arrowicons:hover {
    color: skyblue;
  }

  /*===============GENERIC PAGE SCSS AND CSS =========================================================*/
  .bannersection1 {
    margin-top: -20px;
    height: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 150px;
    padding-right: 150px;
    }
    
    .transition {
    transition: all 3s ease-out;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    }
    
    @keyframes fadeInAnimation {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
    }
    .videosection {
    background-color: #000000;
    }
    
    .overlayvideo{
    opacity: .4;
    height: 600px;
    background-color: #000000;
    }
    .overlayvideo {
    opacity: 0.55;
    width: 100%;
    height: 670px;
    background: linear-gradient(to bottom, #000000);
    margin-top: -670px;
    }
    
    
    .overlayimage{
    opacity: .4;
    height: 600px;
    background-color: #000000;
    }
    .overlayimage {
    opacity: 0.55;
    width: 100%;
    height: 670px;
    background: linear-gradient(to bottom, #000000);
    margin-top: -670px;
    }
    
    .overlaycarousel{
    opacity: .4;
    height: 600px;
    background-color: #000000;
    }
    .overlaycarousel {
    opacity: 0.55;
    width: 100%;
    height: 670px;
    background: linear-gradient(to bottom, #000000);
    margin-top: -670px;
    }
    
    .btnformat {
      border-radius: 50%;
      padding: 14px;
      margin-left: 10px;
    }
    
    .playercss {
    max-height: 300%; 
    height: 70vh;
    
    }
    
    
    .thing {
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }
    
    img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    background-color: #000000;
    }
    
    
    [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
    }
    
    .clientextinfo {
    width: 100%;
    margin: 0 auto;
    margin: 0 auto; 
    text-align: center;
    margin-top: 130px;
    }
    .carousel {
    position: relative;
    max-height: 100vh;
    height: 80vh;
    margin-top: -100px;
    z-index: -9999;
    
    .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-height: 100%;
    height: 100%;
    
    img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.99;
    }
    }
    }
    .pushbyeighty {
    margin-top: 166px;
    }
    
    .clientextinfo {
    width: 100%;
    margin: 0 auto;
    margin: 0 auto; 
    text-align: center;
    margin-top: 130px;
    }
    .carousel {
    position: relative;
    max-height: 100vh;
    height: 80vh;
    margin-top: -100px;
    z-index: -9999;
    
    .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-height: 100%;
    height: 100%;
    
    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.99;
    }
    }
    
    }
    .txtselected {
    text-align: justify;
    }
    .container {
    
    padding-top: 20px;
    }
    .wrapcontent {
    padding-top: 50px;
    padding-bottom: 50px;
    }
    .videosec {
    margin-top: 160px;
    }
    
    .secimage {
    margin-top: 160px;
    }
    .imgcss {
    width: 100%;
    }
    .rndbtnformat {
      margin-top: -120px;
      margin-left: 100px;
    }
    @media only screen and (max-width: 600px) {
    .bannersection1 {
    width: 100%;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    } 
    .imgcss {
    width: 100%; 
    height: 40vh;
    margin-top: -80px;
    }
    .overlayimage, .overlayvideo{
    opacity: 0.55;
    width: 100%;
    height: 90vh;
    background: linear-gradient(to bottom, #000000);
    
    }
    .container {
    padding-left: 20px;
    padding-right: 20px;
    }
    
    .txtdatavideo  {
    position: absolute;
    margin-top: 250px;
    z-index: 9999999999999;
    color: whitesmoke;
    padding-left: 30px;
    }
    
    .txtdataimage  {
    position: absolute;
    margin-top: 150px;
    z-index: 9999999999999;
    color: whitesmoke;
    padding-left: 30px;
    }
    .rndbtnformat {
      margin-top: -60px;
    }
    
    }
    /*-----------------Spinner------------*/
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 150px; /* Adjust size as needed */
  height: 150px;
}
    @media only screen and (min-width: 600px) {
    .bannersection1 {
    width: 100%;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    } 
    
    .txtdatavideo  {
    position: absolute;
    margin-top: 250px;
    z-index: 9999;
    color: whitesmoke;
    padding-left: 60px;
    }
    
    .txtdataimage  {
    position: absolute;
    margin-top: 100px;
    z-index: 9;
    color: whitesmoke;
    padding-left: 30px;
    }
    .imgcss {
    width: 100%; 
    height: 40vh;
    margin-top: -80px;
    }
    .rndbtnformat {
      margin-top: 60px;
    }
    }
    
    @media screen and (min-width:769px) {
    .txtdataimage  {
    position: absolute;
    margin-top: 170px;
    z-index: 9;
    color: whitesmoke;
    padding-left: 30px;
    }
    
    .txtdatavideo  {
    position: absolute;
    margin-top: 250px;
    z-index: 9999;
    color: whitesmoke;
    padding-left: 100px;
    }
    
    .txtdataimage  {
    position: absolute;
    margin-top: 100px;
    z-index: 9;
    color: whitesmoke;
    padding-left: 30px;
    }
    
    .imgcss {
    width: 100%;
    height: 50vh;
    }
    
    .rndbtnformat {
      margin-top: 60px;
    }
    
    }
    
    @media only screen and (min-width: 992px) {
    
    .txtdataimage  {
    position: absolute;
    margin-top: 140px;
    z-index: 9;
    color: whitesmoke;
    padding-left: 30px;
    }
    
    .imgcss {
    width: 100%;
    height: 50vh;
    }
    }
    @media only screen and (min-width: 1200px) {
    .txtdataimage  {
    position: absolute;
    margin-top: 350px;
    z-index: 9;
    color: whitesmoke;
    padding-left: 80px;
    }
    
    .imgcss {
    width: 100%;
    height: 70vh;
    
    }
    .playercss {
    height: 650px;
    }
    
    .txtselected {
    padding-right: 60px;
    text-align: justify;
    }
    .txtleftalign {
    text-align: justify;
    padding-left: 60px;
    }
    .rndbtnformat {
      margin-top: -140px;
    }
    }
    
    @media only screen and (min-width: 1600px) {
    .txtdataimage  {
    position: absolute;
    margin-top: 350px;
    z-index: 9;
    color: whitesmoke;
    padding-left: 300px;
    }
    
    .txtdatavideo  {
    position: absolute;
    margin-top: 250px;
    z-index: 9999;
    color: whitesmoke;
    padding-left: 300px;
    }
    
    .imgcss {
    width: 100%;
    height: 70vh;
    }
    .overlaycarousel {
    opacity: 0.55;
    width: 100%;
    height: 750px;
    background: linear-gradient(to bottom, #000000);
    margin-top: -750px;
    }
    }

</style>
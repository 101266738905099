<template>
    <div class="container">
      <div>
        <p v-html="rpttopcaption"></p>
      </div>
      <div class="row" v-for="rptdata in tworeportdata" :key="rptdata.invid">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div class="titleformat">{{ rptdata.lrptheading}}</div>
          <router-link :to="{path: `${rptdata.lrptlinkpath}`}" style="text-decoration: none;">
            <img :src="`${defaultURL}${rptdata.lrptimagepath}`" />             
            <div>
              <span  class="linkcaption">{{rptdata.lrptlinktext}}
              <i class="fa-solid fa-link"></i></span>
            </div>
          </router-link>
        </div>
       
          
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div class="titleformat">{{ rptdata.rrptheading}}</div>
          <router-link :to="{path: `${rptdata.rrptlinkpath}`}" style="text-decoration: none;">
            <img :src="`${defaultURL}${rptdata.rrptmagepath}`" />            
            <span   class="linkcaption">{{rptdata.rrptlinktext}}
            <i class="fa-solid fa-link"></i></span>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    require('@/defaultPath');
  export default {
   props: ['tworeportdata', 'rpttopcaption'] ,
   data() {
      return {
        defaultURL: defaultPath,
      }
   },
   mounted() {
       
   } 
  }
  </script>
  
  <style scoped>
  .reportsection {
      background-color: whitesmoke;
  
  }
  .col1format {
   text-align: right;
  }
  .linkcaption {
      text-decoration: none;
      color: rgb(9, 92, 139);
      font-size: 16px;
      font-weight: bold;
      float: right;
      padding-top: 10px;
    }
  .fa-link {
      color: orangered;
  }
  .rptviewalltext {
      padding-top: 20px;
      color: orange;
  }
  .innerlink {
      color: rgb(122, 83, 10);
  }

  img {
    width: 100%;
    height: 40vh;
  }
  .titleformat {
    padding: 30px;
  }
  img {
    object-fit: cover;
  }
  @media only screen and (max-width: 600px) {
  .reportsection {
    padding: 10px;
  
  }
  .col1format, .rptviewalltext, .linkcaption, .titleformat, .innerlink{
    text-align: center;
   }
 
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .reportsection {
      padding: 10px;
  
    }
    .col1format, .rptviewalltext, .linkcaption, .titleformat, .innerlink{
      text-align: center;
     }
 
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  
  }
  
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
 
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1400px) {
      .col1format {
        margin-top: 130px;
      }
      .titleformat {
       font-weight: bold;
       font-size: 24px;
       color: #003;
      }
      .linkcaption {
        text-decoration: none;
      }
  
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1600px) {
  
    }
     
  </style>
<template>

<section class="footer" :style="colortheme === 'darkTheme' ? `background-color: #252525; color: white` : `background-color: whitesmoke; color: #003;`">
  <!-- ======= Footer ======= --> 
  <div class=" container footercontainer" >
    <div style="text-align: center; height: auto;  margin-bottom: 80px;">
    

      <span style="padding-bottom: 20px;"><hr style="margin-top: -90px; width: 100%;" /></span>


    <div >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3">
          <div class="footercaption">
        
          </div>
          <div>
            <div>
              <ul>
                <li class="footerdata"><a class="rlinkcss"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3 abtitleformat">
            <div class="allformat formatitle"><p>{{ abtitletext}}</p></div>
            <div class="allformat"  style="padding-right: 30px; text-align: justify; font-size: 13px;"><p>{{ abtext }}</p></div>
            <div class="allformat formatlinks" ><p :style="colortheme === 'darkTheme' ? `color: white; cursor: pointer` : 'cursor: pointer;'">{{ ablinktext }}</p></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 abactivitiesformat" v-for="fcat in footercategories">
          <div class="allformat formatitle"><p>{{ fcat.footcatdesc }}</p></div>
          <div class="formatactivities">
              <p v-for="flink in footerlinks">
                <router-link :to="{path: `${flink.actlinkpath}`}" 
                  class="formatlinks" style="line-height: 2;" :style="colortheme === 'darkTheme' ? `color: white;` : ''"
                 
                  @click="scrollToTop"
                  v-if="flink.actref === fcat.actfootid">
                    {{ flink.actlinktext}}
                  </router-link>
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"></a>
  </div>

  <div class="lastfooter" style="background-color: black; border-top: white thin solid; color: white; text-align: center;">
    <div class="container">
      <div class="row">
        <!-- <div class="col-xs-12 col-sm-12 col-md-2">
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12">
          <div class="copyright">
          &copy; {{$t("Copyright") }}<strong><span style="margin-left: 5px;">   Nexgbits   </span></strong>. {{ $t("All Rights Reserved") }}
          </div>
          <div class="ulelements">
            <ul class="formatul" style="margin-left: -30px;">
              <li class="formatli" data-bs-toggle="modal" data-bs-target="#privacyModal" style="cursor: pointer;"> {{$t("Privacy notice")}}</li>
              <li class="formatli" data-bs-toggle="modal" data-bs-target="#cookiesModal" style="cursor: pointer;">{{$t("Cookies policy")}}</li>
              <!-- <li class="formatli">Disclaimer</li> -->
              <!-- <li class="formatli">Security policy</li> -->
              <li class="formatli" data-bs-toggle="modal" data-bs-target="#termsofuseModal" style="cursor: pointer;"> {{$t("Terms of use")}}</li>

              <li class="formatli" style="cursor: pointer;" v-if="lang === 'en'"> 
                <router-link :to="{path: '/en/sitemap/'}"  class="linkfm">{{$t("Sitemap")}}</router-link>
              </li>
              <li class="formatli"  style="cursor: pointer;" v-else> 
                <router-link :to="{path: '/fr/Plan-du-site/'}" class="linkfm">
                  {{$t("Sitemap")}}
                </router-link>
               
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2">
          <label class="switch" data-toggle="tooltip" title="Please click to toggle dark and normal">
            <input type="checkbox" @click.prevent="toggleCheckbox" id="checkifcheck">
            <div class="slider round"></div>
          </label>
        </div> -->
      </div>
    </div>
  </div>
  <TermsofUsebar />
</section>

<!-- Modal -->
<div class="modal fade" id="termsofuse" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{$t("Terms of use")}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-html="termsofservices"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="privacy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{$t("Privacy")}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-html="nbprivacy"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>

</div>


<div class="container mt-5" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
  <!-- The Modal -->
  <div class="modal fade" id="cookiesModal" tabindex="-1" role="dialog" aria-labelledby="cookiesModalLabel" aria-hidden="true"
    :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''"
  >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
          <div class="modal-content" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
              <div class="modal-header" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white; border-bottom: none;' : 'border-bottom: none;'">
                  <!-- <h5 class="modal-title" id="cookiesModalLabel">Modal Title</h5> -->
                  <button type="button" class="btnclosecookies" data-dismiss="modal" >
                    <font-awesome-icon class="closebtn" icon="fa-solid fa-circle-xmark" aria-label="Close" data-bs-dismiss="modal" />
                  </button> 
               
              </div>
              <div class="modal-body" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
                <div class="container" style="height: auto; " :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-4" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
                      <div class="tabsformat" @click="getPrivacyOverview($event)" 
                      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''"
                      >
                      {{$t("PRIVACY OVERVIEW")}}
                      </div>
                      <div class="tabsformat" @click="getStricklyCookies()"
                      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''"
                      >
                        {{$t("STRICTLY NECESSARY COOKIES")}}
                      </div>
                      <div class="tabsformat" @click="getPerformanceCookies()"
                      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''"
                      >
                        {{$t("PERFORMANCE COOKIES")}}
                      </div>
                      <div class="tabsformat" @click="getCookiePolicy()"
                      :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''"
                      >
                        {{$t("COOKIE POLICY")}}
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-8" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
                      <div class="form-check form-switch" style="border-left: solid thick #f5f4f3;" v-if="showprivacyoverview === true">
                        <div v-html="privacyoverview" ></div>
                          <!-- <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                          <label class="form-check-label" for="flexSwitchCheckChecked">Privacy Overview</label> -->
                        </div>
                    
                        <div class="form-check form-switch" style="border-left: solid thick #f5f4f3;" v-if="showstrickcookies === true">
                          <div v-html="necssarycookies"></div>
                          <!-- <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                          <label class="form-check-label" for="flexSwitchCheckChecked">Strickly Cookies</label> -->
                        </div>
  
                        <div class="form-check form-switch"  style="border-left: solid thick #f5f4f3;" v-if="showperfcookies === true">
                          <div v-html="performancecookies"></div>
                          <!-- <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                          <label class="form-check-label" for="flexSwitchCheckChecked">Performance Cookies</label> -->
                        </div>
  
                        <div class="form-check form-switch" style="border-left: solid thick #f5f4f3;" v-if="showcookiepolicy === true">
                          <!-- <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                          <label class="form-check-label" for="flexSwitchCheckChecked">Cookie Policy</label> -->
                        </div>
                    </div>

                

                  </div>
                </div>
              </div>
              <div class="modal-footer" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : 'border-bottom: none;'">
                  <button type="button" class="btn btn-primary">{{$t("Save changes")}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- modal for privacy and terms of use-->
 <!-- Button trigger modal -->

<!-- Privacy -->
<div class="modal fade privacymodal" id="privacyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true"
  :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog modal-xl" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
    <div class="modal-content" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
      <div class="modal-header">
        <h5 class="modal-title" id="privacyModalLabel">{{$t("Privacy policy")}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-html="privacy" class="privacyfmt"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Terms of use -->
<div class="modal fade termsofusemodal" id="termsofuseModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" 
  aria-labelledby="termsofuseModalLabel" aria-hidden="true" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
  <div class="modal-dialog modal-dialog-centered modal-dialog modal-xl">
    <div class="modal-content" :style="colortheme === 'darkTheme' ? 'background-color: black; color: white' : ''">
      <div class="modal-header">
        <h5 class="modal-title" id="termsofuseModalLabel">{{$t("Terms of use policy")}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-html="termsofuse" class="termsofusefmt"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { useToast } from "vue-toastification";
import TermsofUsebar from './TermsofUsebar.vue';


export default {
    name: 'FooterPage',
    props: ['footertheme'],
    components: {TermsofUsebar},
    data() {
        return {
          
          footercategories: [],
          footerlinks: [],
        

          //aboute data
          footerabout: [],
          abshortdesc: '',
          abtext: '',
          ablinktext: '',
          ablinkpath: '',
          abtitletext: '',
          abtitlepath: '',
          acttitletext: '',
          acttitlepath: '',
          abbgcolor: '',
          
          colortheme: localStorage.getItem('colorTheme'),
          lang: localStorage.getItem('lang'),


          //contact data
          footercontacts: [],
          contshortdesc: '',
          conttel: '',
          contfax: '',
          contaddresstext: '',
          contaddresslinkpath: '',
          contdetails: '',
          contdetailslinkpath: '',
          contrptvoilationtext: '',
          contrptvoilationpath: '',
          conttitletext: '',
          conttitlepath: '',
          contbgcolor: '',
          colortheme: '',
         

          showprivacyoverview: false,
          showstrickcookies: false,
          showperfcookies: false,
          showcookiepolicy: false,

          disclaimer: '',
          privacyoverview: '',
          necssarycookies: '',
          performancecookies: '',

          termsofuse: '',
          privacy: '',
        }
    },

  methods: {
    dateTime(value) {
      return moment(value).format('YYYY-MMMM-DD');
      },

      toggleCheckbox() {
        this.checkbox = !this.checkbox
        let gettheme = document.getElementById('checkifcheck');
      if(gettheme.checked === true) {
        localStorage.setItem('colorTheme', 'darkTheme');
        this.$router.go(0)
      }else{
        localStorage.setItem('colorTheme', 'lightTheme');
        this.$router.go(0)
      }
    },

    setBgcolor(){
      document.body.style.backgroundColor = '#0000000';
    },


    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll animation
      });
    },
    getPrivacyOverview($event) {
      this.showprivacyoverview = true
      this.showstrickcookies = false
      this.showperfcookies = false
      this.showcookiepolicy = false
    },
    getStricklyCookies() {
      this.showstrickcookies = true
      this.showprivacyoverview = false
      this.showperfcookies = false
      this.showcookiepolicy = false
    },
    getPerformanceCookies() {
      this.showprivacyoverview = false
      this.showstrickcookies = false
      this.showperfcookies = true
      this.showcookiepolicy = false
    },
    getCookiePolicy() {
      this.showprivacyoverview = false
      this.showstrickcookies = false
      this.showperfcookies = false
      this.showcookiepolicy = true
    }
   
    },
 

  async mounted() {
    this.setBgcolor();
    
    let gettheme = document.getElementById('checkifcheck');
    this.colortheme = localStorage.getItem('colorTheme')
    if(this.colortheme === 'darkTheme') {
      gettheme.checked = true
    }else {
      gettheme.checked = false
    }


    await axios.get('getfooterabout/').then(
    response => {
        this.footerabout = response.data;
        this.conttitlepath = response.data[0].conttitlepath
        this.abshortdesc = response.data[0].abshortdesc
        this.abtext = response.data[0].abtext
        this.ablinktext = response.data[0].ablinktext
        this.ablinkpath = response.data[0].ablinkpath
        this.abtitletext = response.data[0].abtitletext
        this.abtitlepath = response.data[0].abtitlepath
        this.acttitletext = response.data[0].acttitletext
        this.acttitlepath = response.data[0].acttitlepath
        this.abbgcolor = response.data[0].abbgcolor
        
    })
    .catch((error) => {
    console.log(error)
    })

    await axios.get('getfootercategories/').then(
    response => {
        this.footercategories = response.data;
    })
    .catch((error) => {
    console.log(error)
    })

    await axios.get('getfooterlinks/').then(
    response => {
        this.footerlinks = response.data;
    })
    .catch((error) => {
    console.log(error)
    })


    await axios.get('gettermsofservice/').then(
    response => {
        this.privacyoverview = response.data[0].nbprivacyoverview
        this.necssarycookies = response.data[0].nbstricknecessarycookies
        this.performancecookies = response.data[0].nbperformancecookies
        this.disclaimer = response.data[0].nbdisclaimer
        this.termsofuse = response.data[0].nbtermofservice
        this.privacy = response.data[0].nbprivacypolicy

    })
    .catch((error) => {
    console.log(error)
    })


    
     
  }
  }
</script>
<style lang="scss" scoped >
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
body {
  background-color: WHITE;
  width:100%;
  height:100vw;
  padding:0;
  margin:0;
}
.linkfm {
  list-style-type: none;
  color: white;
  text-decoration: none;
}
img {
  object-fit: cover;
}
.footer{
  font-size: 17px;
  opacity: 0.99;
  bottom: 0;
  padding-bottom: 0;
  position: relative;
  
  line-height: 34px;

  
}
.footerdata {
  text-align: left;
  font-size: 14px;
}

.footerdata .rlinkcss {
  color: white;
}
.footercaption{
  text-align: left;
  margin-left: 30px;
  font-size: 16px;
}
.indenttextlinksft {
  color: white;
}

ul {
  list-style: none;
}

.faiconscss{
  font-size: 30px;
  padding-left: 5px;
  transition: all 0.2s ease-in-out;
  border: solid thin #003;
  border-radius: 5px;
}
ul li {
  padding-top: 10px;
}

.lastfooter {

 /*background-color: black;*/
 height: auto;
 margin-bottom: -100px;
}
.formatinnerfooter {
  padding-top: 25px;
}
.imglogo {
  margin-left: -14px;
  width: 200px;
  opacity: 09;
}
.indenttextlinksft {
  text-decoration: none;
}
.tmuse {
  cursor: pointer;
}

.btn-close {
  background-color: white;
}
.linka {
  color: white;
  font-size: 18px;
}
.copyright {
  font-size: 12px;
  padding-top: 5px;
}
.formatul .formatli {
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
}
.allformat {
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
}
.listit .linka {
  text-decoration: none; 
  font-size: 12px; 
  line-height: 1; 
  padding-left: 0pt;
}
.formatactivities {
  text-align: left;
  line-height: 1;
}
.formatactivities a {
  text-decoration: none;
  font-size: 14px;
  line-height: 1;
}
.formatitle {
  font-size: 18px;
}

/*-------------BUTTON SWITCH*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 30px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #464242;
}

input:focus + .slider {
  box-shadow: 0 0 1px #464242;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.tabsformat {
  float:left; 
  width: 100%; 
  padding-top: 15px; 
  padding-bottom: 15px; 
  background-color: whitesmoke; 
  text-align: center;
  margin-top: 30px;
}
.tabsformat:hover {
  cursor: pointer;
  background-color: skyblue;
  padding:15px;
}

.btnclosecookies {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: -50px;

}
.fa-circle-xmark {
  color: rgb(85, 85, 110);
  font-size: 40px;
}

.fa-circle-xmark:hover {
  color: orangered;

}
.formatlinks:hover {
  color: orangered;

}
.formatlinks {
  color: rgb(70, 66, 66);
  line-height: 4;
}
@media only screen and (max-width: 600px) {
  ul {
    margin-left: 10px;
  }
  .modalclass {
    height:380px;
  }

  .imglogo {
    margin-left: 10px;
  }
  .abtitleformat {
    margin-bottom: 50px;
  }
  .abactivitiesformat {
    margin-bottom: 50px;
  }
  .joinregisterformat {
    margin-top: 100px;
  }

  .ulelements {
    text-align: center;
    width: 100%;
  }
  .copyright{
    text-align: center;
  }
  .btnclosecookies {
    margin-left: 370px;
    margin-top: -40px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .btnclosecookies:hover {
    width: 50px;
    height: 50px;
  }
  .col-md-4 {
    padding-top: 0px; 
    padding-left: 0px;
    width: 30%;
  }
  .col-md-8 {
    padding-top: 0px; 
    padding-left: 0px;
    width: 70%;
  }
  .privacymodal {
    margin-top: 40px;
  }
  .termsofusemodal {
    margin-top: 40px;
  }
  .privacyfmt, .termsofusefmt {
    padding: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .modalclass {
    height:310px;
  }

  .imglogo {
    margin-left: 15px;
  }
  .abtitleformat {
    margin-bottom: 50px;
  }
  .abactivitiesformat {
    margin-bottom: 50px;
  }
  .joinregisterformat {
    margin-top: 100px;
  }
  .btnclosecookies {
    margin-left: 440px;
    margin-top: -40px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .btnclosecookies:hover {
    width: 50px;
    height: 50px;
  }
  .col-md-4 {
    padding-top: 5px; 
    padding-left: 5px;
    width: 30%;
  }
  .col-md-8 {
    padding-top: 10px; 
    padding-left: 10px;
    width: 70%;
  }
  .privacymodal {
    margin-top: 40px;
    padding: 30px;
  }
  .termsofusemodal {
    margin-top: 40px;
    padding: 30px;
  }
  .privacyfmt, .termsofusefmt {
    padding: 30px;
  }

  }
@media only screen and (min-width: 768px) {
  .modalclass {
    height:250px;
  }

  footer {
    min-height: 100vh;
  }

  }
@media only screen and (max-width: 992px) {
.modalclass {
  height:180px;
}


}
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .modalclass {
    height:220px;
  }
  .btnclosecookies {
    margin-left: 1090px;
    margin-top: -40px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .btnclosecookies:hover {
    width: 50px;
    height: 50px;
  }

}
 /* Extra large devices (large laptops and desktops, 1400px and up) */
 @media only screen and (min-width: 1400px) {

 }
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1600px) {

 }
</style>
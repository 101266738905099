<template>
<div :style="`${newsbgcolor};`" class="newscontainer">
    <div class="container">
        <div class="row">
            <div class="toplistformat" >
                <ul style="list-style-type: none; display: flex; ">
                    <li>
                        <router-link :to="{path: '/en/blogs-articles-&-news/'}" class="formatlinksn"  @click.prevent="$emit('getclickedId', $event.target.value='getAll')"
                         v-if="lang === 'en'" v-show="lang==='en'"
                        >
                            <font-awesome-icon icon="fa-solid fa-circle-dot" style="padding-right: 2px; color: skyblue;" />
                            All categories
                        </router-link>
                        <router-link :to="{path: '/fr/blogs-articles-et-nouvelles/'}" class="formatlinksn"  @click.prevent="$emit('getclickedId', $event.target.value='getAll')"
                         v-if="lang === 'fr'" v-show="lang==='fr'"
                        >
                            <font-awesome-icon icon="fa-solid fa-circle-dot" style="padding-right: 2px; color: skyblue;" />
                            All categories
                        </router-link>
                    </li>
                    <li v-for="xcat in newscategory" style="margin-left: 20px; ">
                        <font-awesome-icon icon="fa-solid fa-circle-dot" style="padding-right: 2px; color: skyblue;" />
                        <!-- <router-link class="formatlinksn">
                            {{ xcat.newscatdesc}}
                        </router-link> -->
                        <span v-if="lang === 'en'" v-show="lang==='en'">
                            <router-link :to="{path: `/en/blogs-articles-&-news/${xcat.newscatid}`}" class="formatlinksn"  @click.prevent="$emit('getclickedId', $event.target.value=`${xcat.newscatid}`)">
                                {{ xcat.newscatdesc}}
                            </router-link>
                            </span>
                            <span v-else" v-show="lang==='fr'">
                                <router-link :to="{path: `/fr/blogs-articles-et-l-nouvelles/${xcat.newscatid}`}" class="formatlinksn" @click.prevent="$emit('getclickedId', $event.target.value=`${xcat.newscatid}`)">
                                    {{ xcat.newscatdesc}}
                                </router-link>
                            </span>
                    </li>
                </ul>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 cardformat" v-for="nnews in newsdata" :style="newsbgcolor">   
                    <div class="card h-100" >
                        <div>
                            <img :src="`${defaultURL}${nnews.newsimage}`" class="card-img-top" alt="">
                            <div class="card-body">
                            <span v-if="lang === 'en'" v-show="lang==='en'">
                                <router-link :to="{path: `${nnews.newsimagepath}${nnews.newsid}`}">
                                    <h5 class="card-title">{{nnews.newstitle}}</h5>
                                </router-link>
                            </span>

                            <span v-else v-show="lang==='fr'">
                                <router-link :to="{path: `${nnews.newsimagepath}${nnews.newsid}`}" >
                                    <h5 class="card-title">{{nnews.newstitle}}</h5>
                                </router-link>
                            </span>
                            <hr />
                            <div v-for="ncat in newscategory">
                                <div class="card-text" v-if="ncat.newscatid === nnews.newscatref" style="bottom: 0;" >
                                    <span v-if="lang === 'en'" v-show="lang==='en'">
                                    posted under 
                                    <router-link :to="{path: `/en/blogs-articles-&-news/${ncat.newscatid}`}" @click.prevent="$emit('getclickedId', $event.target.value=`${ncat.newscatid}`)">
                                        {{ ncat.newscatdesc}}
                                    </router-link>
                                    </span>
                                    <span v-else" v-show="lang==='fr'">
                                        posted under 
                                        <router-link :to="{path: `/fr/blogs-articles-et-l-nouvelles/${ncat.newscatid}`}" @click.prevent="$emit('getclickedId', $event.target.value=`${ncat.newscatid}`)">
                                            {{ ncat.newscatdesc}}
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
require('@/defaultPath')

export default {
    props: ['newsdata', 'newscategory', 'newsbgcolor',],
    data() {
        return {
            defaultURL: defaultPath,
            lang: localStorage.getItem('lang'),
            colortheme: localStorage.getItem('colorTheme'),
        }
    },

methods: {
    getclickedId($event) {

    this.$emit('getclickedId', $event.target.value)
    },
}
}
</script>


<style scoped>
.cardformat {
    margin-top: 20px;
    margin-bottom: 20px;

}
.cardsectionformat {
    padding-top: 50px;
    padding-bottom: 50px;
}
.newscontainer {
    padding-top: 100px; 
    padding-bottom: 100px;
    padding-left: 180px;
    padding-right: 180px;
}
.formatlinksn {
    text-decoration: none;
    font-size: 18px;
}
.toplistformat {
    margin-left: -30px;
}
.card-img-top {
    object-fit: cover;
}
@media only screen and (max-width: 600px) {
.col-md-4 {
    width: 100%;
}   
.newscontainer {
    padding-top: 100px; 
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;
}
}
@media only screen and (min-width: 600px) {
    .newscontainer {
        padding-top: 100px; 
        padding-bottom: 100px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (min-width:769px) {
    .col-md-4 {
        width: 50%;
    }  
}

@media only screen and (min-width: 992px) {
    .col-md-4 {
        width: 33%;
    } 
}
@media only screen and (min-width: 1200px) {
    .col-md-4 {
        width: 25%;
    } 
    .newscontainer {
        padding-top: 100px; 
        padding-bottom: 100px;
        padding-left: 130px;
        padding-right: 130px;
    }
}
@media only screen and (min-width: 1400px) {
    .col-md-4 {
        width: 25%;
    } 
    .newscontainer {
        padding-top: 100px; 
        padding-bottom: 100px;
        padding-left: 150px;
        padding-right: 130px;
    }
}
@media only screen and (min-width: 1600px) {
    .col-md-4 {
        width: 25%;
    } 
    .newscontainer .container {
        padding-top: 0px; 
        padding-bottom: 100px;
        padding-left: 50px;
        padding-right: 50px;
    }
}
</style>
<template>
    <!-- ======= SectionTwo Begins here =======  :pagination="{clickable: true}"-->
      <div class="container " data-aos="fade-up">
        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist" >
          <li class="nav-item" role="presentation" v-for="(allcode, index) in allcodes" :key="oportid" :getfirstid="index[0]">
            <button class="nav-link" :class="{active: index === 0}" :id="allcode.oportid" data-bs-toggle="tab" :data-bs-target="`#tab${allcode.oportid}`" 
             type="button" role="tab" aria-controls="home" aria-selected="true" @click="$emit('getId', $event.target.value=allcode.oportid)">{{allcode.shortdesc}}</button></li>
           
        </ul>
        <div class="tab-content" id="myTabContent" v-for="(allcodeid, index1) in allcodes" :key="allcodeid">
          <div class="tab-pane fade show" :class="{active: index1 === 0}"  :id="`tab${allcodeid.oportid}`" role="tabpanel" :aria-labelledby="allcodeid.oportid">
            <div class="row row-cols-1 row-cols-md-4 g-4">
              <div class="col cardformat parent"  style="position: relative;">
                 
              </div>
            </div>

            <!-- Show this div if the click index is greater than one -->
            <div class="textcolor" v-html="getdtsInfo" v-if="index1 > 0">
            </div>
            <!-- Only show this element if the clicked index is equal to zero -->
            <div class="textcolor"  v-html="getfirstid" v-else>
                
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import GeneralModal from '@/components/GeneralModal.vue';
require('@/globalDeclare.js');

export default {
  props: ['allcodes', 'allcodedetails', 'getfirstid', 'getdtsInfo'],
  components: {GeneralModal},
data() {
return {
  myPicUrl: ''
}
},
methods: {
getId($event) {
    // id: $event.target.value
    // this.$emit('getLinkdts', $event.target.value)
    this.$emit('getdtsId', $event.target.value)
},
getId2($event) {
    // id: $event.target.value
    this.$emit('getdtsId1', $event.target.value)
},
// getId3($event) {
//     // id: $event.target.value
//     this.$emit('handleClick', true)
// },
},
mounted() {
this.myPicUrl = myUrlPath

}
}
</script>

<style scoped>
.sectioncontrol{
opacity: .9;
height: auto;
width: 100%;
}
.sectiontwo {
margin-bottom: 100px;
/*background-image: url("../assets/slide-5.jpg");*/
position: relative; 
border-radius: 5px;
opacity: .9;

}
.iconsize {
width: 100px;
}
.empnameformat{
  color: rgb(44, 102, 177);
  font-size: 18px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  margin: 0 auto;
}
.emptitleformat{
  color: maroon;
  font-size: 14px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  margin: 0 auto;
}
.cardref{
text-decoration: none;
}
.card-body {
  text-align: center;
}

.titlelinkformat {
  text-decoration: none;
}
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 4px solid #40A758;
  font-weight: bold;
  color: orangered;
 }

  .nav-link {
  font-weight: bold;
  color: rgb(241, 50, 50);
 }
 .textcolor {
    color: rgb(207, 203, 203);
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
 }
 
/*===================MEDIA SCREENS TAMPLATES===============*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

 .card-title{
  width: 100%;
  font-size: 18px;
 }


 .sectiontwo {
  width: 94%;
 }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 800px) {
  .card-title {
    font-size: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
  .sectioncontrol {
    border-radius: 5px;
  }

} 


</style>
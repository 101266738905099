<template>
  <div class="autocomplete">
    <input
      class="form-control form-control-lg txtsearch"
      type="text"
      placeholder="Please start typing...."
      aria-label="Please start typing...."
      id="cleartxt"
      v-model="search"
      @input="onChange"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @click="clearTxt"
    />

    <ul v-show="isOpen" class="autocomplete-results">
      <li v-if="isLoading" class="loading">Loading results...</li>
      <li v-else-if="errorMessage" class="error">{{ errorMessage }}</li>
      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter, ['li' + (i % 2)]: true }"
        @click="setResult(result.spagedesc)"
      >
        <router-link :to="{ path: result.spagelinkaddress }" style="text-decoration: none;">
          <div>
            <p  data-bs-dismiss="modal" v-html="result.spagekeywords"></p>
          </div>
        </router-link>
      </li>
    </ul>

    <div class="item error" v-if="search && !results.length && !isLoading">
      <span>No results found!</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "SearchAutocomplete",
  props: {
    isAsync: {
      type: Boolean,
      required: false,
      default: true, // Set to true to use dynamic API fetching
    },
  },
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      isLoading: false,
      arrowCounter: -1,
      errorMessage: "",
    };
  },
  methods: {
    clearTxt(){
      this.search = ""
      this.results = [],
      this.errorMessage = ""
      let el = document.getElementById('cleartxt');
      el.value = "";
    },
    onChange: _.debounce(function () {
      if (!this.search.trim()) {
        this.results = [];
        this.isOpen = false;
        return;
      }

      if (this.isAsync) {
        this.isLoading = true;
        this.errorMessage = "";

        // Make an API call to get search results
    axios
      .get(`getsearchpages/${this.search}`)
      .then((response) => {
        this.results = response.data
        console.log(response)
        this.isOpen = this.results.length > 0;
        this.isLoading = false;
      })
      .catch((error) => {
            this.errorMessage = "Error fetching results. Please try again.";
            console.error("API error:", error);
            this.isLoading = false;
          });
      } 
    }, 300), // 300ms debounce delay

    setResult(result) {
      this.search = result;
      this.isOpen = false;
    },

    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter++;
      }
    },

    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter--;
      }
    },

    onEnter() {
      if (this.arrowCounter !== -1) {
        this.setResult(this.results[this.arrowCounter].spshortsearchdesc);
      }
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },

  },

  mounted() {


    document.addEventListener("click", this.handleClickOutside);
  },

  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.item {
  width: 350px;
  margin: 0 auto 10px auto;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
}

.error {
  padding-top: 40px;
  color: orangered;
  width: 100%;
  text-align: center;
}

.autocomplete {
  position: relative;
  z-index: 1000;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: auto;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #f8f5f4;
  color: white;
}

.li1 {
  background-color: whitesmoke;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #003;
}

.li0 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.presult {
  font-weight: bold;
  font-size: 18px;
  color: maroon;
}

.loading {
  padding: 10px;
  font-style: italic;
  color: whitesmoke;
}
</style>

<template>
    <!-- ======= SectionTwo Begins here =======  :pagination="{clickable: true}"-->

      <div class="sectioncontrol" data-aos="fade-up">
        <ul class="nav nav-tabs justify-content-left w-100" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" v-for="(leadcode, index) in leadershipcodes" :key="leadcode">
            <button class="nav-link" :class="{active: index === 0}" :id="leadcode.ldid" data-bs-toggle="tab" :data-bs-target="`#tab${leadcode.ldid}`" 
             type="button" role="tab" aria-controls="home" aria-selected="true" @click="$emit('getId', $event.target.value=leadcode.ldid)">{{leadcode.desc}}</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent" v-for="(leadcodeid, index1) in leadershipcodes" :key="leadcodeid">
          <div class="tab-pane fade show" :class="{active: index1 === 0}"  :id="`tab${leadcodeid.ldid}`" role="tabpanel" :aria-labelledby="leadcodeid.ldid">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-4 cardformat parent" v-for="profile in leadershipprofiles" style="position: relative;">
                <div class="card h-100 shadow">
                  <a class="titlelinkformat" @click="$emit('getId2', $event.target.value=profile.detid)">
                    <img :src="`${myPicUrl}${profile.empphoto}`" class="card-img-top " alt="">
                    <div class="card-body">
                      <h5 class="card-title"><span class="empnameformat">{{profile.empname}}</span></h5>
                      <h5 class="card-title"><span class="emptitleformat">{{profile.emptitle}}</span></h5> <GeneralModal :visible="false" variant="success" :getprofileInfo="getprofileInfo"/>
                     
                    </div>
                  </a>
                </div>
                <!-- <div class="childwidth1" v-if="profile.detid === getlinkProfileId">
                    {{ profile.detid }}              
                </div> -->
              </div>
            </div>
          
          </div>
        </div>
      </div>

</template>

<script>
import GeneralModal from '@/components/GeneralModal.vue';
require('@/globalDeclare.js');

export default {
  props: ['leadershipcodes', 'leadershipprofiles', 'getlinkProfileId', 'getprofileInfo'],
  components: {GeneralModal},
data() {
return {
  myPicUrl: ''
}
},
methods: {
getId($event) {
    // id: $event.target.value
    this.$emit('getLinkProfile', $event.target.value)
    this.$emit('getProfileId', $event.target.value)
},
getId2($event) {
    // id: $event.target.value
    this.$emit('getProfileId', $event.target.value)
},
// getId3($event) {
//     // id: $event.target.value
//     this.$emit('handleClick', true)
// },
},
mounted() {
this.myPicUrl = myUrlPath

}
}
</script>

<style scoped>
.sectioncontrol{
opacity: .9;

height: auto;
width: 100%;
}
.sectiontwo {
margin-bottom: 100px;
background-color: #f9ebea;


}
.iconsize {
width: 100px;
}
.empnameformat{
  color: rgb(44, 102, 177);
  font-size: 18px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  margin: 0 auto;
}
.emptitleformat{
  color: maroon;
  font-size: 14px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  margin: 0 auto;
}
.cardref{
text-decoration: none;
}
.card-body {
  text-align: center;
}
.cardformat {
  margin-top: 80px;
}
.titlelinkformat {
  text-decoration: none;
}
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 4px solid #40A758;
  font-weight: bold;
  color: orangered
 }

  .nav-link {
  font-weight: bold;
  color: rgb(44, 102, 177);
 }
 img {
  object-fit: cover;
 }
/*===================MEDIA SCREENS TAMPLATES===============*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.sectiontwo {
  margin-bottom: 100px;
  background-color: #f9ebea;
  margin-top: 100px;
 }
 .sectiontwopurpose{
  margin-top: -30px;
 }
 .card-title{
  width: 100%;
  font-size: 18px;
 }
 .col-md-4 .card {
  width: 100%;
 }
 .ourmgmtteam {
  margin-top: 200px;
 }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-md-4 .card {
    width: 100%;
   }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-md-4 .card {
    width: 100%;
   }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 800px) {
  .card-title {
    font-size: 10px;
  }
  .ourmgmtteam {
    margin-top: 200px;
   }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
} 
</style>
<template>
  <div class="container">
    <div class="formatpaginatordiv">
      <ul >
        <li>
          <div class="searchformat">
            <div>{{ $t('Search major category') }}</div>
            <div style="padding-top: 10px;" data-bs-placement="top" title="Select category">
              <select id="first-select" v-model="selectedMCategory" @change="getMCat()" >
                <option value="">{{ $t('All categories') }}</option>
                <option v-for="mcat in majorcategories" :key="mcat.artcatid" :value="mcat.artcatid">
                  {{ mcat.artcatname }}
                </option>
              </select>
            </div>
          </div>
        </li>
        
       <li>
        <div class="searchformat">
          <div >{{ $t('Category') }}</div>  
         
          <div style="padding-top: 10px;" data-bs-placement="top" title="Select category">
            <select id="getvalel" v-model="selectedCategory" @change.prevent="getFetchData(); getSelectedVal();" :disabled="!selectedCategory.length"
            
            >
              <option value="" 
               
               >{{ $t('Category items') }}</option>
              <option v-for="category in selectedCategory" :key="category.artmcatid" :value="category.artmcatid"
          
              >
                {{ category.artmcatname }}
              </option>
            </select>
          </div>
          <div :style="colortheme === 'darkTheme' ? 'color: whitesmoke;' : 
            'color: #003; width: auto; float: left; padding-top: 20px; padding-bottom: 30px; font-weight: bold; font-size: 18px;'">
            {{ getdescription }} : {{ $t('Total items found') }} : {{ itemcounts }}
          </div>
        </div>
       </li>
      </ul>
      
    </div>
    
        

    <div class="container">
      <div class="row" >
        <div class="col-xs-12 col-sm-12 col-md-3" v-for="article in articles" :key="article.artid">
          <div v-for="zcat in getallcat">
            <div class="card "  v-if="article.artcatdfkid === zcat.artmcatid">
              <router-link :to="{path: `/en/articles/read-article/${article.artid}`}" v-show="lang === 'en'"  class="fomatlink">
                <img :src="`${myImgUrl}${article.artpic}`" class="card-img-top" alt="">
              </router-link>
              <router-link :to="{path: `/fr/articles/lire-l-article/${article.artid}`}" v-show="lang === 'fr'"  class="fomatlink">
                <img :src="`${myImgUrl}${article.artpic}`" class="card-img-top" alt="">
              </router-link>
       
              <div class="card-body">
                <router-link :to="{path: `/en/articles/read-article/${article.artid}`}" v-show="lang === 'en'"  class="fomatlink">
                  <h5 class="card-title">{{article.artdesc}}</h5>
                    <h6 class="card-title">{{article.artauthor}}</h6>
                    <h7 class="card-title">{{ dateTime(article.artdate) }}</h7>
                </router-link>

                <router-link :to="{path: `/fr/articles/lire-l-article/${article.artid}`}" v-show="lang === 'fr'" class="fomatlink">
                  <h5 class="card-title">{{article.artdesc}}</h5>
                  <h6 class="card-title">{{article.artauthor}}</h6>
                  <h7 class="card-title">{{ dateTime(article.artdate) }}</h7>
                </router-link>
                <h6 class="card-title cardtitle formatlink" :style="colortheme === 'darkTheme' ? 'color: whitesmoke;' : 'color: maroon;'">
                  <b>{{ $t('Posted under')}}:</b> <i>{{ zcat.artmcatname }}</i>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     

    <input type="hidden" v-model="selectedtext" id="gettxtVal"/>
    <div class="pagingationdiv">
      <div class="navdiv">
        <nav arial-laeble="Page navigation">
          <ul>
            <li>
              <div>{{ $t('Results')}}: <b>{{mytotalitems}} </b> {{ $t('items')}}
                <span style=" margin-left: 20px;">
                  <select @change="getSelectedOption($event)" data-bs-toggle="tooltip" data-bs-placement="top" title="Set page size">
                    <option value="" selected disable>{{ $t('Page size')}}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="6">6</option>
                    <option value="9">9</option>
                    <option value="12">12</option>
                    <option value="27">27</option>
                  </select>
                </span>
              </div>

            </li>
          </ul>
          <ul class="ulformat">
            <li class="page-item">
                <a class="page-link innerpagelink" href="#" @click.prevent="prevPage" :disabled="!pagination.previous">
                  <font-awesome-icon :icon="['fas', 'angles-left']" />
                  </a>
            </li>
          
            <!-- -pagination ----->
            <li class="page-item">
              <div id="myDIV">
                <a class="page-link innerpagelink extlength">{{ currentPage }} of {{totalPages}}</a>
              </div>
            </li>
          
            <!--  Next Button-->
            <li class="page-item">
              <a class="page-link innerpagelink" href="#" @click.prevent="nextPage" :disabled="!pagination.next">
                <font-awesome-icon :icon="['fas', 'angles-right']" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- Pagination Controls -->
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import '../globalDeclare';
  import moment from 'moment';

  export default {
    props: ['colortheme'],
    data() {
      return {
        myImgUrl: myUrlPath,
      
        pagination: {
          next: null,                // URL for next page
          previous: null,            // URL for previous page
        },
        currentPage: 0,              // Tracks current page number
        searchQuery: '',             // Holds search term
        articles: [],                // List of articles from API
        selectedCategory: [],        // Selected category ID for filtering
        selectedMCategory: '',       //Initialize teh selectedMCategory (major cat)
        categories: [],              // List of available categories
        majorcategories: [],         // list all major categories
        totalPages: 0,               // Initialize total number of pages
        pageSize: 10,                // set page size 
        getselectedval: '',
        getallcat: [],
        selectedtext: '',
        getdescription: '',
        itemcounts: 0,
        lang: localStorage.getItem('lang'),
        colortheme: localStorage.getItem('colorTheme')

      };
    },
    methods: {
      dateTime(value) {
        return moment(value).format('YYYY-MMMM-DD');
      },

      //this is the user customize pagination settings. get the selected value and call the fetchdata function.
      getSelectedOption($event){
        this.selval = $event.target.value
        this.itemPerpage = this.selval

        this.fetchData();
      },

   
  
      // query or code block to fetch data from our article model
      async fetchData(page = 1) {
        // determine if itemPerpage is set. if not set, assign the default page size. if set, use the user settings.
        try {
          if(this.itemPerpage === undefined) {
            this.pageSize = 10
          }else{
            this.pageSize = this.itemPerpage
          }

          // perform the pagination passing the pageSize and selectedCategory parameters
          const response = await axios.get('getarticlepaginated/', {
            params: {
              page: page,
              page_size: this.pageSize,
              artcatdfkid: this.selectedCategory,     // Category filter
            },
          });
  
          // Update articles and pagination info
          this.articles = response.data.results;
          this.pagination.next = response.data.next;
          this.pagination.previous = response.data.previous;
          this.itemcounts = response.data.count;
          this.currentPage = page;

          this.totalPages = Math.ceil(response.data.count / this.pageSize);

          if(this.totalPages === 0 ){
            this.currentPage = 0
          }
       
        } catch (error) {
          console.error('Error fetching articles:', error);
        }
      },

      //after major selection, call the fetchdata function. also p
      getFetchData() {
        this.fetchData(); //call the function to get the recordsets from articles.
        this.getMCat();   //call the function to populate the second select
        // this.getSelectedVal();
      },
      nextPage() {
        if (this.pagination.next) {
          this.fetchData(this.currentPage + 1);
        }
      },
      prevPage() {
        if (this.pagination.previous) {
          this.fetchData(this.currentPage - 1);
        }
      },

      //retreive second category after major category is selected
      async fetchFilters() {
        try {
          // Fetch available categories and authors
          const categoriesResponse = await axios.get('getarticlecatsecond/');
          this.selectedCategory = categoriesResponse.data;
          this.getSelectedVal();
        } catch (error) {
          console.error('Error fetching filters:', error);
        }
      },

      //populate the major category select
      async fetchMajorCatFilters() {
        try {
          // Fetch available categories and authors
          const majorcategoriesResponse = await axios.get('getarticlecat/');
          this.majorcategories = majorcategoriesResponse.data;
        } catch (error) {
          console.error('Error fetching filters:', error);
        }
      },


      // get the major category selected option
      getMCat() {
        axios.get(`getarticlecatsecondunique/${this.selectedMCategory}`)
          .then(response => {
            this.selectedCategory = response.data; // Populate the items for the selected category
            // this.selectedCategory = null; // Reset selected item
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      },
  
     async getSelectedVal() {
      

      this.selectedtext = this.selectedCategory;

      await axios.get(`getarticlesecondcatidname/${this.selectedtext}`)
        .then(response => {
          this.getdescription = response.data[0].artmcatname
        
        })
        .catch(error => {
          console.error('Error fetching items:', error);
        });
      },
    
     async getDesc(){
    
         
     },
    },
   async mounted() {
      //initialize the functions onload
    
      this.fetchData();     // Fetch initial article list
      this.fetchFilters();  // Fetch categories and authors for filtering
      this.fetchMajorCatFilters(); //Fetch major categories


      await axios.get(`getarticlecatsecond/`)
        .then(response => {
          this.getallcat = response.data
          console.log(this.getallcat)
        })
        .catch(error => {
          console.error('Error fetching items:', error);
        });
      
       
     
    },
  };
  </script>

<style scoped>
.formatselect {
    padding-left: 30px;
    padding-top: 50px;
    padding-bottom: 100px;
}
ul {
  list-style-type: none;
  display: flex;
}

.container-fluid {
  padding-top: 100px;
  padding-bottom: 100px;

  }
  ul li {
  display: inline-block;
  }
  .pagingationdiv {
  
    float: right;
    width: 100%;
  
  }
  nav {
  
    width: 100%;
  }
  
  .innerpagelink {
 
  width: 50px;
  height: 32px;
  text-align: center;
  border: thin solid rgb(153, 153, 158);
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
  }
  .innerpagelink:hover {
    background-color: orange;
   
  }
  
  .innerpagelink:active {
    background-color: orangered;
    width: 60px;
    height: 35;
    font-weight: bold;
    font-size: 16px;
  }
  
  .previousbtn, .btnext {
    width: 80px;
    text-align: center;
  }
  
  .btn {
    border: none;
    outline: none;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 18px;
  }
  
  /* Style the active class, and buttons on mouse-over */
  .active, .btn:hover {
    background-color: #666;
    color: white;
  }
  .card {
  
    border-radius: 5px;
  }
 
  .card-body {
    background-color: white;
  }
  .card {
    border: solid thin white
  }
  .navdiv {
    float: right;
    padding-left: 30px;

    padding-top: 20px;
    padding-bottom: 10px;
  }

  .extlength {
    width: 100px;
  }
.searchformat {
  padding-bottom: 50px;
  padding-left: 20px;
}

img {
  height: 30vh;
  width: 100%;
}
img {
  object-fit: cover;
}
@media only screen and (max-width: 600px) {
ul {
  display: block;
}

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  ul {
    display: block;
  }

}



/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  ul {
    display: flex;
  }

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  ul {
    display: flex;
  }
 
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {

  }
</style>
<!-- 
  Explanation:

    Data Properties:
        articles: Stores the list of articles.
        pagination: Holds pagination URLs for next and previous.
        currentPage: Tracks the current page.
        searchQuery, selectedCategory, and selectedAuthor: Store search and filter values.
        categories and authors: List of available categories and authors for filtering.

    Methods:
        fetchData(page): Fetches paginated data based on the current page, search term, category, and author.
        nextPage and prevPage: Navigate between pages if the respective URLs are available.
        fetchFilters(): Fetches available categories and authors for filtering (assuming separate API endpoints for these).

    Template:
        Search input and dropdowns for filtering.
        Lists articles with pagination controls.

This component will retrieve paginated articles from the Django backend and apply filtering based on the search, category, and author. The pagination buttons enable navigation through pages based on the availability of next and previous links from the API. -->
<template>
<div class="imagescontainer shadow rounded">
    <div class="row">
        <!-- <div class="colcol-sm-12 col-md-4 col-lg-4">
            <img :src="`${abspath}${cmimage1}`" class="imageswidth rounded  shadow">
            <div class="formatbottomtext">Test div data</div>
        </div> -->
        <div style="width: 100%; text-align: center; padding-bottom: 30px;" v-html="toptext"></div>
        <div class="col-xs-12 col-sm-12 col-md-4 secright" data-aos="fade" :data-aos-duration="600" >
           
            <router-link :to="{path: imageonelink}">
                <img :src="`${abspath}${cmimage1}`" class="imageswidth image rounded  shadow" />
            
            <div class="middle">
                <div class="text" :style="colortheme === 'darkTheme' ? 'color: whitesmoke;  width: 100%;' : ' background-color: black; color: white; width: 100%;'">
                    {{timageonedesc}}
                </div>
            </div>
        </router-link> 
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 secright" data-aos="fade" data-aos-duration="700" >
            <router-link :to="{path: imagetwolink}">
                <img :src="`${abspath}${cmimage2}`" class="imageswidth image rounded  shadow" />
           
            <div class="middle">
                <div class="text" :style="colortheme === 'darkTheme' ? 'color: whitesmoke;  width: 100%;' : ' background-color: black; color: white; width: 100%;'">
                    {{timagetwodesc}}
                </div>
            </div>
        </router-link> 
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 secright" data-aos="fade" data-aos-duration="800" >
            <router-link :to="{path: imagethreelink}">
                <img :src="`${abspath}${cmimage3}`" class="imageswidth image rounded  shadow" />
            
            <div class="middle">
                <div class="text" :style="colortheme === 'darkTheme' ? 'color: whitesmoke;  width: 100%;' : ' background-color: black; color: white; width: 100%;'">
                    {{timagethreedesc}}
                </div>
            </div>
        </router-link>
    </div>
    </div>
</div>
</template>

<script>

export default {
    props: ['cmimage1', 'cmimage2', 'cmimage3', 'abspath', 'imageonelink', 'imagetwolink', 'imagethreelink',
        'timageonedesc', 'timagetwodesc', 'timagethreedesc', 'colortheme', 'toptext'
    ]
}
</script>e

<style scoped>
.imagescontainer {
    padding: 30px;
}
.formatbottomtext {
    margin-top: -50px; 
    color: white; 
    padding-left: 20px; 
    text-align: center; 
    font-size: 25px;
}
.formatbottomtext:hover {
    background-color: grey;
    cursor: pointer;
}
.imageswidth:hover{
    cursor: pointer;
}
.secright {
    position: relative;
    border-radius: 5px;
  }
  
  .image {
    opacity: 0.7;
    display: block;
    width: 100%;
    height: 30vh;
    transition: .5s ease;
    backface-visibility: hidden;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: relative;
    top: -14px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 5px;
  }
  
  .secright:hover .image {
    opacity: 0.3;
    background-color: #003;
    margin-top: -10px;
    cursor: pointer;
  }
 
  .secright {
    text-align: center;
  }
  
  .secright:hover .middle {
    opacity: 1;
    cursor: pointer;
  }
  .text {
 
    font-size: 16px;
    padding: 3px 3px;
    opacity: 0.6;
    font-size: 20px;
    font-weight: bold;
    padding-right: 50px;
    text-align: center;
  }
  .txtleftalign {
    text-align: justify;
    padding-right: 40px;
  }
/*===================MEDIA SCREENS TAMPLATES===============*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .iconsize {
      width: 60%;
      padding: 20px;
     }
     .col {
        min-width: 100%;
     }

     .imageswidth {
        width: 100%;
        margin-top: 40px;
        padding: 20px;
        border-radius: 5px;;
     }
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
    .iconsize {
        width: 60%;
        padding: 20px;
        }
    .col {
        min-width: 100%;
    }

    .imageswidth {
        width: 100%;
        margin-top: 40px;
        padding: 40px;
    }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .iconsize {
            width: 60%;
            padding: 20px;
            }
        .col {
            min-width: 33%;
        }
    
        .imageswidth {
            width: 100%;
            margin-top: 40px;
            padding: 0;
        }
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .iconsize {
            width: 60%;
            padding: 20px;
            }
        .col {
            min-width: 25%;
        }
    
        .imageswidth {
            width: 100%;
            margin-top: 40px;
            padding: 0;

        }
    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
    
    } 
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './axios/axios'
import axios from 'axios';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';

import './assets/js/sweetalert.js';
import 'aos/dist/aos.css';
import VueVideoPlayer from '@videojs-player/vue';
import 'video.js/dist/video-js.css';

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/css';

// or other themes
import '@splidejs/splide/css/skyblue';
import '@splidejs/splide/css/sea-green';

// or only core styles
import '@splidejs/splide/css/core';
import JwPagination from 'jw-vue-pagination';
import Vidle from 'v-idle-3'
import VueCountdown from '@chenfengyuan/vue-countdown';


import 'hls-player/dist/hls-player.css'
import hlsPlayer from 'hls-player'
import VueCarousel from 'vue-carousel';

import VueAnalytics from 'vue-analytics';


// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';
// import 'bootstrap-icons/font/bootstrap-icons.css';

// import { MdButton } from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'

AOS.init();

import SwiperClass, { /* swiper modules... */ } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import swiper module styles
import 'swiper/css'
// more module style...

// use swiper modules
SwiperClass.use([/* swiper modules... */])

import { createI18n } from 'vue-i18n';

const i18n =createI18n({
    locale: localStorage.getItem('lang') || 'en',
    messages: {
        en: en,
        fr: fr
    }
})
// import i18n from './i18n';

const lang = localStorage.getItem('lang') || 'en';
// axios.defaults.baseURL = `https://backend.relibridgeminerals.com/${lang}/api/`;
// axios.defaults.baseURL = `http://192.168.2.208:8000/${lang}/api/`;

// const lang = localStorage.getItem('lang')
const storedUserData = localStorage.getItem('user')
const userData = JSON.parse(storedUserData)
if (storedUserData != null) {
    // axios.defaults.baseURL = `http://192.168.2.208:8000/${lang}/api/`
    axios.defaults.baseURL = `https://nexgbitsacademy.ca/${lang}/api/`
    axios.defaults.headers.common = {Authorization: 'Bearer ' + userData.access}
}
else{
    // axios.defaults.baseURL = `http://192.168.2.208:8000/${lang}/api/`
    axios.defaults.baseURL = `https://nexgbitsacademy.ca/${lang}/api/`
}



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VideoBackground from 'vue-responsive-video-background-player'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


/* import specific icons */
import { faAddressCard, faBars, faCartShopping, faFilePen, faFire, faHouse, faHouseUser, faInfo, faKey, faRightToBracket, 
    faScrewdriverWrench, faSquareXmark, faUser, faUserSecret, faMagnifyingGlass, faAngleDoubleLeft, faAngleDoubleRight, faIdCard,
    faMoon, faSun, faCirclePlay, faClockRotateLeft, faRotateRight, faBook, faRightFromBracket, faChalkboardUser, faPenToSquare,
    faGlobe, faGear, faCircleXmark, faCircleChevronLeft, faCircleChevronRight, faEnvelope
  } from '@fortawesome/free-solid-svg-icons'


    /* add icons to the library */
    library.add(faUserSecret, faCartShopping, faUser, faBars, faHouseUser, faScrewdriverWrench, faFire, faInfo, faRightToBracket, 
    faAddressCard, faHouse, faKey, faSquareXmark, faMagnifyingGlass, faFilePen, faAngleDoubleLeft, faAngleDoubleRight, 
    faIdCard, faMoon, faSun, faCirclePlay, faClockRotateLeft, faRotateRight, faBook, faRightFromBracket, faChalkboardUser,
    faPenToSquare, faGlobe, faGear, faCircleXmark, faCircleChevronLeft, faCircleChevronRight, faEnvelope
  );

//*****
// Adding Icons 
//1. Get the icon from font-awesome (default-style)
//2. To import paste the icon part of the font using 'fa' at the beginning and capitalize the fist part of each word removing the hyphen.
//3. add the resulting font to the library by copying and pasting after the last comma.

// faBars, faHouseUser, faScrewdriverWrench, faFire, faInfo
// import { inject } from 'vue'


 
// const globalVar=inject('http://192.168.2.208:8000')
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'

const options = {
    // You can set your default options here
}; 



const myLang = {
    dashboard: {
      btn: {
        play: 'play',
        pause: 'Pause',
        fullscreen: 'Full screen',
        exitFullscreen: 'Exit full screen',
        mute: 'Mute',
        unmute: 'Unmute',
        back: 'Back',
        pip: 'piping',
      },
      settings: {
        autoplay: 'Autoplay',
        loop: 'looping',
        speed: 'Speed',
        resolution: 'Resolution',
      },
    },
    layers: {
      error: {
        title: 'Error!',
        2404: 'Video Source Undefined',
        2502: 'Media Network Error',
        2503: 'Video Cannot DECODE',
        2504: 'Video Cannot Play!',
        601: 'Live video Cannot Play!',
      },
      loading: {
        msg: 'Loading ...',
      },
    },
  };
createApp(App)


// Optionally install the BootstrapVue icon components plugin
// .use(IconsPlugin)
.component('font-awesome-icon', FontAwesomeIcon)
.component('video-background', VideoBackground)
.component('VueDatePicker', VueDatePicker)
.component('jw-pagination', JwPagination)
.component(VueCountdown.name, VueCountdown)



// .use(BootstrapVue)
// .use(IconsPlugin)
// .use(Vue3Material)
// .use(vue3videoPlay)

.use(i18n)
.use(Toast, options)
.use(store)
.use(router)
.use( VueSplide )
.use(Vue3VideoPlayer, {lang: myLang})
.use(VueAwesomeSwiper)
.use(VueVideoPlayer)
.use(VueCarousel)
// .use(VueAnalytics, {id: 'G-MY0NX8G6Q2'})// Replace with your Google Analytics tracking ID
.use(Vidle)
.use(hlsPlayer)
.mount('#app')

<template>
    <div class="container card shadow">
        <div class="row">
            <div class="col-xs-12 col-sm-12  col-md-7">
              <UpcomingEvents :eventdata="eventdata" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-5 rightside">
              <PresentationSection :presentdata="presentdata"/>
            </div>
        </div>
    </div>
</template>
<script>
import UpcomingEvents from '@/components/UpcomingEvents.vue';
import PresentationSection from '@/components/PresentationSection.vue';
require('@/globalDeclare.js');

export default {
props: ['eventdata', 'presentdata', 'presentationtheme'],
components: { UpcomingEvents, PresentationSection, }
}
</script>

<style scoped>
.presentsection {
    padding: 100px;
}
.card {
  padding: 50px;
}
img {
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
 .card {
  width: 100%;
 }
 .presentsection {
  padding: 10px;
}
.rightside {
  margin-top: 50px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card {
    width: 100%;
   }
   .presentsection {
    padding: 10px;
  }
  .rightside {
    margin-top: 50px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {

}

  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {

}
</style>
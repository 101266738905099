<template>
    <div class="presentdivcss">
      <div v-for="presdata in presentdata">
          <div class="texttitle" >{{ presdata.pictitle}}</div>
           <div><img :src="`${myPicUrl}${presdata.presentimage}`" class="imagewidth"/></div>

          <a :href="`${myPicUrl}${presdata.filepath}`" style="text-decoration: none;">
            <div class="downloadpdf">Download Pdf <i class="fa-solid fa-download"></i></div>
          </a>
      </div>
    </div>
</template>
<script>
require('@/globalDeclare.js');
export default {
  props: ['presentdata'],
data(){
  return {
    myPicUrl: '',
  }
},
mounted() {
  this.myPicUrl = myUrlPath
}
}
</script>
<style scoped>

.fa-download {
  font-size: 24px;
  padding: 20px;

}
.downloadpdf {
  text-align: right;
  color: maroon;
  font-weight: bold;
  font-size: 20px;
}

.imagewidth {
width: 100%;
padding: 20px;
}
.texttitle {
font-weight: bold;
font-size: 20px;
padding-left: 30px;
color: rgb(119, 142, 151);

}
img {
  object-fit: cover;
}
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {

}
</style>
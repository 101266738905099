<template>
  <div>
    <div class="buttondiv" style="float: right; padding-right: 100px;">
      <button @click="prev" class="prev rounded-circle">
        <i class="fa-solid fa-circle-chevron-left facircle"></i>
      </button>
      <button @click="next" class="next rounded-circle">
        <i class="fa-solid fa-circle-chevron-right facircle"></i>
      </button>
    </div>

    <div class="carousel">
      <div class="inner" ref="inner" :style="innerStyles">
        <!-- Cloned last item (appears before the first item) -->
        <div
          class="card  innerdiv imageformat zoom"
          v-if="SliderData.length"
          :key="'last-clone'"
          style="margin-left: 40px;"
        >
          <img :src="`${abspath}${SliderData[SliderData.length - 1].slideimagepath}`" class="imageformat" />
          <div>
            <h4>Testing inner data</h4>
            {{ SliderData[SliderData.length - 1].slidedesc }}
          </div>
        </div>

        <!-- Original slides -->
        <div
          class="card p-3 innerdiv imageformat zoom"
          v-for="(item, index) in SliderData"
          :key="index"
          style="margin-left: 40px;"
        >
        <router-link :to="{path: `${item.slidellink}`}" class="formatlinks" 
            :style="colortheme === 'darkTheme' ? 'color: white' : 'color: white'">
          <img :src="`${abspath}${item.slideimagepath}`" class="imageformat" style="width: 100%; "/>
          
            <div :style="colortheme === 'darkTheme' ? 'color: white; padding-top: 20px;' : 'color: white; padding-top: 20px;'">
              <h4>Testing inner data</h4>
              {{ item.slidedesc }}
            </div>
          </router-link>
        </div>

        <!-- Cloned first item (appears after the last item) -->
        <div
          class="card p-3 innerdiv imageformat zoom"
          v-if="SliderData.length"
          :key="'first-clone'"
          style="margin-left: 40px;"
        >
        <router-link :to="{path: `${SliderData[0].slidellink}`}" class="formatlinks" 
        :style="colortheme === 'darkTheme' ? 'color: white' : 'color: white'">
          <img :src="`${abspath}${SliderData[0].slideimagepath}`" class="imageformat" style="width: 100%; height: 40vh;" />
         
            <div :style="colortheme === 'darkTheme' ? 'color: white; padding-top: 20px;' : 'color: white; padding-top: 20px;'">
              <h4>Testing inner data</h4>
              {{ SliderData[0].slidedesc }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['SliderData', 'abspath', 'colortheme'],
  data() {
    return {
      innerStyles: {},
      step: 0,
      transitioning: false,
      currentIndex: 1, // Start at the first actual slide, accounting for the cloned last slide
    };
  },

  mounted() {
    this.setStep();
    this.resetTranslate();
  },

  methods: {
    setStep() {
      const cardWidth = 600; // Assuming card width
      this.step = cardWidth + 40; // Width + margin-left
    },

    next() {
      if (this.transitioning) return;
      this.transitioning = true;

      this.currentIndex += 1;
      this.moveLeft();

      this.afterTransition(() => {
        if (this.currentIndex === this.SliderData.length + 1 ) {
          // We've hit the cloned first item, reset to the real first item
          this.currentIndex = 1;
          this.resetTranslate();
        }
        // else if (this.currentIndex === this.SliderData.length) {
        //   this.currentIndex = 1
        // }
        this.transitioning = false;
      }
    
    );
    },

    prev() {
      if (this.transitioning) return;
      this.transitioning = true;

      this.currentIndex -= 1;
      this.moveRight();

      this.afterTransition(() => {
        if (this.currentIndex === 0) {
          // We've hit the cloned last item, reset to the real last item
          this.currentIndex = this.SliderData.length;
          this.resetTranslate();
        }
        this.transitioning = false;
      });
    },

    moveLeft() {
      this.innerStyles = {
        transform: `translateX(-${this.currentIndex * this.step}px)`,
        transition: 'transform 0.5s ease',
      };
    },

    moveRight() {
      this.innerStyles = {
        transform: `translateX(-${this.currentIndex * this.step}px)`,
        transition: 'transform 0.5s ease',
      };
    },

    afterTransition(callback) {
      const listener = () => {
        callback();
        this.$refs.inner.removeEventListener('transitionend', listener);
      };
      this.$refs.inner.addEventListener('transitionend', listener);
    },

    resetTranslate() {
      // Reset the position of the carousel without animation
      this.innerStyles = {
        transition: 'none', // No transition for instant reset
        transform: `translateX(-${this.currentIndex * this.step}px)`,
      };
    },
  },
};
</script>

<style scoped>
.carousel {
  width: 100%;
  overflow: hidden;
  padding-bottom: 40px;
}

.inner {
  transition: transform 0.5s ease;
  white-space: nowrap;

}

.card {
  width: 600px;
  height: 600px;
  margin-right: 10px;
  display: inline-flex;
  padding: 40px;
  background-color: #39b1bd;
  color: white;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

button {
  margin-right: 5px;
  margin-top: 10px;
}

.zoom {
  transition: transform .90s;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(.9);
}

.imageformat {
  width: 100%;
  border-radius: 5px;
  height: 400px;
}

.innerdiv {
  width: 400px;
  height: 500px;

}

.facircle {
  font-size: 30px;
  color: #003;
}

.prev,
.next {
  background-color: white;
  color: white;
}

.formatlinks {
  text-decoration: none;

}
img {
  object-fit: cover;
}
</style>

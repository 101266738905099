<template>
    <div :style="colortheme === 'darkTheme' ? darkstyle :  ''">
        <div  class="topdiv">
            <ul>
                <li>
                    <div  :style="colortheme === 'darkTheme' ? darkstyle :  'border-bottom: solid thin skyblue;'" class="shakehover">
                        <router-link :to="{path: '/en/who-we-are/about-us/'}" v-if="getlang === 'en'"
                            :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;'"
                        >
                            <div   :class="actionselected === 'aboutus' ? 'setred' : ''"
                             class="leftdiv" >
                                About Us
                                <font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                            </div>
                         </router-link>
                         <router-link :to="{path: '/fr/qui-nous-sommes/a-propos-de-nous/'}" v-if="getlang === 'fr'"
                            :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'"
                         >
                            <div   :class="actionselected === 'aboutus' ? 'setred' : ''"
                             class="leftdiv" >
                             {{$t("About Us")}}
                                <font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                            </div>
                         </router-link>
                    </div>
                </li>
                <li>
                    <div  :style="colortheme === 'darkTheme' ? darkstyle :  'border-bottom: solid thin skyblue;'" class="shakehover middepad">
                        
                            <router-link :to="{path: '/en/who-we-are/about-us/leadership/'}" v-if="getlang === 'en'"
                                :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'"
                            >
                                <div   :class="actionselected === 'meetourteam' ? 'setred' : ''" class="leftdiv">
                                    Leadership<font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                                </div>
                            </router-link>
                            <router-link :to="{path: '/fr/qui-nous-sommes/a-propos-de-nous/direction/'}" v-if="getlang === 'fr'"
                                :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'"
                            >
                                <div   :class="actionselected === 'meetourteam' ? 'setred' : ''" class="leftdiv">
                                    {{$t("Leadership")}}<font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                                </div>
                            </router-link>
                      
                    </div>
                </li>
                <li>
                    <div  :style="colortheme === 'darkTheme' ? darkstyle :  'border-bottom: solid thin skyblue;'" class="shakehover middepad">
                        
                            <router-link :to="{path: '/en/who-we-are/about-us/careers/'}" v-if="getlang === 'en'"
                                :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'"
                            >
                                <div class="leftdiv" :class="actionselected === 'careers' ? 'setred' : ''">
                                    Join our team<font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                                </div>
                            </router-link>
                            <router-link :to="{path: '/fr/qui-nous-sommes/a-propos-de-nous/carrieres/'}" v-if="getlang === 'fr'"
                                :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'"
                            >
                                <div class="leftdiv" :class="actionselected === 'careers' ? 'setred' : ''">
                                    {{$t("Join our team")}}<font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                                </div>
                            </router-link>
                        
                    </div>
                </li>
                <li>
                    <div  :style="colortheme === 'darkTheme' ? darkstyle :  'border-bottom: solid thin skyblue;'" class="shakehover">
                        
                            <router-link :to="{path: '/en/who-we-are/about-us/our-policies/'}" v-if="getlang === 'en'"
                            :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'" 
                            >
                                <div class="leftdiv" :class="actionselected === 'policies' ? 'setred' : ''" >
                                    Policies<font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                                </div>
                            </router-link>
                            <router-link :to="{path: '/fr/qui-nous-sommes/a-propos-de-nous/nos-politiques/'}" v-if="getlang === 'fr'"
                                :style="colortheme === 'darkTheme' ? `${darkstyle} color: white;` :  'border-bottom: solid thin skyblue; color: #003;;'"
                            >
                                <div class="leftdiv" :class="actionselected === 'policies' ? 'setred' : ''" >
                                    {{$t("Policies")}}<font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="arrowicons" />
                                </div>
                            </router-link>
                       
                    </div>
                </li>
            </ul>       
        </div>
    </div>
       
</template>
    
    <script>
    export default{
        props: ['allpages', 'colortheme', 'filtertext', 'pinfotext', 'pinfotextfirst', 'actionselected', 'getlang'],
        data(){
            return {
                // colortheme: localStorage.getItem('colorTheme'),
                
            }
        },
        
    methods: {
        // getEventId(pinfoid, filtertext) {
        // this.$emit('getaboutId', pinfoid, filtertext)
 
        // },
    },
    async mounted() {
           
    }
    }
    </script>
    
    <style scoped>

    .leftdiv:hover {
        color: skyblue;
        cursor: pointer;
    }
  
    .shakehover:hover {
       
        border-radius: 5px;
    }
    .setred {
        color: orangered;
    }
    .innertextright {
        padding-top: 50px; 

    }

      @media only screen and (max-width: 600px) {
        .innertextright {
            padding-top: 50px; 
   
        }
      
        ul {
            display: inline-flex;
            list-style-type: none;
        }
      
        .fa-circle-chevron-right {
            font-size: 16px;
            margin-left: 5px;
        }
        .middepad {
            margin-left: 18px;
            margin-right: 18px;
        }
      }
      @media only screen and (min-width: 600px) {
        .innertextright {
            padding-top: 50px; 
        }
     
      
        ul {
            display: inline-flex;
            list-style-type: none;
            padding-left: 100px;
        }
      
        .fa-circle-chevron-right {
            font-size: 16px;
            margin-left: 5px;
        }
        .middepad {
            margin-left: 25px;
            margin-right: 25px;
        }
      }
     /* Medium devices (landscape tablets, 768px and up) */
     @media only screen and (min-width: 768px) {
        .innertextright {
            padding-top: 50px; 
        }
     
      
        ul {
            display: inline-flex;
            list-style-type: none;
            padding-left: 100px;
        }
      
        .fa-circle-chevron-right {
            font-size: 16px;
            margin-left: 5px;
        }
        .middepad {
            margin-left: 35px;
            margin-right: 35px;
        }
     }
     
  
    
     /* Large devices (laptops/desktops, 992px and up) */
     @media only screen and (min-width: 992px) {
        .innertextright {
            padding-top: 50px; 
        }
     
      
        ul {
            display: inline-flex;
            list-style-type: none;
          
        }
      
        .fa-circle-chevron-right {
            font-size: 16px;
            margin-left: 5px;
        }
        .middepad {
            margin-left: 18px;
            margin-right: 18px;
        }
     }
    
     /* Extra large devices (large laptops and desktops, 1200px and up) */
     @media only screen and (min-width: 1200px) {
        ul {
            display: inline-block;
            list-style-type: none;
     
        }
        .middepad {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .fa-circle-chevron-right {
            font-size: 20px;
            margin-left: 5px;
            float: right;
        }
        .leftdiv {
            padding-top: 10px;
            padding-bottom: 10px; 
            width: 360px;
            background-color: whitesmoke;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 5px;
           
        }
        .topdiv {
            width: 100%;
            border-radius: 10px;

        }
     } 
    </style>
<template>
  <div class="topdiv">
    <div class="breadcrum">
        <nav :style="colortheme === 'darkTheme' ? 'background-color: #101010;' :  'background-color: #eaeded;'" 
       
          class="--bs-breadcrumb-divider bcnavformat"
           >
          <ol class="breadcrumb" >
            <li class="breadcrumb-item hrformat" v-for="bcrumb in breadcrumbdata">
              
              <router-link :to="{path: `${bcrumb.bclink}`}" class="breadcruma" :class="bcrumb.bcactive===true ? 'activelink' : 'nonactive'">
               {{ bcrumb.bcgname }}
              </router-link>
            </li>
          </ol>
        </nav>
 
        <!-- :href="`${bcrumb.bclink}`"  -->
      </div>
    </div>
</template>

<script>

export default {
    props: ['breadcrumbdata', 'breadcrumbtheme', 'darkstyle'],
    data() {
      return {
        colortheme: localStorage.getItem('colorTheme'),
      }
    },
    mounted() {
    
    }
}

</script>

<style lang="scss" scoped>

--bs-breadcrumb-divider {
  color: red;
}
nav {
    color: #003;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .breadcruma{
    color: #003;
    z-index: 10;
    margin-left: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .activelink {
    cursor: none;
    cursor: not-allowed;
    font-weight: bold;
    color: maroon;
  }
  .nonactive {
    color: rgb(60, 161, 219);
  }
  .hrformat {
    color: orangered; 
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
.breadcrumb a:not(:last-child)::after {
    content: '/'; /* Your divider character */
    margin: 0 5px; /* Adjust spacing */
    color: #ff0000; /* Divider color */
}
  @media only screen and (max-width: 600px) {

    .breadcruma {
      text-decoration: none;
    }
  
  }
  @media only screen and (min-width: 600px) {
    .breadcrumb {

      margin-top: -10px;
    }
    .breadcruma {
      text-decoration: none;
    }

  }
  
  @media screen and (min-width:769px) {

    .breadcruma {
      text-decoration: none;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .breadcruma {
      text-decoration: none;
      margin-left: 10px;
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .breadcruma {
      text-decoration: none;
      margin-left: 10px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .hrformat {
      padding-left: 0px;
    }
  }

  @media only screen and (min-width: 1400px) {
    .breadcruma {
      text-decoration: none;
      margin-left: 10px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .hrformat {
      padding-left: 0px;
    }
   .bcnavformat {
    padding-left: 100px;
   }
  }

  @media only screen and (min-width: 1600px) {
    .breadcruma {
      text-decoration: none;
      margin-left: 5px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .hrformat {
      padding-left: 0px;
    }
    .bcnavformat {
      padding-left: 290px;
     }
  }
</style>
<template>

    <div class="container bannerformat" :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'">
       
       <div class="row" style="padding: 10px;" >
         <div class="col-xs-12 col-sm-12 col-md-3 cardmgmt shadow" v-for="(xcard, index) in allcardsinfo" >
            <div :class="`vborder${index}`" style="padding: 20px;" :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'">
                <router-link :to="{path: `${xcard.cardlink}`}" style="text-decoration: none;"
                :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'" 
                    >
                    <p class="headingtext">{{ xcard.carddesc }}</p>
                    <p v-html="xcard.cardbody" class="innertext" :style="colortheme === 'darkTheme' ? 'color: white;' : 'color: #003;'"></p>
                </router-link>
            </div>
         </div>
       </div>
    </div>
</template>
<script>
import BreadCrumbs from './BreadCrumbs.vue';


export default {

props: ['allcardsinfo', 'colortheme'],

data(){
 return {
    allcards: [],
    xcard: 0,
    colortheme: localStorage.getItem('colorTheme')

 }
},
mounted(){
  
}
}
</script>
<style scoped>
/*
.container {
    padding-left: 50px;
    padding-right: 50px;
}*/
.vborder1 {
    padding-left: 20px;
}
.innertext {
    font-size: 16px;
    text-align: left;
}
.headingtext {
    font-weight: bold;
    text-align: center;
}
.cardmgmt {
    padding: 18px; 
    text-align: center;
}
.bannerformat {
    margin-top: 30px;
}
@media only screen and (max-width: 600px) {
    .vborder1, .vborder2, .vborder3 {
        border-top: thin solid red;
        padding-top: 10px;
        height: 100%;
    }  
    .col-md-3 {
        width: 100%;
    }     
}
@media only screen and (min-width: 600px) {
    .vborder1, .vborder2, .vborder3 {
        border-top: thin solid red;
        padding-top: 10px;
    } 

}

@media screen and (min-width:769px) {
    .vborder1, .vborder2, .vborder3 {
        border-top: thin solid red;
        padding-top: 10px;
        border-top-color: none;
    } 
    .cardmgmt{
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
       
    }

}

@media only screen and (min-width: 992px) {
    .vborder1 {
        border-right: thin solid red;
        border-left: thin solid red;
        padding-right: 20px;
        padding-left: 20px;
        border-top: none;
        height: 80%;
        padding-top: 0px;
    }
    .vborder2 {
        border-right: thin solid red;
        padding-right: 10px;
        padding-left: 20px;
        border-top: none;
        height: 80%;
        padding-top: 0px;
        

    }
    .vborder3 {
        padding-left: 20px;
        padding-right: 20px;
        height: 80%;
        padding-top: 0px;
        border-top: none;
        
    }
    .cardmgmt{
        width: 25%;
        padding-left: 2px;
        padding-right: 2px;
    }
  
}
@media only screen and (min-width: 992px) {

}
</style>